import React, { Component } from 'react';
import ProfilDetailCard from '../Components/ProfilDetailCard';
import BreadCrumb from '../Components/BreadCrumb';
import OrderDetail from '../Components/OrderDetail';
import AddressCard from '../Components/AddressCard';
import PaymentCard from '../Components/PaymentCard';
import Sprite from '../Assets/Icons/sprite.svg';
import UserDetailItemCard from '../Components/UserDetailItemCard';
import i18next from 'i18next';
import UserDetailCardMobil from '../Components/UserDetailCardMobil';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import ExceptionTranslate from '../lib/ExceptionTranslate'
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
const SiparislerimDetay = observer(class SiparislerimDetay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: [
        {
          OrderId: 1,
          OrderCode: '#273723457',
          OrderStatus: 3,
          OrderDate: '21.09.2020',
          TotalPrice: '106,98 TL',
          Count: 3
        }
      ],
      address: [
        {
          adressCardId: 1,
          deliverTitle: 'Ev',
          receiverName: 'Jane Doe',
          receiverPhone: '(+90) 555 555 55 55',
          receiverAdress: 'Mansuroğlu Mah. Dumlupınar Cad. No:47/C ',
          receiverDistrict: 'Bayraklı',
          receiverProvince: 'İzmir',
          receiverCounty: 'Türkiye',
          receiverPostalCode: '35550'
        }
      ],
      paymentCard: [
        {
          paymentCardId: 1,
          CreditCardNo: '3242 2342 2342 2342',
          TotalPrice: '99,99',
          CargoPrice: '6,99',
          GeneralTotalPrice: '106,98'
        }
      ],

      userDetailItemCard: [
        {
          ProductName: 'Coral Fever Kadın Elbise',
          Brand: 'Hip + Happen',
          Size: 'SMALL',
          Count: '1',
          Price: '99.99'

        }
      ],
      selectedCountId: 0,
      selectedSizeId: 0,
      ProductList: [],
      OrderDetailData: [],
      DeliveryInfo: [],
      DeliveryLocation: [],
      showErrorModal: false,
      errorMessage: "",
      width: window.innerWidth,
      height: window.innerHeight,

      isReturnActive: false,

      refundIbanName: "",
      refundIbanNumber: "",
      refundNote: "",
    };
    // console.log("width", this.state.width)

  }
  componentDidMount() {
    this.getUserOrders();
  }

  getUserOrders = () => {
    this.setState({ actLoading: true })
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;

    ApiBase.Post({
      url: '/get-order-detail',
      data: {
        CurrentUser: cUser,
        UserId: cUser.UserId,
        LanguageId: LangId,
        OrderId: this.props.match.params.siparisId
      },
      successFunction: (e) => {
        this.setState({
          OrderDetailData: e.data.data.List[0],
          ProductList: e.data.data.List[0].Products,
          DeliveryInfo: e.data.data.List[0].DeliveryAddress,
          DeliveryLocation: e.data.data.List[0].DeliveryAddress.Location,
        })
      },
      errorFunction: (e) => {
        this.setState({ actLoading: false, errorMessage: e.data.message });
      }
    })
  }

  getRefundRequest = () => {
    this.setState({ actLoading: true })
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    // var Lang = localStorage.getItem("lang")
    // var LangId = Lang === "tr" ? 1 : 2;

    ApiBase.Post({
      url: '/get-refund-request',
      data: {
        CurrentUser: cUser,
        UserId: cUser.UserId,
        // LanguageId: LangId,
        OrderId: this.state.OrderDetailData.OrderId,
        RefundName: this.state.refundIbanName,
        RefundIban: this.state.refundIbanNumber,
        RefundNote: this.state.refundNote
      },
      successFunction: (e) => {
        this.getUserOrders();
      },
      errorFunction: (e) => {
        this.setState({ showErrorModal: true, errorMessage: e.data.message });
      }
    })
  }
  getCartCount = () => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");

    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId

    }
    this.props.Store.CartStore.GetCartCount(_UserId, AUID)
  }
  AddToCart = (item) => {
    this.setState({ addCartLoading: true })
    const productId = this.props.match.params.productId;
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = 0;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    if (this.state.ProductDetailData.IsMainVariation == false && this.state.ProductVariations.length <= 0) {
      this.setState({ showErrorModal: true, addCartLoading: false, errorMessage: i18next.t("NOSTOCK") })
    }
    else {
      ApiBase.Post({
        url: '/add-to-cart',
        data: {
          LanguageId: LangId,
          ProductId: item.ProductId,
          VariationId: this.state.selectedSizeId,
          IsMainVariation: this.state.ProductDetailData.IsMainVariation,
          AnonymousUserId: AUID,
          Quantity: this.state.selectedCountId,
          UserId: _UserId

        },
        successFunction: (e) => {
          // console.log("sepeteekle", e.data)
          // console.log("asdasdad", AUID)
          // console.log("categories",e.data.data.product.Categories[0].NameTr)
          this.setState({ addCartLoading: false, })
          // this.notify();
          this.getCartCount();
        },
        errorFunction: (e) => {
          this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
          // console.log(e.data.message)
        }
      })
    }

  }
  notify = () => toast.success(ExceptionTranslate("Sepete Eklendi !"));
  onChange = e => this.setState({ [e.target.name]: e.target.value })
  render() {
    // console.log(this.state.DeliveryLocation.City)
    // const listOrder = this.state.orderList.map(item => {
    //     return  />
    // });

    // const address = this.state.DeliveryInfo.map(item => {
    //     return <AddressCard item={item} />
    // });
    const paymentCard = this.state.paymentCard.map(item => {
      return <PaymentCard item={item} />
    });
    const userDetailItemCard = this.state.ProductList.map(item => {
      return (

        <UserDetailItemCard orderDetail item={item} />

      )
    });
    return (
      <div className="UserDetail SiparislerimDetay">
        <div className="container">
          <BreadCrumb hesabim path={i18next.t("MyOrders")}></BreadCrumb>
          <div className="row  UserDetail__Siparislerim">
            <div className="col-lg-3 d-none d-lg-block  UserDetail__section1">
              <ProfilDetailCard className2={"bg--secondary"}></ProfilDetailCard>
            </div>
            <div className="col-lg-3  d-lg-none UserDetail__section1">
              <UserDetailCardMobil active2></UserDetailCardMobil>
            </div>
            <div className="col-lg-9  UserDetail__section2">
              <div className="d-flex justfy-items-center  align-items-center">
                <div className="ArrowLeftBox ">
                  <a href={`/${localStorage.getItem("lang")}/siparislerim`}>
                    <svg className="iconSize-sm " >
                      <use href={Sprite + "#arrow-right"} ></use>
                    </svg >
                  </a>
                </div>
                <div className="divGilroy ml-2 font-weight-bold">SİPARİŞ DETAYI</div>
              </div>
              <div className="UserDetail__sectionSub2--border mt-3">
                <OrderDetail isInDetail={true} item={this.state.OrderDetailData} />
                {this.state.OrderDetailData.RejectReason == null ?
                  <></>
                  :
                  <p className="rejectReason"> <span className="text-danger"> İptal / Red Nedeni : </span>{this.state.OrderDetailData.RejectReason} </p>}
              </div>
              <div className="row my-5 d-none d-lg-flex">
                <div className="col-6">
                  <h5 className="OrderDetail__head divGilroy font-weight-bold">{i18next.t("ProductInformation")}</h5>
                </div>
                <div className="col-2 ">
                  <h5 className="OrderDetail__head divGilroy font-weight-bold">{i18next.t("TotalPrice")}</h5>
                </div>
                <div className="col-4 text-center ">
                  {
                    (this.state.OrderDetailData.Status != 6 && this.state.OrderDetailData.Status != 8 && this.state.OrderDetailData.Status != 9 && this.state.OrderDetailData.Status != 10 && this.state.OrderDetailData.Status != 11) ?
                      <div onClick={() => { this.setState({ isReturnActive: !this.state.isReturnActive }) }} className="divGilroy text-danger font-weight-bold cursorPointer">{i18next.t("ReturnBtn")}</div>
                      :
                      <></>
                  }
                </div>
              </div>
              <div className=" d-block d-lg-none mt-4">
                <div className="row">
                  <div className="col-6">
                    <div className="OrderDetail__head">{i18next.t("OrderDetail")}</div>
                  </div>
                  <div className="col-6 text-right">
                    {
                      (this.state.OrderDetailData.Status != 6 && this.state.OrderDetailData.Status != 8 && this.state.OrderDetailData.Status != 9 && this.state.OrderDetailData.Status != 10 && this.state.OrderDetailData.Status != 11) ?
                        <div onClick={() => { this.setState({ isReturnActive: !this.state.isReturnActive }) }} className="divGilroy text-danger font-weight-bold cursorPointer">{i18next.t("ReturnBtn")}</div>
                        :
                        <></>
                    }
                  </div>
                </div>
              </div>
              {userDetailItemCard}
              <div className="row divOrderDetailAdress">
                <div className="col-lg">
                  <h5 className="addressCardTittle mb-4 divGilroy font-weight-bold">{i18next.t("DeliveryInformation")}</h5>
                  <AddressCard item={this.state.DeliveryInfo} orderDetail locationItem={this.state.DeliveryLocation} />
                </div>
                <div className="col-lg">
                  <h5 className="paymentCardTittle mb-4 divGilroy font-weight-bold">{i18next.t("PaymentInformation")}</h5>
                  <PaymentCard item={this.state.OrderDetailData} />

                </div>
              </div>
            </div>
          </div>
          <ToastContainer
          />
          {/* Error Modal  */}
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={this.hideLoginModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center">{ExceptionTranslate(this.state.errorMessage)}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => { this.setState({ showErrorModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
                </div>
              </form>
            </Modal.Body>
          </Modal>
          {/* RefeundModal */}
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.isReturnActive} onHide={this.hideLoginModal}>
            <Modal.Header >
            </Modal.Header>
            <h4 className="LoginModal__title text-center mt-5">{i18next.t("CreateRefundRequest")}</h4>
            <p></p>
            <Modal.Body>
              {/* <div className="text-center mb-3">
                                    <svg className="iconSize-md" >
                                        <use href={Sprite + "#sad-face"} ></use>
                                    </svg >
                                </div> */}
              <form className="LoginModal">
                {/* <p className="text-center">{this.state.errorMessage}</p> */}
                <div className="w-100 divRefundForm">
                  <div className="divFormSubTitle">{i18next.t("IBANNameSurname")}</div>
                  <input id="ibanNameSurname" className="form-control divFromInput" type="text" name="refundIbanName" onChange={this.onChange} />
                  <div className="divFormSubTitle">IBAN</div>
                  <input id="ibanNumber" className="form-control divFromInput" type="text" name="refundIbanNumber" onChange={this.onChange} />
                  <div className="divFormSubTitle">{i18next.t("Notes")}</div>
                  <textarea id="notes" className="form-control divFromInput" rows="3" name="refundNote" onChange={this.onChange}></textarea>
                </div>
                <div className="text-center  w-100  mx-auto">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <a onClick={() => { this.setState({ isReturnActive: false }); this.getRefundRequest() }} className="submit-button">{i18next.t('OK')}</a>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <a onClick={() => { this.setState({ isReturnActive: false }) }} className="submit-button">{i18next.t('CANCEL')}</a>
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
                                <div className="KeepInTouch__Img ">
                                    <img src={KeepInTouchImg} className="img-fluid"></img>
                                </div>
                            </Modal.Footer> */}
          </Modal>
        </div>
      </div>
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(SiparislerimDetay);
