import React, { Component } from 'react'
import i18next from 'i18next';
import Sprite from '../Assets/Icons/sprite.svg';
export default class SepetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }
  render() {
    const { item, Quantity, Size, Price } = this.props;
    return (
      <div className="row  p-0  mb-2 position-relative border-bottom py-2 pl-3">
        <div className="col-3  sepetImg p-0 ">
          <img src={item.MainPhoto} className=""></img>
        </div>
        <div className="col-6 p-0 pl-2">
          <p className="product-card__title mb-0 font-size-2">{item.Title}</p>
          <p className="product-card__brand font-size-1">{item.DesignerName}</p>
          <span className="product-card__itemCount font-size-2">  <span><b>  {i18next.t('Count')}: </b></span> <span className="ml-2">{item.Quantity}</span>   </span>
          {item.VariationGroupId > 0 && item.VariationId > 0 &&
            <span className="product-card__size font-size-2 mr-2">  <span><b>{item.VariationGroupName}:</b></span>  <span className="ml-2">{item.VariationName}</span>  </span>
          }
            {
              (item.OptionItemGroupName != null && item.OptionItemGroupName != undefined && item.OptionItemGroupName != "" && item.OptionItemName != null && item.OptionItemName != undefined && item.OptionItemName != "") &&
              <span className="product-card__size font-size-2 mr-2">  <span><b>{item.OptionItemGroupName}:</b></span>  <span className="ml-2">{item.OptionItemName}</span>  </span>
          }
        </div>
        <div className="col-12 text-right p-0 d-flex  my-auto pl-2 ">
          {item.DiscountedPrice > 0 ?
            <>
              <div className="product-card__oldPrice  ml-auto my-auto font-size-1 "> <del>{parseFloat(item.Price).toFixed(2)} {localStorage.getItem("lang")==="tr" ? "TL" : "$"}</del></div>
              <div className="product-card__newPrice my-auto"> {parseFloat(item.DiscountedPrice).toFixed(2)} {localStorage.getItem("lang")==="tr" ? "TL" : "$"}</div>
            </>
            :
            <>
              <span className="product-card__newPrice ml-auto my-auto  "> {parseFloat(item.Price).toFixed(2)} {localStorage.getItem("lang")==="tr" ? "TL" : "$"}</span>
            </>
          }
        </div>
        <a onClick={this.props.removeCartItem} className="product-card__newPrice mr-3 popover-delete-product ">
          <svg className="iconSize-xs" >
            <use href={Sprite + "#deleteProduct"} ></use>
          </svg >
        </a>
      </div>




    )
  }
}
