/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import i18next from 'i18next';

export default class SozlesmeCard extends Component {
  render() {
    const { className1, className2, className3, className4, className5, className6, className7 } = this.props;
    return (
      <div className="ProfilDetailCard SozlesmeCard">
        <div className="ProfilPopever__body">
          <p className={className1}>
            <a href={`/${localStorage.getItem("lang")}/shipping-returns`} className="profildetail__title "> {i18next.t("FtShippingReturns")} </a>
          </p>
          <p className={className2}>
            <a href={`/${localStorage.getItem("lang")}/membership`} className="profildetail__title"> {i18next.t("FtMembership")}</a>
          </p>
          <p className={`${className3} p-0`}>
            <p className="ProfilPopever__like-button">
              <a href={`/${localStorage.getItem("lang")}/privacy-policy`} className="profildetail__title"> {i18next.t("FtPrivacyPolicy")}</a>
            </p>
          </p>
          <p className={className4}>
            <a href={`/${localStorage.getItem("lang")}/sozlesmeler`} className="profildetail__title">{i18next.t("FtTermsConditions")}</a>
          </p>
        </div>
      </div>
    )
  }
}
