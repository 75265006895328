import Constant from './Constant';
import { extendObservable, action } from 'mobx';
import axios from 'axios';


class ApiBase {
  constructor() {
    extendObservable(this, {

      Post: action(async function ({ url, data, successFunction, errorFunction, exceptionFunction, navigation }) {
        var _url = "";

        if (url.indexOf("http") === -1) {
          if (url.indexOf("/") === 0) {
            _url = `${Constant.API_URL}${url}`;
          }
          else {
            _url = `${Constant.API_URL}/${url}`;
          }
        }
        else {
          _url = url;
        }

        var AccessToken = await localStorage.getItem("AccessToken");

        let _data = {
          Checker: true,
          accessToken: AccessToken,
          data: data,
        }


        let _exceptionFunction = (e) => {
        };

        if (typeof (exceptionFunction) == "function") {
          _exceptionFunction = exceptionFunction;
        }

        axios({
          method: 'post',
          url: _url,
          data: _data,
          headers: { "Content-Type": "application/json" }


        })
          .then((e) => {


            if (e.data.succeeded) {
              successFunction(e);
            } else {
              errorFunction(e);
            }
          })
          .catch((e) => {
            _exceptionFunction(e);
          });

      })
    })
  }


}
export default new ApiBase();


//   @action async Post({ url, data, successFunction, errorFunction, exceptionFunction, navigation }) {
  //     let _url = "";

  //     if (url.indexOf("http") === -1) {
  //       if (url.indexOf("/") === 0) {
  //         _url = `${Constant.API_URL}${url}`;
  //       }
  //       else {
  //         _url = `${Constant.API_URL}/${url}`;
  //       }
  //     }
  //     else {
  //       _url = url;
  //     }

  //     var AccessToken = '';
  //     await AsyncStorage.getItem("AccessToken", (err3, res3) => {

  //       AccessToken = res3;
  //       // console.log(res3)


  //     })
  //     let _data = {
  //       Checker: true,
  //       Version: Constant.VERSION,
  //       Platform: Constant.PLATFORM,
  //       accessToken: AccessToken,
  //       data

  //     }

  //     //console.log(_data)

  //     let _exceptionFunction = (e) => {
  //       //   console.log(e);
  //       Alert.alert(
  //         'Hata',
  //         e.message,
  //         [
  //           { text: 'Tamam' },
  //         ],
  //         { cancelable: false },
  //       );
  //     };

  //     if (typeof (exceptionFunction) == "function") {
  //       _exceptionFunction = exceptionFunction;
  //     }

  //     axios.post(_url, _data)
  //       .then((e) => {
  //         if (e.message == "OUT OF DATE") {
  //           navigation.navigate("UpdatePage");
  //         }

  //         if (e.data.succeeded) {
  //           successFunction(e);
  //         } else {
  //           errorFunction(e);
  //         }
  //       })
  //       .catch((e) => {
  //         _exceptionFunction(e);
  //       });

  //   }
