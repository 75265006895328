/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import Sprite from '../Assets/Icons/sprite.svg';
import ProductCard from '../Components/ProductCard';
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputRange from 'react-input-range';
import i18next from 'i18next';
import Select, { components } from "react-select";
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Modal from 'react-bootstrap/Modal';
import ExceptionTranslate from '../lib/ExceptionTranslate'

const Designers = observer(class Designers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favoriteList: [],
      isFavorited: -1,




      filterList: [
        {
          Id: 1,
          FilterName: i18next.t("MostPopular")
        },
        {
          Id: 2,
          FilterName: i18next.t("Newest")
        },
        {
          Id: 3,
          FilterName: i18next.t("HighestPrice")
        },
        {
          Id: 4,
          FilterName: i18next.t("LowestPrice")
        },
      ],


      selectedCategoryValues: [],
      priceValue: {
        min: 0,
        max: 1000,
      },
      SelectedCategoryId: 0,
      fiyatRange: false,
      DesignerProductList: [],
      DesignerData: [],
      favoriteIds: [],
      DesignerCategoryList: [],
      IsContinue: false,
      showErrorModal: false,
      errorMessage: "",
      TotalCount: 0,
      width: 0,
      checkedCategoryList: [],
      DiscountPercentage1: "",
      DiscountPercentage2: "",
      DiscountPercentage3: "",
    };
    this.multiselectRef = React.createRef();
  }
  componentDidMount() {
    this.getDesignerDetail();
    this.setState({ width: window.innerWidth });

    this.getFavoriteIds();
  }
  toggleFavorite = (item) => {
    const i = this.state.favoriteList.indexOf(item.ProductId)

    if (i >= 0) {
      this.state.favoriteList.splice(i, 1)
      // console.log("i 0 dan büyük",this.state.favoriteList)
    } else {
      this.state.favoriteList.push(item.ProductId)
      // console.log("i 0 dan küçük", this.state.favoriteList)
    }
    this.setState({ favoriteList: this.state.favoriteList });
    // // console.log(this.state.favoriteList)
    // console.log("iiii", i)
  };

  onSelect(item) {

    this.setState({ selectedCategoryValues: item }, () => {
      // console.log(this.state.selectedCategoryValues)
    })

  }
  getDesignerDetail = () => {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    this.setState({ actLoading: true, mobileFilreler: false })
    ApiBase.Post({
      url: '/get-designer-detail',
      data: {
        MinPrice: this.state.priceValue.min,
        MaxPrice: this.state.priceValue.max === 1000 ? null : this.state.priceValue.max,
        DesignerId: this.props.match.params.designerId,
        CategoryId: this.state.SelectedCategoryId,
        OrderBy: this.state.selectedFilterItem,
        LanguageId: LangId,
        Take: 24
      },
      successFunction: (e) => {

        this.setState({
          DesignerProductList: e.data.data.products,
          DesignerData: e.data.data.designerData,
          DesignerCategoryList: e.data.data.designerData.Categories,
          IsContinue: e.data.data.Continue,
          TotalCount: e.data.data.products.length > 0 && e.data.data.products[0].TotalCount,
          DiscountPercentage1: e.data.data.designerData.Discounts.DiscountPercentage1,
          DiscountPercentage2: e.data.data.designerData.Discounts.DiscountPercentage2,
          DiscountPercentage3: e.data.data.designerData.Discounts.DiscountPercentage3,
        });


      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })

  }

  laodMore = () => {
    if (this.state.IsContinue === true) {
      var Lang = localStorage.getItem("lang")
      var LangId = Lang === "tr" ? 1 : 2;
      this.setState({ actLoading: true })
      ApiBase.Post({
        url: '/get-designer-detail',
        data: {
          MinPrice: this.state.priceValue.min,
          MaxPrice: this.state.priceValue.max === 1000 ? null : this.state.priceValue.max,
          DesignerId: this.props.match.params.designerId,
          CategoryId: this.state.SelectedCategoryId,
          OrderBy: this.state.selectedFilterItem,
          LanguageId: LangId,
          Take: 24,
          Skip: this.state.DesignerProductList.length,
        },
        successFunction: (e) => {
          // console.log("designerDetail", e.data.data.designerData)
          this.setState({

            DesignerProductList: [...this.state.DesignerProductList, ...e.data.data.products],
            DesignerData: e.data.data.designerData,
            DesignerCategoryList: e.data.data.designerData.Categories
          });


        },
        errorFunction: (e) => {
          // console.log(e.data.message)
          this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message });

        }
      })
    }


  }

  handleChangeFilter = (item) => {
    this.setState({ selectedFilterItem: item.value }, () => {
      // console.log(this.state.selectedFilterItem)
    });
  }

  AddFavorite = (ProductId) => {
    this.setState({ addFavoriteLoading: true });
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }
    ApiBase.Post({
      url: '/add-favourite',
      data: {
        ProductId: ProductId,
        CurrentUser: cUser
      },
      successFunction: (e) => {
        // console.log("favoriekle", e.data)
        this.getFavoriteIds();
        // this.toggleFavorite(item)
        this.setState({ addFavoriteLoading: false })
      },
      errorFunction: (e) => {
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message })
        // console.log(e.data.message)

      }
    })
  }
  getFavoriteIds = () => {
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var favIdList = [];
    ApiBase.Post({
      url: '/get-favorite-ids',
      data: {

        UserId: _UserId,

      },
      successFunction: (e) => {
        // console.log("favoriteıds", e.data.data.List)
        e.data.data.List.map(item =>
          favIdList.push(item.ProductId));
        this.setState({ favoriteIds: favIdList })
        //this.setState({ CartList: e.data.data.List, CartTotalPriceStr: e.data.data.TotalPriceStr })
      },
      errorFunction: (e) => {

        // console.log("hata", e.data.message)
      }
    })
  }
  removeFavorites = (ProductId) => {
    this.setState({ addFavoriteLoading: true });
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }

    ApiBase.Post({
      url: '/delete-favourite',
      data: {
        ProductId: ProductId,
        CurrenUser: cUser
      },
      successFunction: (e) => {
        // console.log("remove", e.data)

        this.getFavoriteIds();
        this.setState({ addFavoriteLoading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }
  _onClickMobileFiltreler = () => {
    this.setState({ mobileFilreler: true })
  }
  _mobileKateogoriler = () => {
    this.setState({ mobileKategoriler: true })
  }
  _mobileTasarimcilar = () => {
    this.setState({ mobileTasarimcilar: true })
  }
  _mobileFiyatRange = () => {
    this.setState({ mobileFiyatRange: true })
  }
  handleChangeCategoriesMobile = (item) => {
    this.setState({ SelectedChildCategoryId: 0, SelectedCategoryId: item.Id, SelectedCategoryName: item.Name, SelectedCategoryDescription: item.Description, mobileKategoriler: false, mobileCategorySelected: true })
  }
  mobileFiltreUygula = () => {
    this.getDesignerDetail();
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontSize: 12,
        color: 'black',
        padding: 0,
        marginTop: 8,
        marginBottom: 8,
        marginLeft: 12,
        marginRight: 12,
        backgroundColor: 'transparent',
        fontFamily: ''
      }),
      control: (provided) => ({
        ...provided,
        marginTop: "5%",
        borderWidth: 0,
        borderBottomWidth: "1px",
        borderColor: '#EDE8DE',
        backgroundColor: "transparent",
        borderRadius: 0,
        outline: 'none',
        margin: 0,
        boxShadow: 0,
        ":hover": { borderColor: '#808080' },
      }),


      dropdownIndicator: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        color: '#B29F78',
        borderLeft: 0

      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        backgroundColor: 'white',

        color: 'black'
      }),
      singleValue: (provided) => ({
        ...provided,
        // backgroundColor: 'blue',
        color: 'black'
      }),
      valueContainer: (provided) => ({
        ...provided,
        flexWrap: 'no-wrap',
      }),
      menuList: (provided) => ({
        ...provided,
        overflowX: 'hidden',
        overflowY: 'auto',

      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 12

      })
    }

    const ProductList = this.state.DesignerProductList.map((item) => {
      return (
        <div className="col-lg-3 col-6">
          <ProductCard designer designerName={this.state.DesignerData.Name} _className={this.state.favoriteIds.indexOf(item.Id) >= 0 ? "product-card__like-button active" : "product-card__like-button"}
            toggleFavorite={() => { this.state.favoriteIds.indexOf(item.Id) >= 0 ? this.removeFavorites(item.Id) : this.AddFavorite(item.Id) }} history={this.props.history} item={item}></ProductCard>
        </div>
      )

    });
    const item = this.state.DesignerDetail;
    const filterOptions = [];
    this.state.filterList.map(item => {
      filterOptions.push({ value: item.Id, label: item.FilterName });
    })


    const CategoryList = this.state.DesignerCategoryList.map((item) => {
      return (
        <div className="col-1 DesignerList__filterBtns">
          <a className={this.state.SelectedCategoryId === item.Id ? "active" : ""} onClick={() => { this.setState({ SelectedCategoryId: item.Id }, () => { this.getDesignerDetail() }) }}>{item.Name} </a>
        </div>
      )
    })

    // console.log(this.state.DesignerData.Categories)
    return (
      <>
        {this.state.addFavoriteLoading == true &&
          <div className="favoriteoverlay">

          </div>
        }
        <div className="Designers">
          <div className="container">
            <BreadCrumb title={"MARKALAR"} titleLink={`/${localStorage.getItem("lang")}/designerlist`} path={this.state.DesignerData.Name} />
            <div className="row Designers__banner">
              <div className="Designers__bannerImg">
                <img src={this.state.DesignerData.BannerPhoto}></img>
              </div>

            </div>
            <div className="row Designers__header">
              <div className="col-lg-2">
                <div className="Designers__logoImg text-center mb-3 mt-2" >

                  <img src={this.state.DesignerData.Logo}></img>
                </div>
                <a href={this.state.DesignerData.Url} className="Designer__brand text-center d-block text-dark p-size-1">{this.state.DesignerData.Website}</a>
                {/* <Link to={item.DesignerSiteLink} >{item.DesignerSiteLink}</Link> */}
                {/* <div className="Designers__socialMediaTags pb-3  ">
                <a href={this.state.DesignerData.TwitterLink} className="px-2 text-dark">
                  <svg className="iconSize-xs " >
                    <use href={Sprite + "#twitter"} ></use>
                  </svg >
                </a>
                <a href={this.state.DesignerData.InstagramLink} className="px-2 text-dark ">
                  <svg className="iconSize-xs " >
                    <use href={Sprite + "#instagram"} ></use>
                  </svg >
                </a>
              </div> */}
              </div>
              <div className="col-lg-10">
                <h3 className="h-size-1 Designer__Title px-2">{this.state.DesignerData.Name}</h3>
                <p className="p-size-1" dangerouslySetInnerHTML={{ __html: this.state.DesignerData.Description }}></p>
              </div>

            </div>
            <div style={{ textAlign: 'center', background: '#F3F0E9' }}>
              {/* {this.state.DiscountPercentage1 != null && this.state.DiscountPercentage1 != undefined && this.state.DiscountPercentage1 != 0 ?
                <h3>{this.state.DiscountPercentage1}</h3>
                :
                <></>
              } */}
              {this.state.DiscountPercentage2 != null && this.state.DiscountPercentage2 != undefined && this.state.DiscountPercentage2 != 0 ?
                <div style={{ marginLeft: 10 }}>
                  {localStorage.getItem("lang") == 'tr' ?
                    <h3 style={{ marginBottom: 2, marginTop: 2 }}>İkinci Ürüne %{this.state.DiscountPercentage2} İndirim!</h3>
                    :
                    <h3 style={{ marginBottom: 2, marginTop: 2 }}>{this.state.DiscountPercentage2}% off on second item!</h3>
                  }
                </div>
                :
                <></>
              }
              {this.state.DiscountPercentage3 != null && this.state.DiscountPercentage3 != undefined && this.state.DiscountPercentage3 != 0 ?
                <div style={{ marginLeft: 10 }}>
                  {localStorage.getItem("lang") == 'tr' ?
                    <h3 style={{ marginBottom: 2, marginTop: 2 }}>Üçüncü Ürüne %{this.state.DiscountPercentage3} İndirim!</h3>
                    :
                    <h3 style={{ marginBottom: 2, marginTop: 2 }}>{this.state.DiscountPercentage3}% off on third item!</h3>
                  }
                </div>
                :
                <></>
              }
            </div>
            <div className="row Designers__product my-3 ">
              {this.state.width < 992 ?
                //mobil
                <div className="w-100 container">
                  <div className="row my-4">
                    <div onClick={this._onClickMobileFiltreler} className="col-6 d-flex justify-content-center align-items-center MobileFiltrelerDropdown">
                      <a className="w-100 text-dark h-size-3 "> {i18next.t("FILTERS")}

                      </a>
                      <svg className="iconSize-xxs" >
                        <use href={Sprite + "#add-sharp"} ></use>
                      </svg >

                    </div>
                    <div className="col-6 ">
                      <Select
                        placeholder={i18next.t("SortBy")}
                        styles={customStyles}
                        onChange={this.handleChangeFilter}
                        options={filterOptions} >
                      </Select>
                    </div>
                  </div>

                  {this.state.mobileFilreler === true &&
                    <div className="FiltersMobileContainer" >
                      <div className="text-right">   <svg onClick={() => { this.setState({ mobileFilreler: false }) }} className="iconSize-md" >
                        <use href={Sprite + "#close-sharp"} ></use>
                      </svg ></div>
                      <div className="CategorySubBtns">
                        <div className="text-center"><a onClick={this.mobileFiltreUygula} className="CategorySubBtn text-white">  {i18next.t("Apply")}</a></div>
                        <div className="text-center"><a className="CategorySubBtn bg-white p-size--color2"> {i18next.t("CANCEL")}</a></div>
                      </div>
                      {this.state.mobileKategoriler === true ?
                        <>
                          <div className="CategorySub">
                            <p className="FiltersMobileBtns__title FiltersMobileBtns__title--sub">
                              <span onClick={() => { this.setState({ mobileKategoriler: false }) }}>
                                <svg className="iconSize-xs" >
                                  <use href={Sprite + "#chevron-arrow-down"} ></use>
                                </svg >
                              </span>
                              {i18next.t("Categories")}
                            </p>
                            {
                              this.state.DesignerCategoryList.map(item => {
                                return (
                                  <a className="CategorySubItem"
                                    onClick={() => this.handleChangeCategoriesMobile(item)}
                                    key={item.Id}  >

                                    {/* <div className="customCheckbox" style={{ backgroundColor: this.state.checkedCategoryList.filter(x => x.id == item.Id) >= 0 ? "white" : "#AA7A56" }}>
                                  </div> */}
                                    <div style={{ opacity: this.state.checkedCategoryList.filter(x => x.id == item.Id) >= 0 ? 0.5 : 1 }} className="CategorySubItemName">{item.Name}</div>
                                  </a>
                                )
                              })
                            }
                          </div>
                          <div className="CategorySubBtns">
                            <div onClick={() => { this.setState({ mobileKategoriler: false }) }} className="text-center"><a className="CategorySubBtn text-white"> {i18next.t("ApplySelected")}</a></div>
                            <div className="text-center"><a className="CategorySubBtn bg-white p-size--color2"> {i18next.t("CANCEL")}</a></div>
                          </div>
                        </>
                        :

                        this.state.mobileFiyatRange === true ?
                          <>
                            <div className="CategorySub">
                              <p className="FiltersMobileBtns__title FiltersMobileBtns__title--sub">
                                <span onClick={() => { this.setState({ mobileFiyatRange: false }) }}>
                                  <svg className="iconSize-xs" >
                                    <use href={Sprite + "#chevron-arrow-down"} ></use>
                                  </svg >

                                </span>
                                {i18next.t("PRICE")}
                              </p>
                              <div className="border p-4">
                                <InputRange
                                  formatLabel={value => `${value} TL`}
                                  maxValue={1000}
                                  minValue={0}
                                  value={this.state.priceValue}
                                  onChange={value => this.setState({ priceValue: value })}
                                  onChangeComplete={value => console.log(value)} />
                              </div>
                            </div>
                            <div className="CategorySubBtns">
                              <div onClick={() => { this.setState({ mobileFiyatRange: false }) }} className="text-center"><a className="CategorySubBtn text-white"> {i18next.t("ApplySelected")}</a></div>
                              <div className="text-center"><a className="CategorySubBtn bg-white p-size--color2"> {i18next.t("CANCEL")}</a></div>
                            </div>
                          </>
                          :
                          <div>
                            <div onClick={this._mobileKateogoriler}>
                              {this.state.mobileCategorySelected === true ?
                                <div className="borderBlock">
                                  <p className="MobileCategoryLabel">{i18next.t("Categories")}</p>
                                  {this.state.SelectedCategoryId > 0 &&
                                    <p className="MobileCategoryChecked">{this.state.SelectedCategoryName}</p>
                                  }
                                  {/* {this.state.checkedCategoryList.length > 0 &&
                                    this.state.checkedCategoryList.map(item => {
                                      //  console.log(this.state.checkedCategoryList)
                                      return <p className="MobileCategoryChecked">{item.name}</p>
                                    })
                                  } */}
                                </div>
                                :
                                <div className="FiltersMobileBtns">
                                  <span className="FiltersMobileBtns__title">

                                    {i18next.t("Categories")}</span>
                                  <span className="pr-3">
                                    <svg className="iconSize-xs" >
                                      <use href={Sprite + "#chevron-arrow-down"} ></use>
                                    </svg >
                                  </span>
                                </div>
                              }
                            </div>

                            <div >
                              {this.state.priceValue.min == 0 && this.state.priceValue.max == 1000 ?

                                <div className="FiltersMobileBtns">
                                  <p onClick={this._mobileFiyatRange} className="FiltersMobileBtns__title">  {i18next.t("PRICE")} </p>
                                  <span className="pr-3">
                                    <svg className="iconSize-xs" >
                                      <use href={Sprite + "#chevron-arrow-down"} ></use>
                                    </svg >
                                  </span>
                                </div>
                                :
                                <div className="borderBlock">
                                  <p className="MobileCategoryLabel  "> {i18next.t("PRICE")}</p>
                                  {this.state.priceValue.min == 0 && this.state.priceValue.max == 1000 ?
                                    <></>
                                    :
                                    <p onClick={this._mobileFiyatRange} className=" MobileCategoryChecked"> {this.state.priceValue.min + "-" + this.state.priceValue.max + " TL"}  </p>

                                  }
                                </div>
                              }
                            </div>
                          </div>}
                    </div>}
                </div>
                :
                <>
                  <div className="col-1 DesignerList__filterBtns">
                    <a onClick={() => { this.setState({ SelectedCategoryId: 0 }, () => { this.getDesignerDetail() }) }} className={this.state.SelectedCategoryId === 0 ? "active" : ""}>{i18next.t("ALL")} </a>
                  </div>
                  {CategoryList}

                  {/* <div className="col-1 DesignerList__filterBtns">
  <a>{i18next.t("Man")}</a>
</div>
<div className="col-1 DesignerList__filterBtns">
  <a className="font-weight-bold p-size--color3"> {i18next.t("Discount")} </a>
</div> */}
                  <div className="col-3 offset-3 productFilters__priceRange1">
                    <label className="categoryFilter__labelFont"> {i18next.t("PRICE")}</label>
                    <div>
                      <a onClick={() => { this.setState({ fiyatRange: !this.state.fiyatRange }) }} className="productFilters__priceRange d-flex flex-row">{this.state.fiyatRange === true ? this.state.priceValue.min + "-" + this.state.priceValue.max + " TL" :
                        "Fiyat Aralığı Belirleyiniz..."}
                        <div aria-hidden="true" class=" css-1aujs45-indicatorContainer d-inline-block ml-auto p-0 pr-2 m-0"><svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg></div>
                      </a>
                    </div>
                    {this.state.fiyatRange === true ?
                      <div className="border p-4 mt-2">

                        <InputRange

                          formatLabel={value => `${value} TL`}
                          maxValue={1000}
                          minValue={0}
                          value={this.state.priceValue}
                          onChange={value => this.setState({ priceValue: value })}
                          onChangeComplete={this.getDesignerDetail} />
                      </div>
                      :
                      <></>}
                  </div>
                  <div className="col-2 ">
                    <label className="categoryFilter__labelFont">{i18next.t("SortBy")}</label>
                    <Select
                      placeholder={i18next.t("SortBy")}
                      styles={customStyles}
                      onChange={this.handleChangeFilter}
                      options={filterOptions} >
                    </Select>

                  </div>


                </>
              }


            </div>
            <div className=" product-cards">

              <div className="row mx-0">

                {ProductList}

              </div>
            </div>

            <div className="row my-5">
              <div className="col-lg-7 text-lg-right text-center mb-3 mb-lg-0" >
                <a onClick={this.laodMore} className="p-size-2 text-dark">{i18next.t("LoadMore")}</a>
              </div>
              <div className="col-lg-3 offset-lg-2 text-right ">
                <ProgressBar now={this.state.DesignerProductList.length} max={this.state.TotalCount ? this.state.TotalCount : 0} />
                <span className="p-size-2">{this.state.DesignerProductList.length} {i18next.t("Product")} {i18next.t("display")} / {i18next.t("Totallowercase")} {this.state.TotalCount ? this.state.TotalCount : 0} {i18next.t("Product")}</span>
              </div>

            </div>

          </div>
          {/* Error Modal  */}
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={this.hideLoginModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center">{ExceptionTranslate(this.state.errorMessage)}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => { this.setState({ showErrorModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
                <div className="KeepInTouch__Img ">
                  <img src={KeepInTouchImg} className="img-fluid"></img>
                </div>
              </Modal.Footer> */}
          </Modal>

        </div>
      </>

    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(Designers);
