/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Logo from '../Assets/Image/footerLogo.png';
import SafeShop from '../Assets/Image/safeShop.png';
import PRLogo from '../Assets/Image/prlogo.svg';
import Sprite from '../Assets/Icons/sprite.svg'
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';
export default class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      LayoutInfo: [],
      ClientCountry: '',
      CategoryList: [],
      ContractList: []
    }
  }
  componentDidMount() {
    this.getLayoutInfo();
    this.getCategories();
    this.getContracts();
  }

  getLayoutInfo = () => {
    ApiBase.Post({
      url: '/get-layout-info',
      data: {},
      successFunction: (e) => {
        this.setState({
          LayoutInfo: e.data.data
        });
      },
      errorFunction: (e) => {
      }
    });
  };

  getCategories = () => {
    this.setState({ loadingBlogDetail: true });
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-categories',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        this.setState({
          CategoryList: e.data.data.categories
        });
      },
      errorFunction: (e) => {
      }
    })
  };

  getContracts = () => {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-contracts',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        this.setState({
          ContractList: e.data.data.contractList,
        });
      },
      errorFunction: (e) => {
      }
    })
  }

  urlToBeForwardedFunc = (lang) => {
    localStorage.setItem("lang", lang);

    const currentPathNameArray = window?.location?.pathname?.split('/');

    let _targetHref = "";

    for (let i = 0; i < currentPathNameArray.length; i++) {
      if (!((i === 0 && currentPathNameArray[i] === "") || (i === 1 && (currentPathNameArray[i] === "" || currentPathNameArray[i] === "tr" || currentPathNameArray[i] === "en")))) {
        _targetHref += "/" + currentPathNameArray[i];
      }
    }

    window.location.href = `/${lang}${_targetHref}`;
  }

  render() {
    const catList = this.state.CategoryList.map((item, index) => {
      if (index < 4) {
        return (
          <a href={`/${localStorage.getItem("lang")}/shop/${item.Url}`} className="divFooterItem d-block">{item.Name}</a>
        )
      }
    })

    return (
      <div className="footer">
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-12 col-md-12">
              <img src={Logo} />
            </div>
            <div className="col-lg-4 col-md-12">
              <div style={{ marginTop: 40 }}></div>
              <div className="divFooterBigItem">{this.state.LayoutInfo.Phone}</div>
              <div className="divFooterItem">{this.state.LayoutInfo.Email}</div>
              <div className="divFooterItem">{this.state.LayoutInfo.Address}</div>
              <div className="d-flex justify-content-between align-items-center divFooterSocialLinks">
                <a href={this.state.LayoutInfo.Facebook} target="_blank">
                  <svg className="iconSize-xs" >
                    <use href={Sprite + "#facebook"} ></use>
                  </svg >
                </a>
                {/* <a href={this.state.LayoutInfo.Twitter} target="_blank">
                  <svg className="iconSize-xs" >
                    <use href={Sprite + "#twitter"} ></use>
                  </svg >
                </a> */}
                <a href={this.state.LayoutInfo.Instagram} target="_blank">
                  <svg className="iconSize-xs" >
                    <use href={Sprite + "#instagram"} ></use>
                  </svg >
                </a>
                <a href={this.state.LayoutInfo.Youtube} target="_blank">
                  <svg className="iconSize-xs" >
                    <use href={Sprite + "#youtube"} ></use>
                  </svg >
                </a>
                <a href={this.state.LayoutInfo.Pinterest} target="_blank">
                  <svg className="iconSize-xs" >
                    <use href={Sprite + "#pinterest"} ></use>
                  </svg >
                </a>
                {/* <a href={this.state.LayoutInfo.LinkedIn} target="_blank">
                  <svg className="iconSize-xs" >
                    <use href={Sprite + "#linkedin"} ></use>
                  </svg >
                </a> */}
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-12">
              <div className="divFooterBrownItem">{i18next.t('QuickMenu')}</div>
              {catList}
            </div> */}
            <div className="col-lg-4 col-md-12">
              <div className="divFooterBrownItem">{i18next.t('Contracts')}</div>
              {
                this.state.ContractList.map((item) => (
                  <a href={`/${localStorage.getItem("lang")}/sozlesmeler/${item.Id}`} className="divFooterItem d-block">{item.Title}</a>
                ))
              }
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <img className="imgSafeShop" src={SafeShop} />
            </div>
            <div className="divFooterLang">
              {
                ((window.navigator.userLanguage || window.navigator.language) === "tr-TR") ?
                  <>
                    <div onClick={() => { this.urlToBeForwardedFunc("tr"); }} className={`cursorPointer divFooterLangItem ${localStorage.getItem("lang") == "tr" ? "active" : ""}`}>TR</div>
                    <div onClick={() => { this.urlToBeForwardedFunc("en"); }} className={`cursorPointer divFooterLangItem ${localStorage.getItem("lang") == "en" ? "active" : ""}`}>EN</div>
                  </>
                  :
                  <></>
              }
            </div>
          </div>
          <div className="divFooterWhiteLine"></div>
          <div className="row">
            <div className="col-lg-6 col-md-12 divCopyCol">
              <div className="divFooterItem">©2021 - FERUAL | {i18next.t('AllRights')}</div>
            </div>
            <a target="_blank" href="https://www.pryazilim.com/" className="col-lg-6 col-md-12  divDesignCol">
              <div className="divFooterItem">Designed by</div>
              <img src={PRLogo} />
            </a>
          </div>
        </div>
      </div>
    )
  }
}
