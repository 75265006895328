import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg'
import i18next from 'i18next';
export default class BreadCrumb extends Component {
  render() {
    const { title, titleLink, category, categoryLink, brand, brandLink, path, pathLink, hesabim } = this.props;
    return (
      <div className="breadcrumb__text--clamp">
        <ol className="breadcrumb mb-0 bg-transparent  ">
          <li className="breadcrumb-item">
            <a href={`/${localStorage.getItem("lang")}/`}>
              <svg className="iconSize-xxs" >
                <use href={Sprite + "#home-menu"} >
                </use>
              </svg >
            </a>
          </li>
          {title ?
            <li className="breadcrumb-item">
              <a href={titleLink}>
                <span className="text-uppercase">{title}</span>
              </a>
            </li> : <></>}
          {hesabim ?
            <li className="breadcrumb-item divGilroy">
              <span className="text-uppercase">{i18next.t("MyAccount")}</span>
            </li> : <></>}
          {category ?
            <li className="breadcrumb-item">
              <a href={categoryLink}>
                <span className="text-uppercase">{category}</span>
              </a>
            </li>
            :
            <></>}
          {brand ?
            <li className="breadcrumb-item">
              <a href={brandLink}>
                <span className="text-uppercase">{brand}</span>
              </a>
            </li>
            :
            <></>}
          <li className="breadcrumb-item active">
            <a href={pathLink}>
              <span className="text-uppercase">{path}</span>
            </a>
          </li>
        </ol>
      </div>
    )
  }
}
