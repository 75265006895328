/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import BreadCrumb from '../Components/BreadCrumb';
import Sprite from '../Assets/Icons/sprite.svg';
import ProductCard from '../Components/ProductCard';
import Slider from "react-slick";
import i18next from 'i18next';
import Select from 'react-select';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import imageZoom from '../lib/ImageZoom'
import ExceptionTranslate from '../lib/ExceptionTranslate'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";

const ProductDetail = observer(class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'burak',
      nav1: null,
      nav2: null,
      favoriteList: [],
      isFavorited: -1,
      selectedSizeId: 0,
      cart: [],
      addCartLoading: false,
      showErrorModal: false,
      errorMessage: "",
      selectedCountId: 1,
      ProductDetailData: [],
      DesignerList: [],
      ProductCategoryList: [],
      ProductCategoryName: "",
      ProductPhotos: [],
      RecommendedProducts: [],
      ProductVariations: [],
      VariationType: 1,
      ProductVariationName: "",
      SelectedVariationPrice: 0,
      SelectedVariationDiscountedPrice: 0,
      favoriteIds: [],
      totalStock: 0,
      OptionListName: "",
      OptionList: "",
      SelectedProductOption: ""
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
    this.getProductDetail();
    this.getRecommendedProducts();
    this.getFavoriteIds();
  }

  getFavoriteIds = async () => {
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var favIdList = [];
    await ApiBase.Post({
      url: '/get-favorite-ids',
      data: {
        UserId: _UserId,
      },
      successFunction: (e) => {
        e.data.data.List.map(item => favIdList.push(item.ProductId));
        this.setState({ favoriteIds: favIdList });
      },
      errorFunction: (e) => {
      }
    })
  }

  toggleFavorite = (item) => {
    const i = this.state.favoriteList.indexOf(item.Id)

    if (i >= 0) {
      this.state.favoriteList.splice(i, 1)
    } else {
      this.state.favoriteList.push(item.Id)
    }
    this.setState({ favoriteList: this.state.favoriteList });
  };

  handleChangeCount = (item) => {
    this.setState({ selectedCountId: item.label }, () => {
    })
  }

  getProductDetail = () => {
    this.setState({ loading: true })
    const productId = this.props.match.params.productId;
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-product-detail',
      data: {
        LanguageId: LangId,
        ProductId: productId,
        CountryId: LangId
      },
      successFunction: (e) => {
        this.setState({
          ProductDetailData: e.data.data.product,
          DesignerList: e.data.data.product.Designer,
          ProductPhotos: e.data.data.product.Photos,
          ProductVariations: e.data.data.product.Variations,
          VariationType: e.data.data.product.VariationType,
          ProductVariationName: e.data.data.product.VariationName,
          ProductCategoryList: e.data.data.product.Categories,
          OptionListName: e.data.data.product.OptionListName,
          OptionList: e.data.data.product.OptionList,
          loading: false,
          totalStock: e.data.data.product.IsMainVariation === true ? e.data.data.product.Stock : e.data.data.product.Variations.reduce((totalStock, x) => totalStock + x.Stock, 0)
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false, loading: false })
      }
    })
  };

  /////
  getRecommendedProducts = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-featured-products',
      data: {
        LanguageId: LangId,
        CountryId: LangId
      },
      successFunction: (e) => {
        this.setState({
          RecommendedProducts: e.data.data.productList,
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
      }
    })
  }

  onChangeSelectSize = (item) => {
    this.setState({ selectedSizeId: item.value }, () => {
      const a = this.state.ProductVariations.find(x => x.VariationId === this.state.selectedSizeId)
      this.setState({
        SelectedVariationPrice: a.Price,
        SelectedVariationDiscountedPrice: a.DiscountedPrice
      })
    });
  }

  onChangeProductOption = (item) => {
    this.setState({ SelectedProductOption: item.label });
  }

  onFontColorSelect = (item) => {
    this.setState({ selectedSizeId: item.VariationId }, () => {
      const a = this.state.ProductVariations.find(x => x.VariationId === this.state.selectedSizeId);
      this.setState({
        SelectedVariationPrice: a.Price,
        SelectedVariationDiscountedPrice: a.DiscountedPrice
      })
    });
  }

  AddToCart = () => {
    if (this.state.addCartLoading) {
      return;
    }
    if (this.state.OptionList != null && this.state.OptionList != undefined && this.state.OptionList != "" && this.state.OptionList.split('^').length > 0 && this.state.SelectedProductOption == "") {
      this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: ExceptionTranslate("Lütfen Varyasyon Seçiniz") })
      return;
    }
    const productId = this.props.match.params.productId;
    var AUID = localStorage.getItem("AnonymousUserId") ? localStorage.getItem("AnonymousUserId") : null
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = 0;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    if (this.state.ProductDetailData.IsMainVariation == false && this.state.ProductVariations.length <= 0) {
      this.setState({ showErrorModal: true, addCartLoading: false, errorMessage: i18next.t("NOSTOCK") })
    }
    else {
      this.setState({ addCartLoading: true })
      ApiBase.Post({
        url: '/add-to-cart',
        data: {
          LanguageId: LangId,
          ProductId: productId,
          VariationId: this.state.selectedSizeId,
          IsMainVariation: this.state.ProductDetailData.IsMainVariation,
          AnonymousUserId: AUID,
          Quantity: this.state.selectedCountId,
          UserId: _UserId,
          OptionItemGroupName: this.state.OptionListName,
          OptionItemName: this.state.SelectedProductOption,
          CountryId: LangId
        },
        successFunction: (e) => {
          const senddata = {
            LanguageId: LangId,
            ProductId: productId,
            VariationId: this.state.selectedSizeId,
            IsMainVariation: this.state.ProductDetailData.IsMainVariation,
            AnonymousUserId: AUID,
            Quantity: this.state.selectedCountId,
            UserId: _UserId
          };

          this.setState({ addCartLoading: false, })
          this.notify();
          this.getCartCount();
        },
        errorFunction: (e) => {
          this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
        }
      })
    }

  }

  notify = () => toast(ExceptionTranslate('Sepete Eklendi!'), {
    className: "Toastify__toast--default",
    bodyClassName: ".Toastify__toast-body",
    autoClose: 3000
  });

  handleBuyNow = () => {
    if (this.state.addCartLoading) {
      return;
    }
    const productId = this.props.match.params.productId;
    var AUID = localStorage.getItem("AnonymousUserId") ? localStorage.getItem("AnonymousUserId") : null
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = 0;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    if (this.state.ProductDetailData.IsMainVariation == false && this.state.ProductVariations.length <= 0) {
      this.setState({ showErrorModal: true, addCartLoading: false, errorMessage: i18next.t("NOSTOCK") })
    }
    else {
      this.setState({ addCartLoading: true });
      ApiBase.Post({
        url: '/add-to-cart',
        data: {
          LanguageId: LangId,
          ProductId: productId,
          VariationId: this.state.selectedSizeId,
          IsMainVariation: this.state.ProductDetailData.IsMainVariation,
          AnonymousUserId: AUID,
          Quantity: this.state.selectedCountId,
          UserId: _UserId

        },
        successFunction: (e) => {
          const senddata = {
            LanguageId: LangId,
            ProductId: productId,
            VariationId: this.state.selectedSizeId,
            IsMainVariation: this.state.ProductDetailData.IsMainVariation,
            AnonymousUserId: AUID,
            Quantity: this.state.selectedCountId,
            UserId: _UserId
          };

          this.setState({ addCartLoading: false, })
          this.notify();
          this.getCartCount();

          window.location.href = `/${localStorage.getItem("lang")}/adresbilgisi`;
        },
        errorFunction: (e) => {
          this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
        }
      })
    }
  }

  getCartCount = () => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");

    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId

    }
    this.props.Store.CartStore.GetCartCount(_UserId, AUID)
  }

  AddFavorite = (ProductId) => {
    this.setState({ addFavoriteLoading: true }, () => {
    })
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }
    ApiBase.Post({
      url: '/add-favourite',
      data: {
        ProductId: ProductId,
        CurrentUser: cUser
      },
      successFunction: (e) => {
        this.setState({ addFavoriteLoading: false })
        this.getFavoriteIds();
      },
      errorFunction: (e) => {
        this.setState({ showErrorModal: true, errorMessage: e.data.message, addFavoriteLoading: false })
        document.body.style.backgroundColor = "transparent";
        document.body.style.opacity = 1

      }
    })
  }

  removeFavorites = (ProductId) => {
    this.setState({ addFavoriteLoading: true }, () => {
    });
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }
    ApiBase.Post({
      url: '/delete-favourite',
      data: {
        ProductId: ProductId,
        CurrenUser: cUser
      },
      successFunction: (e) => {
        this.getFavoriteIds().then(() => {
          this.setState({ addFavoriteLoading: false })
        })
      },
      errorFunction: (e) => {
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }

  setZoomElement() {
    if (this.state.showResultDiv == true) {
      const imgElement = document.querySelector('.mainslider .slick-active.slick-current div img');
      imageZoom(imgElement);
    }

  }

  handleSpanMinus = () => {
    if (this.state.selectedCountId > 1) {
      this.setState({ selectedCountId: this.state.selectedCountId - 1 });
    }
  }

  handleSpanPlus = () => {
    this.setState({ selectedCountId: this.state.selectedCountId + 1 });
  }

  render() {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.RecommendedProducts.length < 4 ? this.state.RecommendedProducts.length : 4,
      arrows: false,
      swipeToSlide: true,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,

      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            autoplaySpeed: 3000,
            arrows: false,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
            autoplaySpeed: 3000,
            arrows: false,
          }
        }
      ]

    };

    var photoSlider2Settings = {
      vertical: true,
      verticalSwiping: true,
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: (this.state.ProductPhotos.length > 4 ? 4 : this.state.ProductPhotos.length),
      swipeToSlide: true,
      focusOnSelect: true,
      className: "center",
      arrows: false,

      responsive: [
        {
          breakpoint: 576,
          settings: {
            vertical: false,
            verticalSwiping: false,
            slidesToShow: (this.state.ProductPhotos.length > 3 ? 3 : this.state.ProductPhotos.length),
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 438,
          settings: {
            vertical: false,
            verticalSwiping: false,
            slidesToShow: (this.state.ProductPhotos.length > 3 ? 3 : this.state.ProductPhotos.length),
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            arrows: false
          }
        },

      ]

    }

    const ListItem = this.state.RecommendedProducts.map((pitem) => {
      return (
        <li key={pitem.ProductId} >
          <ProductCard item={pitem} _className={this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? "product-card__like-button active" : "product-card__like-button"} toggleFavorite={() => { this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? this.removeFavorites(pitem.Id) : this.AddFavorite(pitem.Id) }} history={this.props.history} ></ProductCard>
        </li>
      );
    });

    const customStyles = {

      option: (provided, state) => ({
        ...provided,
        fontSize: 12,
        color: 'black',
        backgroundColor: "transparent",

        '&:hover': {
          backgroundColor: "#DEDEDE"

        },
        '&:focus': {
          backgroundColor: "#DEDEDE"

        },
      }),

      control: (provided, state) => ({
        ...provided,
        marginTop: "0",
        borderWidth: "1px",
        borderColor: '#DEDEDE',
        backgroundColor: "transparent",
        borderRadius: 0,
        outline: "transparent",
        fontSize: window.innerWidth < 576 ? "13px" : "inherit",
        boxShadow: state.isFocused ? '0 0 0 0.2rem transparent' : '',
        '&:focus': {
          borderColor: "#DEDEDE",
          '&:hover': {
            borderColor: "#DEDEDE"

          },
        },

      }),



      container: (provided) => ({
        ...provided,
        '&:focus': {
          borderColor: "#F3F0E9",

        },

      }),

      dropdownIndicator: (provided) => ({
        ...provided,
        color: '#B29F78',
        borderLeft: 0

      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0
      }),
      placeholder: (provided) => ({
        ...provided,
        "@media only screen and (max-width: 576px)": {
          ...provided["@media only screen and (max-width: 576px)"],
          fontSize: 13,
        },
      })

    }

    const options = [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
      { value: 6, label: '6' },
      { value: 7, label: '7' },
      { value: 8, label: '8' },
      { value: 9, label: '9' },
      { value: 10, label: '10' },

    ]

    const sizeOptions = [];
    this.state.ProductVariations.map(item => {
      return sizeOptions.push({ value: item.VariationId, label: item.Stock <= 0 ? item.Name + " (Stok Tükendi)" : item.Name })
    });

    const productOptions = [];
    if (this.state.OptionList != null && this.state.OptionList != undefined && this.state.OptionList != "" && this.state.OptionList.split('^').length > 0) {
      this.state.OptionList.split('^').map((item, index) => {
        if (item != "") {
          return productOptions.push({ value: index, label: item })
        }
      });
    }

    const linkedItem = (image, url, title) => {
      return <a href={`/${localStorage.getItem("lang")}/productdetail/${url}`}><div className="col-md-3 " style={{ padding: 16, border: 1, minHeight: 50 }}>
        <img style={{ width: 150, maxHeight: 200 }} src={image} />
        <p>{title}</p>
      </div></a>
    }

    const linkedProducts = () => {
      if (this.state.ProductDetailData.LinkItems) {
        return this.state.ProductDetailData.LinkItems.map(x => {
          return linkedItem(x.PhotoPath, x.Url, LangId === 1 ? x.LinkNameTr : x.LinkNameEn);
        })
      }
      else {
        return <></>
      }
    }
    const options2 = {
      buttons: {
        showAutoplayButton: false,
        showNextButton: true,
        showPrevButton: true,
        showDownloadButton: false,
        showThumbnailsButton: false,
      },
      settings: {
        disableWheelControls: true,
      },
      thumbnails: {
        showThumbnails: false,

      }
    };

    const CatName = this.state.ProductCategoryList.map(item => {
      return LangId === 1 ? item.NameTr : item.NameEn
    })

    const ProductImageElements = this.state.ProductPhotos.map((photo, index) => {
      if (index == 0) {
        return <></>
      }
      else {
        return <img key={"myPhoto" + index} className={"ProductDetailImgSize d-none product-" + photo.Id} src={photo.PhotoPath} onMouseEnter={() => this.setState({ showResultDiv: true })} onMouseLeave={() => this.setState({ showResultDiv: false })} />
      }
    })

    const _productId = parseInt(this.props.match.params.productId);

    return (
      <>
        {this.state.addFavoriteLoading == true &&
          <div className="favoriteoverlay">

          </div>
        }
        <div className="productDetail">
          <div className="container">
            <BreadCrumb titleLink={`/${localStorage.getItem("lang")}/searchproducts`} title={i18next.t('Shop')} brand={this.state.DesignerList.Name} brandLink={`/${localStorage.getItem("lang")}/designers/${this.state.DesignerList.Url}`} path={this.state.ProductDetailData.Title} ></BreadCrumb>
            <div className="row py-4">
              <div className="col-lg-6 ">
                <div className="row d-flex">
                  <div className="col-lg-2  col-sm-12 order-3 order-sm-1 mt-3 mt-sm-0 px-sm-0" style={{ marginRight: -40 }}>
                    <Slider {...photoSlider2Settings} initialSlide={0} asNavFor={this.state.nav1} ref={slider => (this.slider2 = slider)}>
                      {
                        this.state.ProductPhotos.map((photo) => {
                          return (<img key={1} className="ProductDetailImgSizeSm" src={photo.PhotoPath} />)
                        })
                      }
                    </Slider>
                  </div>
                  <div className="col-sm-9 order-1 order-sm-2 img-zoom-container">
                    <Slider arrows={false} dots={true} initialSlide={0} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} afterChange={() => this.setZoomElement()} className={"mainslider"}>
                      {this.state.ProductPhotos.map((photo, index) => {
                        return (
                          <SimpleReactLightbox>
                            <SRLWrapper options={options2}>
                              <img key={"myPhoto" + index} className={"ProductDetailImgSize product-" + photo.Id} src={photo.PhotoPath} onMouseEnter={() => this.setState({ showResultDiv: true })} onMouseLeave={() => this.setState({ showResultDiv: false })} />
                              {ProductImageElements}
                            </SRLWrapper>
                          </SimpleReactLightbox>
                        );
                      })
                      }
                    </Slider>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={this.state.showResultDiv == false ? "d-none img-zoom-result" : "img-zoom-result"}></div>
                {
                  this.slider1 && this.setZoomElement()
                }
                {this.state.loading === true ?
                  <div className="loadingImage"></div>
                  :
                  <div className="product-detail__body">
                    <p className="product-card__title">{this.state.ProductDetailData.Title}</p>
                    {this.state.ProductDetailData.DiscountPercentage > 0 ?
                      <>
                        <span className="product-card__discountAmount product-card__discountAmount--ps">
                          -% {this.state.ProductDetailData.DiscountPercentage}
                        </span>
                        <div className="divSplitBlock"></div>
                      </>
                      : <></>}
                    {this.state.ProductDetailData.DiscountedPrice > 0 || this.state.SelectedVariationDiscountedPrice > 0 ?
                      <>
                        {this.state.selectedSizeId > 0 || this.state.SelectedVariationDiscountedPrice > 0 ?
                          <span className="product-card__oldPrice"> <del>{parseFloat(this.state.SelectedVariationPrice).toFixed(2)} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</del></span>
                          :
                          <span className="product-card__oldPrice"> <del>{parseFloat(this.state.ProductDetailData.Price).toFixed(2)} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</del></span>
                        }
                      </>
                      :
                      <>
                      </>
                    }
                    <span className="product-card__newPrice--font">
                      {this.state.selectedSizeId > 0 ?
                        <>
                          {
                            this.state.SelectedVariationDiscountedPrice > 0 ?
                              <span className="product-card__newPrice">{parseFloat(this.state.SelectedVariationDiscountedPrice).toFixed(2)} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</span>
                              :
                              <span className="product-card__newPrice">{parseFloat(this.state.SelectedVariationPrice).toFixed(2)} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</span>
                          }

                        </>
                        :
                        <>
                          {
                            this.state.ProductDetailData.DiscountedPrice > 0 ?
                              <span className="product-card__newPrice">{parseFloat(this.state.ProductDetailData.DiscountedPrice).toFixed(2)} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</span>
                              :
                              <span className="product-card__newPrice">{parseFloat(this.state.ProductDetailData.Price).toFixed(2)} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</span>
                          }
                        </>
                      }
                    </span>
                    {/* <p className="product-card__description p-size-1" dangerouslySetInnerHTML={{ __html: this.state.ProductDetailData.ShortDescription }}></p> */}


                    {((this.state.ProductDetailData.Stock <= 0 && this.state.ProductDetailData.Variations.length <= 0) || this.state.totalStock <= 0) ?
                      <div className="divProductDetailBorder">
                        <span className="spanNoStock">STOK TÜKENDİ</span>
                      </div>
                      :
                      <>
                        <div className="divProductDetailBorder">
                          {this.state.ProductDetailData.IsMainVariation === true || this.state.ProductVariations.length <= 0 ?
                            <></>
                            :
                            this.state.VariationType == 2 ?
                              <>
                                <div className="divProductDetailSelectLabel">{this.state.ProductVariationName}:</div>
                                <div className="d-flex mt-4  mb-4">
                                  {
                                    this.state.ProductVariations.map((item) => (
                                      item.Stock <= 0 ?
                                        <div className="divFontColorSquare" style={{ background: item.FontColor }}>
                                          <div className="disableFontSquare">
                                            {i18next.t('NOSTOCK')}
                                          </div>
                                        </div>
                                        :
                                        <div className={`${item.VariationId == this.state.selectedSizeId ? "selectedSquare" : "divFontColorSquare"}`} style={{ background: item.FontColor }} onClick={() => { this.onFontColorSelect(item); }}></div>
                                    ))
                                  }
                                </div>
                              </>
                              :
                              this.state.VariationType == 3 ?
                                <>
                                  <div className="divProductDetailSelectLabel">{this.state.ProductVariationName}:</div>
                                  <div className="d-flex mt-4  mb-4">
                                    {
                                      this.state.ProductVariations.map((item) => (
                                        item.Stock <= 0 ?
                                          <div className="divPhotoSquare" style={{ backgroundImage: `url(${item.PhotoPath})` }}>
                                            <div className="disablePhotoSquare">
                                              {i18next.t('NOSTOCK')}
                                            </div>
                                          </div>
                                          :
                                          <div className={`${item.VariationId == this.state.selectedSizeId ? "selectedSquare" : "divPhotoSquare"}`} style={{ backgroundImage: `url(${item.PhotoPath})` }} onClick={() => { this.onFontColorSelect(item); }}></div>
                                      ))
                                    }
                                  </div>
                                </>
                                :
                                <div className="d-flex justfy-content-center align-items-center mb-4">
                                  <div className="divProductDetailSelectLabel">{this.state.ProductVariationName}:</div>
                                  <Select placeholder={this.state.ProductVariationName} styles={customStyles} options={sizeOptions} isSearchable={false} className="productDetailSelect text-center"
                                    onChange={this.onChangeSelectSize}>
                                  </Select>
                                </div>
                          }
                          {
                            (this.state.OptionListName != null && this.state.OptionListName != undefined && this.state.OptionListName != "") &&
                            <div className="d-flex justfy-content-center align-items-center mb-4">
                              <div className="divProductDetailSelectLabel">{this.state.OptionListName}:</div>
                              <Select placeholder={this.state.OptionListName} styles={customStyles} options={productOptions} isSearchable={false} className="productDetailSelect text-center"
                                onChange={this.onChangeProductOption} >
                              </Select>
                            </div>
                          }
                          <div className="d-flex justfy-content-center align-items-center">
                            <div className="divProductDetailSelectLabel">{i18next.t('Adet')}</div>
                            <div className="divProductQuatity">
                              <div className="spanMinus" onClick={() => { this.handleSpanMinus(); }}>-</div>
                              <div className="divCount">{this.state.selectedCountId}</div>
                              <div className="spanPlus" onClick={() => { this.handleSpanPlus(); }}>+</div>
                            </div>
                          </div>
                          <div style={{ width: '100%' }} className="d-flex justfy-content-center align-items-center row">
                            {linkedProducts()}
                          </div>
                        </div>
                        <div className="productDetailButtons">
                          <a onClick={this.AddToCart} className={this.state.addCartLoading === true ? "submit-button2  loading" : "submit-button2 "}>
                            <span className="d-flex">
                              <svg className="iconSize-xxs" >
                                <use href={Sprite + "#menu-shopping2"} ></use>
                              </svg > &nbsp;
                              {this.state.addCartLoading === true ? i18next.t('AddtoCart') : i18next.t('AddtoCart')}
                            </span>
                          </a>
                          <a onClick={this.handleBuyNow} className={this.state.addCartLoading === true ? "submit-button  loading" : "submit-button "}>
                            {this.state.addCartLoading === true ? i18next.t('BuyNow') : i18next.t('BuyNow')}
                          </a>
                        </div>
                      </>
                    }
                    <span onClick={() => { this.state.favoriteIds.indexOf(_productId) >= 0 ? this.removeFavorites(_productId) : this.AddFavorite(_productId) }} className={this.state.favoriteIds.indexOf(_productId) >= 0 ? "product-card__addFavorite  col-lg-2  d-lg-block d-none ml-3 ProductDetail-favoriteActive" : "product-card__addFavorite  col-lg-2  d-lg-block d-none ml-3"} >
                      <svg className="iconSize-sm">
                        <use href={Sprite + "#like-button"} ></use>
                      </svg >
                    </span>
                  </div>
                }
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ProductDetail__productHistory">
                  <h4 className="h-size-1"> {i18next.t('ProductDetail')}</h4>
                  {this.state.loading === true ?
                    <div className="loadingImage"></div>
                    :
                    <>
                      <p className="p-size-1 divProductDetailDesc" dangerouslySetInnerHTML={{ __html: this.state.ProductDetailData.Description }}></p>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className="container product-card--pd">
              <div className="product-card">
                <h3 className="text-center my-4"> {i18next.t('SimilarPieces')}</h3>
                <Slider {...settings}>
                  {ListItem}
                </Slider>
              </div>
            </div>
          </div>
          <ToastContainer />
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center">{ExceptionTranslate(this.state.errorMessage)}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => { this.setState({ showErrorModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(ProductDetail);