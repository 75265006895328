/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import ProfilDetailCard from '../Components/ProfilDetailCard';
import FavorilerimItems from '../Components/FavoriItems2';
import Slider from "react-slick";
import i18next from 'i18next';
import ProductCard from '../Components/ProductCard';
import UserDetailCardMobil from '../Components/UserDetailCardMobil';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Sprite from '../Assets/Icons/sprite.svg';
import { ToastContainer, toast } from 'react-toastify';
import ExceptionTranslate from '../lib/ExceptionTranslate'

const Favorilerim = observer(class Favorilerim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favoriteList: [],
      isFavorited: -1,
      RecommendedProducts: [],


      favoriteIds: [],
      count: 1,
      selectedValue: null,
      FavoriteList: [],
    };
  }

  componentDidMount() {
    this.getFavourites();
    this.getRecommendedProducts();
    this.getFavoriteIds();
  }

  AddFavorite = (ProductId) => {
    this.setState({ addFavoriteLoading: true })
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }
    ApiBase.Post({
      url: '/add-favourite',
      data: {
        ProductId: ProductId,
        CurrentUser: cUser
      },
      successFunction: (e) => {
        this.getFavoriteIds();
        this.getFavourites();
        this.setState({ addFavoriteLoading: false })
      },
      errorFunction: (e) => {
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message })
      }
    })
  }

  getFavoriteIds = () => {
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var favIdList = [];
    ApiBase.Post({
      url: '/get-favorite-ids',
      data: {
        UserId: _UserId,
      },
      successFunction: (e) => {
        e.data.data.List.map(item =>
          favIdList.push(item.ProductId));
        this.setState({ favoriteIds: favIdList })
      },
      errorFunction: (e) => {
      }
    })
  }

  getRecommendedProducts = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-featured-products',
      data: {
        LanguageId: LangId,
        CountryId: LangId
      },
      successFunction: (e) => {
        this.setState({
          RecommendedProducts: e.data.data.productList,
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
      }
    })
  }


  getFavourites = () => {
    this.setState({ actLoading: true });
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/favorite-products',
      data: {
        LanguageId: LangId,
        CurrenUser: cUser,
        CountryId: LangId
      },
      successFunction: (e) => {
        this.setState({ FavoriteList: e.data.data.List });
      },
      errorFunction: (e) => {
        this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message });
      }
    })
  }

  removeFavorites = (ProductId) => {
    this.setState({ addFavoriteLoading: true });
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);


    ApiBase.Post({
      url: '/delete-favourite',
      data: {
        ProductId: ProductId,
        CurrenUser: cUser
      },
      successFunction: (e) => {
        // console.log("remove", e.data)
        this.setState({ addFavoriteLoading: false })
        this.getFavourites();
        this.getFavoriteIds();
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }

  clearFavoriteList = () => {
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);


    ApiBase.Post({
      url: '/clear-favourite-products',
      data: {
        CurrenUser: cUser
      },
      successFunction: (e) => {
        // console.log("remove", e.data)
        this.getFavourites();
        this.getFavoriteIds();
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }
  notify = () => toast(ExceptionTranslate('Sepete Eklendi!'), {
    className: "Toastify__toast--default",
    bodyClassName: ".Toastify__toast-body",

    autoClose: 3000
  });
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.RecommendedProducts.length < 4 ? this.state.RecommendedProducts.length : 4,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplaySpeed: 3000,
            arrows: false,


          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            autoplaySpeed: 3000,
            arrows: false,



          }
        }
      ]

    };
    const ListItem = this.state.RecommendedProducts.map((pitem) => {
      // console.log(pitem)
      return (
        <li key={pitem.ProductId} >
          <ProductCard item={pitem} _className={this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? "product-card__like-button active" : "product-card__like-button"} toggleFavorite={() => { this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? this.removeFavorites(pitem.Id) : this.AddFavorite(pitem.Id) }} history={this.props.history} ></ProductCard>
        </li>
      );
    });



    const favorilerimItems = this.state.FavoriteList.map((item) => {

      return (
        <div className="col-12">
          <FavorilerimItems notify={this.notify} url={`/${localStorage.getItem("lang")}/productdetail/${item.Url}`} removeItem={() => this.removeFavorites(item.ProductId)} onChangeValues={this._onChangeSizeValues} item={item} />
        </div>
      )
    });
    return (
      <>
        {this.state.addFavoriteLoading == true &&
          <div className="favoriteoverlay">

          </div>
        }
        <div className="">
          <div className="container">
            <BreadCrumb hesabim path={i18next.t("MyFavorites")}></BreadCrumb>
            <div className="row  Favorilerim">
              <div className="col-lg-3  d-none d-lg-block   UserDetail__section1">
                <ProfilDetailCard className3={"bg--secondary"}></ProfilDetailCard>
              </div>
              <div className="col-lg-3  d-lg-none UserDetail__section1">
                <UserDetailCardMobil active3></UserDetailCardMobil>
              </div>
              <div className="col-lg-9 UserDetail  UserDetail__section2">
                <div className="divProfileDetailTitle divGilroy font-weight-bold mb-2">{i18next.t("MyFavorites")}</div>
                <div className="row">
                  {favorilerimItems}
                </div>
              </div>
            </div>
            <a onClick={this.clearFavoriteList} className="text-right d-block h-size-2 text-dark font-weight-bold">{i18next.t("ClearList")}</a>
            <h3 className="text-center my-4"> {i18next.t('RecomendedForYou')}</h3>
            <div className=" product-card--pd">
              <div className="product-card">
                <Slider {...settings}>
                  {ListItem}
                </Slider>
              </div>
            </div>

          </div>
        </div>
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => this.setState({ showErrorModal: false })}>
          <Modal.Header closeButton>

          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
          <p></p>
          <Modal.Body>
            <div className="text-center mb-3">
              <svg className="iconSize-md" >
                <use href={Sprite + "#sad-face"} ></use>
              </svg >
            </div>
            <form className="LoginModal  ">
              <p className="text-center  p-size-1">{ExceptionTranslate(this.state.errorMessage)}</p>
              <div className="text-center  w-50  mx-auto">
                <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('OK')}</a>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </>
    )
  }
}
)
export default inject((stores) => ({
  Store: stores.Store,
}))(Favorilerim);