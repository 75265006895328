import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import SozlesmeCard from '../Components/SozlesmeCard';
import SozlesmeCardMobil from '../Components/SozlesmeCardMobil';
import i18next from 'i18next';

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="sozlesme body--margin">
                <div className="container-fluid">
                    <div className="container">
                        <BreadCrumb path={i18next.t("FtPrivacyPolicy")}></BreadCrumb>
                    </div>
                </div>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-3  d-none d-lg-block ">
                            <SozlesmeCard className3={"bg--secondary"} />
                        </div>
                        <div className="col-lg-3  d-lg-none UserDetail__section1">
                            <SozlesmeCardMobil active3></SozlesmeCardMobil>
                        </div>
                        <div className="col-lg-9">
                            <h4 className="h-size-4 mt-3"> GİZLİLİK KURALLARI İLE KİŞİSEL VERİLERİNİZE İLİŞKİN BİLGİLENDİRME </h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
