/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg'
import i18next from 'i18next';

export default class ProfilDetailCard extends Component {
  render() {
    const { className1, className2, className3, className4, className5, className6 } = this.props;
    return (
      <div className="ProfilDetailCard">
        <div className="ProfilPopever__body">
          <p className={className1}>
            <svg className="iconSize-xxxs" >
              <use href={Sprite + "#profile-icon"} ></use>
            </svg> <a href={`/${localStorage.getItem("lang")}/profilim`} className="p-size-3">{i18next.t('MyProfile')} </a>
          </p>
          <p className={className2}>
            <svg className="iconSize-xxxs" >
              <use href={Sprite + "#truck"} ></use>
            </svg ><a href={`/${localStorage.getItem("lang")}/siparislerim`} className="p-size-3"> {i18next.t('MyOrders')}</a>
          </p>
          <p className={`${className3} p-0`}>
            <p className="ProfilPopever__like-button">
              <svg className="iconSize-xxxs" >
                <use href={Sprite + "#like-button"} ></use>
              </svg ><a href={`/${localStorage.getItem("lang")}/favorilerim`} className="p-size-3">{i18next.t('MyFavorites')}</a>
            </p>
          </p>
          <p className={className4}>
            <svg className="iconSize-xxxs" >
              <use href={Sprite + "#map-pin"} ></use>
            </svg ><a href={`/${localStorage.getItem("lang")}/adreslerim`} className="p-size-3">{i18next.t('MyAdresses')}</a>
          </p>
          <p className="ProfilPopever__logout">
            <svg className="iconSize-xxxs" >
              <use href={Sprite + "#log-out"} ></use>
            </svg ><a onClick={this.onPressLogOut} href="#" className="p-size-3"> {i18next.t('LogOut')}</a>
          </p>
        </div>
      </div>
    )
  }
}
