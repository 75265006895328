/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg';
import { slide as Menu } from 'react-burger-menu';
import Logo from '../Assets/Image/logo.png';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import SepetComponent from '../Components/SepetComponent';
import { inject, observer } from 'mobx-react';
import ApiBase from '../lib/ApiBase';
import ExceptionTranslate from '../lib/ExceptionTranslate'
import Select from 'react-select';
import { Helmet } from "react-helmet";
const Navbar = observer(class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: "searchbar",
      menuOpen: false,
      dropdownOpen: false,
      blogdropdownOpen: false,
      showLoginModal: false,
      profilePopup: false,
      showSuccesModal: false,
      showSuccessActivationModal: false,
      showErrorModal: false,
      forgotPasswordModal: false,
      showAlertModal: false,
      showNewPasswordModal: false,
      isLogin: false,
      profilePopupTarget: null,
      userName: "",
      password: "",
      newPasswordAgain: "",
      newPassword: "",
      searchBarVisible: false,
      searchBarFocus: false,
      searchBarVisibleMobile: false,
      searchBarFocusMobile: false,
      hideIcon: false,
      showChildrenId: 0,
      inputValue: '',
      ForgotEmail: "",
      imageClassName: ' matrix3',
      LayoutInfo: [],
      SearchText: "",
      CategoryList: [],
      CartList: [],
      CartTotalPriceStr: "",
      navBarClassName: 'navbar__item2 smaller1',
      favoriteIds: [],
      SearchList: [],
      searchbarText: "",
      WebTitle: "",
      WebDescription: "",
      ClientCountry: ''
    };

    this.ref = React.createRef();
    this.sepetRef = React.createRef();

    //  console.log("aasdasda",JSON.parse(this.props.Store.CurrentUserStore.CurrentUser))
    // console.log(localStorage.getItem("AccessToken"))
  }
  // onClickMenu = () => {
  //   this.setState({ menuOpen: !this.state.menuOpen })
  // }
  _loginFunction = () => {
    var AnonymousUserId = localStorage.getItem("AnonymousUserId")
    this.props.Store.CurrentUserStore.Login(this.state.userName, this.state.password, AnonymousUserId, this._errorFunction)
    this.setState({ showLoginModal: false })
  }

  removeCartProduct = (item) => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;

    // console.log(item.Quantity + 1)

    ApiBase.Post({
      url: '/remove-cart-product',
      data: {
        LanguageId: LangId,
        ProductId: item.ProductId,
        AnonymousUserId: AUID,
        UserId: _UserId,
      },
      successFunction: (e) => {
        this.setState({ addCartLoading: false, });
        this.getCartCount();
        this.getMenuCart();
      },
      errorFunction: (e) => {
        this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
      }
    })
  }


  _errorFunction = () => {
    var errorMessage = localStorage.getItem("errorMessage")
    this.setState({ errorMessage: errorMessage, showErrorModal: true })
  }

  componentDidMount() {
    this.getLayoutInfo();
    this.getSeoData();
    this.getCategories();
    this.generateGuid();
    this.getCartCount();

    if (this.props.location.pathname === "/adresbilgisi" || this.props.location.pathname === "/odemeyontemi") {
    } else {
      localStorage.removeItem("adresBilgileri")
    }
  }


  getSeoData = () => {
    this.setState({ seoDataLoading: true });

    var Lang = localStorage.getItem("lang");

    var LangId = Lang === "tr" ? 1 : 2;

    var currentUrl = this.props.location.pathname;

    var replacedUrl = "";

    if (LangId === 1) {
      replacedUrl = currentUrl.replace("tr/", "");
    } else {
      replacedUrl = currentUrl.replace("en/", "");
    }

    ApiBase.Post({
      url: '/get-seo-tag-data',
      data: {
        LanguageId: LangId,
        Pathname: replacedUrl,
        DataId: 0
      },
      successFunction: (e) => {
        this.setState({
          WebTitle: e.data.data.seoData.Title,
          WebDescription: e.data.data.seoData.Description
        })
      },
      errorFunction: (e) => {
      }
    })
  }

  getCartCount = () => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    this.props.Store.CartStore.GetCartCount(_UserId, AUID)
  }

  generateGuid = () => {
    const isLogin = localStorage.getItem("isLogin")
    var AUID = localStorage.getItem("AnonymousUserId")
    if (isLogin !== "true") {

      if (AUID == null) {
        ApiBase.Post({
          url: '/generate-guid',
          data: {

          },
          successFunction: (e) => {
            localStorage.setItem("AnonymousUserId", e.data.data)
          },
          errorFunction: (e) => {
          }
        })
      }
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      window.location.pathname = `/${localStorage.getItem("lang")}/searchproducts/${false}/${false}/${this.state.SearchText}`;
    }
  }

  getSearch = (e) => {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    this.setState({ SearchText: e.target.value }, () => {
      ApiBase.Post({
        url: '/search-products',
        data: {
          searchText: this.state.SearchText,
          LanguageId: LangId,
        },
        successFunction: (e) => {
          this.setState({
            searchbarText: (e.data.data.List.length <= 0 && this.state.SearchText !== "") ? i18next.t('NoResult') : "",
            SearchList: e.data.data.List
          });
        },
        errorFunction: (e) => {
        }
      })
    })
  }


  getMenuCart = () => {
    this.setState({ loadingCart: true })
    var AUID = localStorage.getItem("AnonymousUserId")

    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;

    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }

    var Lang = localStorage.getItem("lang");
    var LangId = Lang === "tr" ? 1 : 2;

    ApiBase.Post({
      url: '/get-menu-cart',
      data: {
        AnonymousUserId: AUID,
        UserId: _UserId,
        LanguageId: LangId,
        CountryId: LangId
      },
      successFunction: (e) => {
        this.setState({
          CartList: [],
          CartList: e.data.data.List,
          CartTotalPriceStr: e.data.data.TotalPriceStr,
          loadingCart: false
        })
      },
      errorFunction: (e) => {
        this.setState({ loadingCart: false })
      }
    })
  }


  /////
  getCategories = () => {
    this.setState({ loadingBlogDetail: true });
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-categories',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        this.setState({
          CategoryList: e.data.data.categories
        });
      },
      errorFunction: (e) => {
      }
    })
  };

  getLayoutInfo = () => {
    ApiBase.Post({
      url: '/get-layout-info',
      data: {},
      successFunction: (e) => {
        this.setState({
          LayoutInfo: e.data.data,
          ClientCountry: e.data.data.ClientCountry
        });
      },
      errorFunction: (e) => {
        this.setState({
          ClientCountry: "Turkey (TR)"
        });
      }
    })
  };

  ////

  onClickDropdown = () => {

    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  ClickBlogDropdown = () => {

    this.setState({ blogdropdownOpen: !this.state.blogdropdownOpen })
  }
  menuOpen = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  navigateToShop = (item) => {
    this.props.history.push({ pathname: `/shop:${item.CategoryName}/:${item.Id}`, Id: item.Id, CategoryName: item.CategoryName });
    this.setState({ menuOpen: false })
  }

  showLoginModal = (event) => {
    const isLogin = localStorage.getItem("isLogin")
    //console.log(isLogin)
    if (isLogin == "true") {
      this.setState({
        profilePopup: !this.state.profilePopup,
        profilePopupTarget: event.target,
      })
    } else {
      this.setState({ showLoginModal: !this.state.showLoginModal })
    }

  }
  showAlertModal = () => {
    this.setState({ showAlertModal: true, onPressForgotPassword: false })

  }
  hideLoginModal = () => {
    this.setState({
      showLoginModal: false,
      showSuccesModal: false,
      showAlertModal: false,
      forgotPasswordModal: false,
      showNewPasswordModal: false,
      showSuccessActivationModal: false
    })
  }

  showSepetPopover = (event) => {
    this.setState({
      sepetPopup: !this.state.sepetPopup,
      sepetPopupTarget: event.target,
    }, () => {
      if (this.state.sepetPopup == true) {
      } else {
      }
      this.getMenuCart();
    })
  };
  hideProfilePopup = () => {
    this.setState({ profilePopup: false })
  }
  hideSepetPopup = () => {
    this.setState({ sepetPopup: false });
    // document.body.className = "";
  }


  showNewPasswordModal = () => {
    this.setState({ showNewPasswordModal: true })

  }
  showSuccessActivationModal = () => {
    this.setState({ showSuccessActivationModal: true, showNewPasswordModal: false })

  }

  onPressForgotPassword = () => {
    this.setState({ forgotPasswordModal: true, showLoginModal: false, showAlertModal: false })

  }

  onPressLogOut = () => {
    localStorage.setItem("isLogin", false);
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("CurrentUser");

    this.props.history.push(`/${localStorage.getItem("lang")}/`);
    window.location.reload();
  }

  clearSepet = () => {
    localStorage.removeItem("cartList");
    this.setState({ sepetPopup: false })
  }

  onClickSearchBar = () => {
    this.setState({
      searchBarVisible: !this.state.searchBarVisible,
      searchBarFocus: !this.state.searchBarFocus

    }, () => {
      setTimeout(() => {
        this.setState({ className: "searchbar searchbar--w" })
      }, 250);
    })
    if (this.state.searchBarVisible === false) {
      this.setState({ className: "searchbar" })
    }
  }

  onClickMenuCategoryItem = (item) => {

    if (this.state.showChildren === item.Id) {
      this.setState({ showChildren: 0 })
    } else {
      this.setState({ showChildren: item.Id })
    }
  }

  onClickSearchBarMobile = () => {
    this.setState({
      searchBarVisibleMobile: !this.state.searchBarVisibleMobile,
      searchBarFocusMobile: !this.state.searchBarFocusMobile
    }, () => {
      setTimeout(() => {
        this.setState({ className: "searchbar searchbar--w bg-transparent" })
      }, 250);
    })
    if (this.state.searchBarVisibleMobile === false) {
      this.setState({ className: " searchbar bg-transparent" })
    }
  }

  // resizeHeaderOnScroll = () => {
  //   const distanceY = window.pageYOffset || document.documentElement.scrollTop;

  //   let headerEl = document.querySelector('.web-navbar');

  //   // let imageEl = document.querySelector('.lg-img')




  //   if (distanceY > 95) {

  //     headerEl.classList.add("smaller")
  //     // console.log("asdasdas", headerEl.classList);
  //     // this.setState({ navBarClassName: 'navbar__item2 smaller1' })
  //     this.setState({ imageClassName: "img-fluid matrix3" })
  //   } else {


  //     headerEl.classList.remove("smaller")
  //     // this.setState({ navBarClassName: 'navbar__item2 lg-img' })
  //     this.setState({ imageClassName: "img-fluid matrix1" })
  //   }
  // }


  forgotPasswordSendMail = () => {
    this.setState({ actLoading: true })

    ApiBase.Post({
      url: '/send-forgot-password-mail',
      data: {
        Email: this.state.ForgotEmail
      },
      successFunction: (e) => {
        this.setState({ showAlertModal: true });
      },
      errorFunction: (e) => {
        this.setState({ errorMessage: e.data.message, showErrorModal: true });

      }
    })
  }

  urlToBeForwardedFunc = (lang) => {
    localStorage.setItem("lang", lang);

    const currentPathNameArray = window?.location?.pathname?.split('/');

    let _targetHref = "";

    for (let i = 0; i < currentPathNameArray.length; i++) {
      if (!((i === 0 && currentPathNameArray[i] === "") || (i === 1 && (currentPathNameArray[i] === "" || currentPathNameArray[i] === "tr" || currentPathNameArray[i] === "en")))) {
        _targetHref += "/" + currentPathNameArray[i];
      }
    }

    window.location.href = `/${lang}${_targetHref}`;
  }

  render() {
    const catList = this.state.CategoryList.map((item) => {
      return (
        <li key={item.Id} className="nav__link ">
          <a href={`/${localStorage.getItem("lang")}/shop/${item.Url}`}>{item.Name} </a>
          <a className="pl-3 p-size--b" onClick={() => { this.onClickMenuCategoryItem(item) }} >{item.Children.length > 0 &&
            <>
              {this.state.showChildren === item.Id ?
                <svg className="iconSize-xs mr-5" >
                  <use href={Sprite + "#minus"} ></use>
                </svg >
                :
                <svg className="iconSize-xs mr-5" >
                  <use href={Sprite + "#add-sharp"} ></use>
                </svg >}
              <>
              </>
            </>
          }</a>

          {this.state.showChildren === item.Id &&
            item.Children.length > 0 && item.Children.map(item2 => {
              return (
                <a href={`/${localStorage.getItem("lang")}/shop/${item2.Url}`} >
                  <li key={item2.Id} className="nav__sublink pl-3 p-size-2">
                    {item2.Name}
                  </li>
                </a>
              )
            })
          }
        </li>
      )
    })

    const menuBlogList = [];

    menuBlogList.push(
      <a>
        <li className="nav__sublink ">
          <a href={`/${localStorage.getItem("lang")}/blogandmagazine`}>Blog</a>
        </li>
        <li className="nav__sublink ">
          <a href={`/${localStorage.getItem("lang")}/fashion`}>{i18next.t('Fashion')}</a>
        </li>
        <li className="nav__sublink ">
          <a href={`/${localStorage.getItem("lang")}/artsandworkshops`}>{i18next.t('ArtsWorkshops')}</a>
        </li>
        <li className="nav__sublink ">
          <a href={`/${localStorage.getItem("lang")}/isbirlikleri`}>{i18next.t('Isbirlikleri')}</a>
        </li>
      </a>
    );

    const { history } = this.props;

    const SepetList =
      this.state.CartList != [] ?
        this.state.CartList.map((item, index) => {
          return (
            <SepetComponent item={item} removeCartItem={() => { this.removeCartProduct(item) }}
            />
          )
        })
        :
        [];

    return (
      <nav>
        <Helmet>
          <title>{this.state.WebTitle}</title>
          <meta name="title" content={this.state.WebTitle} />
          <meta name="description" content={this.state.WebDescription} />
        </Helmet>
        <div id="js-header" className=" web-navbar shadow-sm  web-navbar--home  container-fluid smaller">
          <div className="container d-flex  justify-content-between align-items-center">
            <div className="nav__item1 d-flex ">
              <a onClick={this.menuOpen} className="menu-burger d-lg-inline d-none">
                <svg className="iconSize-xs" >
                  <use href={Sprite + "#menu-hamburger"} ></use>
                </svg >
              </a>
              <div className="spanMenu  ml-3 my-auto">{i18next.t("MENU")}</div>
            </div>
            {this.state.searchBarVisible === false ?
              <div className={this.state.navBarClassName}>
                <a href={`/${localStorage.getItem("lang")}/`}>
                  <img src={Logo} className={this.state.imageClassName} />
                </a>
              </div>
              :
              <>
                <div className="nav__item4">
                  <input placeholder={i18next.t("SearchPlaceholder")} type="text" autoFocus={this.state.searchBarFocus} className={this.state.className} value={this.state.SearchText} onChange={(e) => this.getSearch(e)} onKeyDown={this._handleKeyDown} />
                  {this.state.SearchText !== "" ?
                    <div className="suggestion-result">
                      <p className="suggestion-result-title h-size-3 font-weight-bold"> {i18next.t("RelatedResults")}</p>
                      <ul className="list-unstyled">
                        {this.state.SearchList.map((item) => {
                          return (
                            <a href={`/${localStorage.getItem("lang")}/productdetail/${item.Url}`} >{item.Title}</a>
                          )
                        })}
                      </ul>
                      {this.state.SearchList.length > 0 ? <a class="suggestin-all-result" href={`/${localStorage.getItem("lang")}/searchproducts/${false}/${false}/${this.state.SearchText}`}>{i18next.t("SeeAll")}</a> : <p>{this.state.searchbarText}</p>}

                    </div>
                    :
                    <></>}
                </div>
              </>
            }
            <div className="nav__item3 d-flex justify-content-between align-items-center ">
              <a onClick={this.onClickSearchBar} className="d-lg-inline d-none sb2">
                <svg className="iconSize-xs" >
                  <use href={this.state.searchBarVisible === true ? Sprite + "#navbar-close" : Sprite + "#menu-search"} ></use>
                </svg >
              </a>
              <a onClick={this.showLoginModal} className="d-lg-inline d-none">
                <svg className="iconSize-xs" >
                  <use href={Sprite + "#profile-icon"} ></use>
                </svg >
              </a>
              <a onClick={this.showSepetPopover} className="   align-items-center d-lg-flex d-none">
                <svg className="iconSize-xs" >
                  <use href={Sprite + "#menu-shopping"}  ></use>
                </svg >
                {this.props.Store.CartStore.CartCount > 0 && this.props.Store.CartStore.CartCount &&
                  <span className="countItemInBasket font-weight-bold">{this.props.Store.CartStore.CartCount > 0 && this.props.Store.CartStore.CartCount} </span>

                }
              </a>
            </div>
          </div>
          <div className="container top-links justify-content-around d-none d-lg-flex">
            {
              this.state.CategoryList.map((item, index) => {
                if (index < 3) {
                  return (
                    <a href={`/${localStorage.getItem("lang")}/shop/${item.Url}`}>{item.Name} </a>
                  );
                }
              })
            }
            {/* <a className="colorDiscount" href={`/${localStorage.getItem("lang")}/searchproducts/false/false/true`}>{i18next.t("SALE")}</a> */}
            <a href={`/${localStorage.getItem("lang")}/about`}>{i18next.t("ABOUT")}</a>
            <a href={`/${localStorage.getItem("lang")}/musterihizmetleri`}>{i18next.t("CONTACT")}</a>
          </div>
        </div>
        <div className="mobil-navbar d-lg-none d-block">
          <div className=" container d-flex  justify-content-between align-items-center">
            {this.state.searchBarVisibleMobile === true &&
              <div className="nav__item4">
                <input placeholder={i18next.t("SearchPlaceholder")} type="text" autoFocus={this.state.searchBarFocusMobile} className={this.state.className} value={this.state.SearchText} onChange={(e) => this.getSearch(e)} onKeyDown={this._handleKeyDown} />
                {this.state.SearchText !== "" ?
                  <div className="suggestion-result">
                    <p className="suggestion-result-title h-size-3 font-weight-bold"> {i18next.t("RelatedResults")} </p>
                    <ul className="list-unstyled">
                      {this.state.SearchList.map((item) => {
                        return (
                          <a href={`/${localStorage.getItem("lang")}/productdetail/${item.Url}`} >{item.Title}</a>
                        )
                      })}
                    </ul>
                    {this.state.SearchList.length > 0 ? <a class="suggestin-all-result" href={`/${localStorage.getItem("lang")}/searchproducts/${false}/${false}/${this.state.SearchText}`}>{i18next.t("SeeAll")}</a> : <p>{this.state.searchbarText} </p>}

                  </div>
                  :
                  <></>}
              </div>
            }
            {this.state.searchBarVisibleMobile === false &&
              <a onClick={this.menuOpen} className="menu-burger">
                <svg className="iconSize-xs" >
                  <use href={Sprite + "#menu-hamburger"} ></use>
                </svg >
              </a>
            }
            <div className={`nav__item3   d-flex justify-content-between ${this.state.searchBarVisibleMobile === true ? "ml-auto" : "w-50 ml-auto"}`}>
              <a onClick={this.onClickSearchBarMobile} className="d-lg-none d-inline-block">
                <svg className="iconSize-xs" >
                  <use href={this.state.searchBarVisibleMobile === true ? Sprite + "#navbar-close" : Sprite + "#menu-search"} ></use>
                </svg >
              </a>
              {this.state.searchBarVisibleMobile === false &&
                <a onClick={this.showLoginModal} className=" d-lg-none d-inline-block ">
                  <svg className="iconSize-xs" >
                    <use href={Sprite + "#profile-icon"} ></use>
                  </svg >
                </a>
              }
              {this.state.searchBarVisibleMobile === false &&
                <a onClick={this.showSepetPopover} className="  d-flex align-items-center">
                  <svg className="iconSize-xs" >
                    <use href={Sprite + "#menu-shopping"}  ></use>
                  </svg >
                  {
                    this.props.Store.CartStore.CartCount > 0 && this.props.Store.CartStore.CartCount &&
                    <span className="countItemInBasket font-weight-bold">{this.props.Store.CartStore.CartCount > 0 && this.props.Store.CartStore.CartCount} </span>
                  }
                </a>
              }

            </div>
          </div>
        </div>
        <Menu onClose={() => { this.setState({ menuOpen: false }) }} isOpen={this.state.menuOpen} customBurgerIcon={false} customCrossIcon={false}>
          <a className="aCloseMenu" onClick={this.menuOpen}>
            <svg className="iconSize-xs close-sharp" >
              <use href={Sprite + "#close-sharp"} ></use>
            </svg>
            <div>{i18next.t("MENU")}</div>
          </a>
          <div className="mb-4 divLangRow">
            <span className={`mr-4 ${localStorage.getItem("lang") == "tr" ? "active" : ""}`} onClick={() => { this.urlToBeForwardedFunc("tr"); }}>TR</span>
            <span className={`mr-auto ${localStorage.getItem("lang") == "en" ? "active" : ""}`} onClick={() => { this.urlToBeForwardedFunc("en"); }}>EN</span>
          </div>
          <ul className="nav__links list-unstyled">
            {catList}
            {/* <li className="nav__link">
              <a className="colorDiscount" href={`/${localStorage.getItem("lang")}/searchproducts/false/false/true`}>{i18next.t("SALE")}</a>
            </li> */}
            <li className="nav__link">
              <a href={`/${localStorage.getItem("lang")}/about`}>{i18next.t("ABOUT")}</a>
            </li>
            <li className="nav__link">
              <a href={`/${localStorage.getItem("lang")}/musterihizmetleri`}>{i18next.t("CONTACT")}</a>
            </li>
          </ul>
          <div className="divMenuLogoContainer">
            <img src={Logo} />
          </div>

        </Menu>
        {/* Login Modal */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showLoginModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('MemberLogin')}</h4>
          <p></p>
          <Modal.Body>
            <form className="LoginModal">
              <div className="divFormSubTitle">{i18next.t('Email')}</div>
              <input type="mail" className="form-control divFromInput" placeholder={i18next.t('Email')} value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value })} />
              <div className="divFormSubTitle">{i18next.t('Password')}</div>
              <input type="password" className="form-control divFromInput" placeholder={i18next.t('Password')} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
              <div className="text-center  w-50  mx-auto">
                <a className="h-size-3 p-size--b submit-button" onClick={() => { this._loginFunction() }}>{i18next.t('SignIn')}</a>
              </div>
            </form>
            <div className="LoginModal__links">
              <div className=" text-center">
                <a className="p-size-2 divGilroy font-weight-bold" onClick={this.onPressForgotPassword} >{i18next.t('ForgotPassword')}</a>
                <div className="LoginModal__hr pt-2 pb-2 text-black  mx-auto w-50">
                  <p className="mx-auto"></p>
                </div>
                <p className="p-size-2 divGilroy"><div className="mb-3">{i18next.t('DontYouHaveaMemberShip')}</div> <a className="submit-button2 w-50 mx-auto" href={`/${localStorage.getItem("lang")}/uyeol`}> <strong> {i18next.t('SignUp')}</strong></a></p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Succes Modal */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccesModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center"> {i18next.t("ProcessSuccess")}</h4>
          <p></p>
          <Modal.Body>
            <div className="text-center mb-3">
              <svg className="iconSize-md" >
                <use href={Sprite + "#smile"} ></use>
              </svg >
            </div>
            <form className="LoginModal  ">
              <p className="text-center p-size-1"> {i18next.t("LoginSuccessMessage")}</p>
              <div className="text-center  w-50  mx-auto">
                <button onClick={() => history.push('/')} className="submit-button">{i18next.t('Home')}</button>
              </div>
            </form>

          </Modal.Body>
        </Modal>
        {/* Error Modal  */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
          <p></p>
          <Modal.Body>
            <div className="text-center mb-3">
              <svg className="iconSize-md" >
                <use href={Sprite + "#sad-face"} ></use>
              </svg >
            </div>
            <form className="LoginModal  ">
              <p className="text-center  p-size-1">{ExceptionTranslate(this.state.errorMessage)}
                {/* {i18next.t('ErrorMessage')} */}
              </p>
              <div className="text-center  w-50  mx-auto">
                <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('Home')}</a>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/* Şifremi Unuttum Modal */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.forgotPasswordModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('ForgotPassword')}</h4>
          <p></p>
          <Modal.Body>
            <form className="LoginModal">
              <p className="text-center p-size-1">{i18next.t('ForgotPasswordText')}</p>
              <div className="divFormSubTitle">{i18next.t('EmailAdress')}</div>
              <input type="mail" className="form-control divFromInput" placeholder={i18next.t('EmailAdress')} value={this.state.ForgotEmail} onChange={(e) => this.setState({ ForgotEmail: e.target.value })} required />
              <div className="text-center  w-50  mx-auto">
                <a onClick={this.forgotPasswordSendMail} className="submit-button">{i18next.t('Send')}</a>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/* Alert Modal */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showAlertModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <form className="LoginModal">
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#smile"} ></use>
                </svg >
              </div>
              <p className="text-center h-size-3 p-size--color2 ">{i18next.t('AlertModalText')} </p>
              <div className="d-flex px-2">
                <div className="mx-auto ">
                  <p className=" p-size-1 mb-2"> {i18next.t('AlertModalTextHeader')}</p>
                  <p className=" p-size-3 font--opacity  mb-2"> {i18next.t('AlertModalText1')}</p>
                  <p className=" p-size-3  font--opacity mb-2"> {i18next.t('AlertModalText2')}</p>
                  <p className=" p-size-3  font--opacity mb-2">{i18next.t('AlertModalText3')}</p>
                </div>
              </div>
              <div className="text-center  w-50  mx-auto">
                <button onClick={this.onPressForgotPassword} className="submit-button">{i18next.t('TurnBack')}</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/*Şifre Yenileme Başarılı Modal*/}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccessActivationModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('ProcessSuccess')}</h4>
          <Modal.Body>
            <form className="LoginModal  ">
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#smile"} ></use>
                </svg >
              </div>
              <p className="text-center p-size-1">{i18next.t('NewPasswordSuccessText')}</p>
              <div className="text-center  w-50  mx-auto">
                <button onClick={() => history.push('/')} className="submit-button">{i18next.t('Home')}</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <div ref={this.ref} className="ProfilPopever">
          <Overlay
            rootClose={true}
            onHide={this.hideProfilePopup}
            show={this.state.profilePopup}
            target={this.state.profilePopupTarget}
            placement="bottom"
            container={this.ref.current}
            containerPadding={20}
          >
            <Popover id="popover-contained">
              <Popover.Title >
                <div className="ProfilPopever__header d-flex">
                  <h6 class="d-inline" >{i18next.t('MyAccount')}</h6>
                  <a onClick={this.hideProfilePopup} className="ml-auto">
                    <svg className="iconSize-xs" >
                      <use href={Sprite + "#close-sharp"} ></use>
                    </svg>
                  </a>
                </div>
              </Popover.Title>
              <Popover.Content>
                <div className="ProfilPopever__body">
                  <p>
                    <svg className="iconSize-xxxs" >
                      <use href={Sprite + "#profile-icon"} ></use>
                    </svg> <a href={`/${localStorage.getItem("lang")}/profilim`} className="p-size-3">{i18next.t('MyProfile')} </a>
                  </p>
                  <p>
                    <svg className="iconSize-xxxs" >
                      <use href={Sprite + "#truck"} ></use>
                    </svg ><a href={`/${localStorage.getItem("lang")}/siparislerim`} className="p-size-3"> {i18next.t('MyOrders')}</a>
                  </p>
                  <p className="ProfilPopever__like-button">
                    <svg className="iconSize-xxxs" >
                      <use href={Sprite + "#like-button"} ></use>
                    </svg ><a href={`/${localStorage.getItem("lang")}/favorilerim`} className="p-size-3">{i18next.t('MyFavorites')}</a>
                  </p>
                  <p>
                    <svg className="iconSize-xxxs" >
                      <use href={Sprite + "#map-pin"} ></use>
                    </svg ><a href={`/${localStorage.getItem("lang")}/adreslerim`} className="p-size-3">{i18next.t('MyAdresses')}</a>
                  </p>
                  <p className="ProfilPopever__logout">
                    <svg className="iconSize-xxxs" >
                      <use href={Sprite + "#log-out"} ></use>
                    </svg ><a onClick={this.onPressLogOut} className="p-size-3"> {i18next.t('LogOut')}</a>
                  </p>
                </div>
              </Popover.Content>
            </Popover>
          </Overlay>
        </div>
        <div ref={this.sepetRef}>
          <Overlay
            rootClose={true}
            onHide={this.hideSepetPopup}
            show={this.state.sepetPopup}
            target={this.state.sepetPopupTarget}
            placement="bottom"
            container={this.sepetRef.current}
            containerPadding={20}
          >
            <Popover id="popover-contained">
              <Popover.Title >
                <div className=" d-flex">
                  <a href={`/${localStorage.getItem("lang")}/sepetim`}>
                    <h6 class="d-inline" >{i18next.t('MyBasket')}</h6>
                  </a>
                  <a onClick={this.hideSepetPopup} className="ml-auto">
                    <svg className="iconSize-xs" >
                      <use href={Sprite + "#close-sharp"} ></use>
                    </svg>
                  </a>
                </div>
              </Popover.Title>
              <Popover.Content>
                <div className=" BasketPopever__body px-4 " id="style-4">
                  {SepetList}
                </div>
                {SepetList.length > 0 ?
                  <div className=" BasketPopever__footer px-4 ">
                    <div className="row BasketPopover__Result">
                      <div className="col-6 pl-0">
                        <p className=" BasketPopover__Total p-size-3 p-size--b">{i18next.t('GeneralPrice')}</p>
                      </div>
                      <div className="col-6 text-right">
                        <p className=" BasketPopover__TotalPrice p-size-3 p-size--b">{this.state.CartTotalPriceStr} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</p>
                      </div>
                    </div>
                    <div className="row p-0">
                      <div className="col-12 p-0">
                        <a href={`/${localStorage.getItem("lang")}/sepetim`} className="submit-button2  h-size-2">{i18next.t('GoCart')}</a>
                      </div>
                    </div>
                    <div className="row p-0">
                      <div className="col-12 p-0">
                        <a href={`/${localStorage.getItem("lang")}/adresbilgisi`} className="submit-button mb-3 h-size-2">{i18next.t('CompleteShopping')}</a>
                      </div>
                    </div>
                  </div>
                  :
                  this.state.loadingCart == true ? ""
                    :
                    <div className="col-12">
                      <p className=" BasketPopover__Total p-size-3 p-size--b">{i18next.t('CartEmpty')}</p>
                    </div>
                }
              </Popover.Content>
            </Popover>
          </Overlay>
        </div>
      </nav>
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(withRouter(Navbar));