/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg';
import i18next from 'i18next';
export default class UserDetailItemCard extends Component {
  render() {
    const { item } = this.props;
    // const { isReturnActive } = this.props;
    return (
      <div className="UserDetailItemCard  mt-4 d-flex justify-content-between flex-column flex-lg-row align-items-center">
        <div className=" col-lg-6 ">
          <div className="d-flex">
            <a href={`/${localStorage.getItem("lang")}/productdetail/${item.Url}`}>
              <img src={item.MainPhoto} style={{ width: 130, height: 118 }} className="UserDetailItemCard__img  mr-4"></img>
            </a>
            <div className="w-100">
              <a href={`/${localStorage.getItem("lang")}/productdetail/${item.Url}`}>
                <p className="product-card__title h-size-3 mb-2">{item.Title}</p>
              </a>
              <p className="product-card__brand ">{item.Designer.Name}</p>
              <p className="product-card__size font-size-1 my-2"> <span className="font--opacity"> {i18next.t("SizeUp")} :</span> {item.Size}</p>
              <p className="product-card__count font-size-1 mb-2"> <span className="font--opacity">{i18next.t("CountUp")} : </span> {item.Quantity}</p>
              <p className="product-card__price d-block d-lg-none font-size-1 mb-2"><span className="font--opacity"> {i18next.t("TotalPrice")} </span> : <span className="font-weight-bold"> {item.Price} TL</span> </p>
            </div>
          </div>
        </div>
        <div className=" col-lg-3  d-none d-lg-block h-size-3 p-size--b" > {item.Price} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</div>
        <div className="col-lg-3">
          {/* {isReturnActive == true ?
                            <input className="form-control" style={{ marginLeft:60 }} type="checkbox" />
                            :
                            <></>
                        } */}
          {/* <a href={`/uruniade/${item.Id}`} className="btn btn-primary btnReturn">{i18next.t("ReturnBtn")}</a> */}


          {/* <div className="col-lg-12 col-6 p-0 px-1 px-lg-0 mb-lg-2 mb-0 order-2 order-lg-1">
                            <div className=" " >
                                <a onClick={this.props.AddToCart} className=" UserDetailAddBasket w-100 p-size-3 p-size--b">
                                    <svg className="iconSize-xxs mr-2" >
                                        <use href={Sprite + "#menu-shopping"} ></use>
                                    </svg >
                                    {i18next.t("AddtoCart")}</a>
                            </div>
                        </div> */}
          {/* <div className="col-lg-12 col-6 p-0 order-1 order-lg-2">
                            <div className="">
                                <a href="#" className="UserDetailReturnRequest w-100 p-size-3 p-size--b w-100  "> {i18next.t("RefundRequest")}</a>
                            </div>
                        </div> */}
        </div>
      </div>
    )
  }
}
