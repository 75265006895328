/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import i18next from 'i18next';
import Sprite from '../Assets/Icons/sprite.svg'

export default class UserDetailCardMobil extends Component {


  render() {
    const { active1, active2, active3, active4, active5, active6 } = this.props;
    const title = active1 ?
      <p className="d-inline">
        <svg className="iconSize-xxxs" >
          <use href={Sprite + "#profile-icon"} ></use>
        </svg> <a href="#" className="p-size-3">{i18next.t('MyProfile')} </a>
      </p>
      :
      active2 ?
        <p className="d-inline">
          <svg className="iconSize-xxxs" >
            <use href={Sprite + "#truck"} ></use>
          </svg ><a href="#" className="p-size-3"> {i18next.t('MyOrders')}</a>
        </p>
        :
        active3 ?
          <p className="ProfilPopever__like-button d-inline">
            <svg className="iconSize-xxxs" >
              <use href={Sprite + "#like-button"} ></use>
            </svg ><a href="#" className="p-size-3">{i18next.t('MyFavorites')}</a>
          </p>
          :
          active4 ?
            <p className="d-inline">
              <svg className="iconSize-xxxs" >
                <use href={Sprite + "#map-pin"} ></use>
              </svg ><a href="#" className="p-size-3">{i18next.t('MyAdresses')}</a>
            </p>
            :
            // active5 ?
            //     <p className="d-inline">
            //         <svg className="iconSize-xxxs" >
            //             <use href={Sprite + "#credit-card"} ></use>
            //         </svg ><a href="#" className="p-size-3"> {i18next.t('MyCreditCards')}</a>
            //     </p>
            //     :
            active6 &&
            <p className="d-inline">
              <svg className="iconSize-xxxs" >
                <use href={Sprite + "#gift"} ></use>
              </svg ><a href="#" className="p-size-3">{i18next.t('MyGifts')}</a>
            </p>

    return (
      <div>
        <DropdownButton id="dropdown-item-button" title={title} className="UserDetailCardMobil">
          <Dropdown.Item active={active1} as="button">
            <p className="">
              <svg className="iconSize-xxxs" >
                <use href={Sprite + "#profile-icon"} ></use>
              </svg> <a href={`/${localStorage.getItem("lang")}/profilim`} className="p-size-3">{i18next.t('MyProfile')} </a>
            </p>
          </Dropdown.Item>
          <Dropdown.Item active={active2} as="button">
            <p>
              <svg className="iconSize-xxxs" >
                <use href={Sprite + "#truck"} ></use>
              </svg ><a href={`/${localStorage.getItem("lang")}/siparislerim`} className="p-size-3"> {i18next.t('MyOrders')}</a>
            </p>
          </Dropdown.Item>
          <Dropdown.Item active={active3} as="button">
            <p className="ProfilPopever__like-button">
              <svg className="iconSize-xxxs" >
                <use href={Sprite + "#like-button"} ></use>
              </svg ><a href={`/${localStorage.getItem("lang")}/favorilerim`} className="p-size-3">{i18next.t('MyFavorites')}</a>
            </p>
          </Dropdown.Item>
          <Dropdown.Item active={active4} as="button">
            <p>
              <svg className="iconSize-xxxs" >
                <use href={Sprite + "#map-pin"} ></use>
              </svg ><a href={`/${localStorage.getItem("lang")}/adreslerim`} className="p-size-3">{i18next.t('MyAdresses')}</a>
            </p>
          </Dropdown.Item>
          {/* <Dropdown.Item active={active5} as="button">
                        <p>
                            <svg className="iconSize-xxxs" >
                                <use href={Sprite + "#credit-card"} ></use>
                            </svg ><a href="/kayitlikartlarim" className="p-size-3"> {i18next.t('MyCreditCards')}</a>
                        </p>
                    </Dropdown.Item> */}
          {/* <Dropdown.Item active={active6} as="button">
                        <p>
                            <svg className="iconSize-xxxs" >
                                <use href={Sprite + "#gift"} ></use>
                            </svg ><a href="#" className="p-size-3">{i18next.t('MyGifts')}</a>
                        </p>
                    </Dropdown.Item> */}
          <Dropdown.Item as="button">
            <p className="ProfilPopever__logout">
              <svg className="iconSize-xxxs" >
                <use href={Sprite + "#log-out"} ></use>
              </svg ><a href="#" onClick={this.onPressLogOut} className="p-size-3"> {i18next.t('LogOut')}</a>
            </p>
          </Dropdown.Item>
        </DropdownButton>
      </div>
    )
  }
}
