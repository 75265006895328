/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import ProfilDetailCard from '../Components/ProfilDetailCard';
import BreadCrumb from '../Components/BreadCrumb';
import AddressCard from '../Components/AddressCard';
import UserDetailCardMobil from '../Components/UserDetailCardMobil';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Sprite from '../Assets/Icons/sprite.svg';
import i18next from 'i18next';
import Form from 'react-bootstrap/Form';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import InputMask from 'react-input-mask';
import ExceptionTranslate from '../lib/ExceptionTranslate'
import Select from 'react-select';


const KayıtlıAdreslerim = observer(class KayıtlıAdreslerim extends Component {
  constructor(props) {
    super(props);
    this.state = {

      showAddresEditModal: false,
      showSuccesModal: false,
      showErrorModal: false,



      selectedId: 0,
      AddressList: [],
      AddressTitle: "",
      NameSurname: "",
      SelectedCityId: 0,
      SelectedDistrictId: 0,
      Description: "",
      Phone: "",
      PostalCode: "",
      CityList: [],
      DistrictList: [],
      errorMessage: "",
      AddressId: 0,
      SuccessMessage: "",
    };


  }

  componentDidMount() {
    this.GetAddresses();
    this.getCityList();

  }

  getCityList = () => {
    this.setState({ CityLoading: true });
    ApiBase.Post({
      url: '/get-city-list',
      data: {
        CountryId: 223,
      },
      successFunction: (e) => {
        //   console.log("şehirler", e.data.data)
        this.setState({ CityList: e.data.data.cities, CityLoading: false });

      },
      errorFunction: (e) => {
        this.setState({ CityLoading: false })
        // console.log(e.data.message)
      }
    })
  };

  getDistrictList = () => {
    this.setState({ DistrictLoading: true });
    ApiBase.Post({
      url: '/get-district-list',
      data: {
        CityId: this.state.SelectedCityId,
      },
      successFunction: (e) => {
        //  console.log("ilçeler", e.data.data)
        this.setState({ DistrictList: e.data.data.districts, DistrictLoading: false });

      },
      errorFunction: (e) => {
        this.setState({ DistrictLoading: false })
        // console.log(e.data.message)
      }
    })
  };



  onChangeCity = (e) => {
    this.setState({ SelectedCityId: e.value }, () => {
      this.getDistrictList();
    })
  };

  onChangeDistrict = (e) => {
    this.setState({ SelectedDistrictId: e.value });

  };
  addAdress = () => {
    this.setState({ actLoading: true });
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);


    ApiBase.Post({
      url: '/add-address',
      data: {
        Title: this.state.AddressTitle,
        NameSurname: this.state.NameSurname,
        City: this.state.SelectedCityId,
        District: this.state.SelectedDistrictId,
        Description: this.state.Description,
        Phone: this.state.Phone,
        CurrentUser: cUser,
        Zip: this.state.PostalCode

      },
      successFunction: (e) => {
        // console.log(e.data)
        this.setState({ showAddresEditModal: false, showSuccesModal: true, SuccessMessage: e.data.message })
        // this.setState({ loading: false })
        this.GetAddresses();
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }


  GetAddresses = () => {
    this.setState({ actLoading: true });
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);


    ApiBase.Post({
      url: '/get-addresses',
      data: {
        CurrenUser: cUser
      },
      successFunction: (e) => {
        // console.log(e.data.data.AddressList)
        this.setState({ AddressList: e.data.data.AddressList })
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        // this.setState({ showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }

  updateAddress = () => {
    // console.log(this.state.AddressId)
    this.setState({ actLoading: true, updateAddress: true });
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    ApiBase.Post({
      url: '/update-address',
      data: {
        Title: this.state.AddressTitle,
        NameSurname: this.state.NameSurname,
        City: this.state.SelectedCityId,
        District: this.state.SelectedDistrictId,
        Description: this.state.Description,
        Phone: this.state.Phone,
        CurrentUser: cUser,
        Zip: this.state.PostalCode,
        Id: this.state.AddressId

      },
      successFunction: (e) => {
        // console.log(e.data)
        this.setState({ showAddresEditModal: false, showSuccesModal: true, updateAddress: false, SuccessMessage: e.data.message })
        // this.setState({ loading: false })
        this.GetAddresses();
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }


  removeAddress = (Id) => {

    this.setState({ actLoading: true });
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    ApiBase.Post({
      url: '/remove-address',
      data: {
        CurrentUser: cUser,
        Id: Id
      },
      successFunction: (e) => {
        // console.log(e.data)
        this.setState({ showSuccesModal: true, SuccessMessage: e.data.message })
        // this.setState({ loading: false })
        this.GetAddresses();
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }

  selectCheckBox = (id) => {
    this.setState({ selectedId: id })
  }
  hideLoginModal = () => {
    this.setState({ showAddresEditModal: false, showSuccesModal: false, showErrorModal: false })
  }
  showSuccesModal = () => {
    this.setState({ showSuccesModal: true, showAddresEditModal: false })

  }
  showAddresEditModal = () => {
    this.setState({
      showAddresEditModal: true,
      updateAddress: false,
      AddressTitle: "",
      NameSurname: "",
      SelectedCityId: 0,
      SelectedDistrictId: 0,
      Description: "",
      Phone: "",
      PostalCode: "",

    })
  }
  onClickEditAddress = (id) => {
    this.setState({
      updateAddress: true,
      showAddresEditModal: true,
      AddressTitle: this.state.AddressList[id].AddressName,
      NameSurname: this.state.AddressList[id].NameSurname,
      SelectedCityId: this.state.AddressList[id].Location.CityId,
      SelectedDistrictId: this.state.AddressList[id].Location.DistrictId,
      Description: this.state.AddressList[id].AddressDescription,
      Phone: this.state.AddressList[id].PhoneStr,
      PostalCode: this.state.AddressList[id].ZipCode,
      AddressId: this.state.AddressList[id].AdressId,
    }, () => {
      // console.log(this.state.AddressList[0].Location.AddressName)
      this.getCityList();
      this.getDistrictList();
    })
  }
  render() {
    const { history } = this.props;
    const customStyles = {
      option: (provided, state) => ({
        ...provided,

        color: 'black',
        padding: 5,
        fontSize: 16,
        marginLeft: 12,
        marginRight: 12,
        backgroundColor: 'transparent',
        fontFamily: ''
      }),
      control: (provided) => ({
        ...provided,
        marginTop: "5%",
        borderWidth: 0,

        backgroundColor: "transparent",
        borderRadius: 0,
        outline: 'none',
        margin: 0,
        boxShadow: 0,
        ":hover": { borderColor: '#808080' }
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        color: '#000',
        borderLeft: 0
      }),
      placeholder: (provided) => ({
        ...provided,
        color: '#CEC2A6',
        fontFamily: 'Basis Grotesque Pro'
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        fontSize: 16,
        color: 'black'
      }),
      singleValue: (provided) => ({
        ...provided,
        // backgroundColor: 'blue',
        color: 'black',
        fontSize: 16,
        fontFamily: 'Basis Grotesque Pro'
      }),
      valueContainer: (provided) => ({
        ...provided,
        flexWrap: 'no-wrap',

      }),
      menuList: (provided) => ({
        ...provided,
        overflowX: 'hidden',
        overflowY: 'auto',

      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 12,


      })
    }
    const address = this.state.AddressList.map((item, index) => {
      return (
        <div className="col-lg-6 col-md-12 ">
          {/* <div className="input-group-prepend ">
              <div className="input-group-text border-0 bg-transparent">
              </div>
            </div> */}
          <div className="kayitliadresler"> <AddressCard deleteAddress={() => { this.removeAddress(item.AdressId) }} editAddress={() => { this.onClickEditAddress(index) }} myAddress item={item} /> </div>
        </div>
      )
    });
    const cityOpts = [];
    const cityOptions = this.state.CityList.map((item) => {
      return (
        cityOpts.push({ value: item.CityId, label: item.City })
      )
    })
    const distOpts = [];
    const districtOptions = this.state.DistrictList.map((item) => {
      return (
        distOpts.push({ value: item.DistrictId, label: item.District })
      )
    });
    return (
      <>
      <div className="container">
      <BreadCrumb hesabim path={i18next.t("MyAdresses")}></BreadCrumb>
      </div>
        <div className="KayitliAdreslerim" >
          <div className="container">
            <div className="row  ">
              <div className="col-lg-3 d-none d-lg-block UserDetail__section1">
                <ProfilDetailCard className4={"bg--secondary"}></ProfilDetailCard>
              </div>
              <div className="col-lg-3  d-lg-none UserDetail__section1">
                <UserDetailCardMobil active4></UserDetailCardMobil>
              </div>
              <div className="col-lg-9  UserDetail__section2">
              <div className="divProfileDetailTitle divGilroy font-weight-bold">{i18next.t('MyAdresses')}</div>
                <div className="row mb-3">
                  <div className="col-lg-4  text-center  mr-auto">
                    <a onClick={this.showAddresEditModal} className="submit-button2 border-0 text-left  shadow-none"> +
                                    <span className="p-size-2"> {i18next.t('AddNewAddress')}</span>
                    </a>
                  </div>
                </div>
                <div className="row">
                  {address}
                </div>
              </div>
            </div>
            <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showAddresEditModal} onHide={this.hideLoginModal}>
              <Modal.Header closeButton>
              </Modal.Header>
              <h4 className="LoginModal__title text-center">{i18next.t('NEWADDRESS')}</h4>
              <Modal.Body>
                <Form className="iletisim iletisim--pd">
                  <div className="divFormSubTitle">{i18next.t('AddressTitle')}</div>
                  <input type="text" className="form-control divFromInput" id="addresstitle" required value={this.state.AddressTitle} onChange={(e) => this.setState({ AddressTitle: e.target.value })} />
                  <div className="divFormSubTitle">{i18next.t("NameSurname")}</div>
                  <input type="text" className="form-control divFromInput" id="name" required value={this.state.NameSurname} onChange={(e) => this.setState({ NameSurname: e.target.value })} />
                  <div className="divFormSubTitle">Şehir</div>
                  <Form.Group >
                    <Select
                      placeholder={this.state.SelectedCityId > 0 ? "" : "Şehir Seçiniz"}
                      options={cityOpts}
                      styles={customStyles}
                      className="form-control divFromInput"
                      onChange={this.onChangeCity}
                      value={cityOpts.find(op => { return op.value == this.state.SelectedCityId })}
                    >
                    </Select>
                  </Form.Group>
                  <div className="divFormSubTitle">İlçe</div>
                  <Form.Group >
                    <Select
                      placeholder={this.state.SelectedDistrictId > 0 ? "" : "İlçe Seçiniz"}
                      options={distOpts}
                      className="form-control divFromInput"
                      styles={customStyles}
                      onChange={this.onChangeDistrict}
                      value={distOpts.find(op => { return op.value == this.state.SelectedDistrictId })}
                    >
                    </Select>
                  </Form.Group>
                  <div className="divFormSubTitle">{i18next.t("FullAddress")}</div>
                  {/* <textarea className="w-100 form-control divFromInput" id="messageBoxForIletisim" rows="1" required value={this.state.Description} onChange={(e) => this.setState({ Description: e.target.value })}></textarea> */}
                  <textarea className="w-100 form-control divFromInput" rows="1" required value={this.state.Description} onChange={(e) => this.setState({ Description: e.target.value })}></textarea>
                  <div className="divFormSubTitle">{i18next.t("PhoneNumber")}</div>
                  <InputMask mask="(999) 999 99 99"
                    className="c-input form-control divFromInput"
                    maskChar={null}
                    placeholder
                    type="tel"
                    id="telno"
                    value={this.state.Phone}
                    onChange={(e) => { this.setState({ Phone: e.target.value }) }}
                    required>
                  </InputMask>
                  <div className="divFormSubTitle">{i18next.t("PostalCode")}</div>
                  <input type="text" className="form-control divFromInput" id="postalcode" required value={this.state.PostalCode} onChange={(e) => this.setState({ PostalCode: e.target.value })} />
                  <div className="col-lg-7 text-center  mx-auto">
                    {this.state.updateAddress === true ?
                      <a onClick={this.updateAddress} className="submit-button font-weight-bold"> {i18next.t("UPDATE")}
                      </a>
                      :
                      <a onClick={this.addAdress} className="submit-button font-weight-bold"> {i18next.t("SAVE")}
                      </a>
                    }
                  </div>
                  <div className="col-lg-7  text-center  mx-auto">
                    <a onClick={this.hideLoginModal} className="submit-button2 border-0 shadow-none "> {i18next.t("CANCEL")}
                    </a>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
            {/* Succes Modal */}
            <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccesModal} onHide={this.hideLoginModal}>
              <Modal.Header closeButton>
              </Modal.Header>
              <h4 className="LoginModal__title text-center">BAŞARILI</h4>
              <p></p>
              <Modal.Body>
                <div className="text-center mb-3">
                  <svg className="iconSize-md" >
                    <use href={Sprite + "#smile"} ></use>
                  </svg >
                </div>
                <form className="LoginModal  ">
                  <p className="text-center">{this.state.SuccessMessage}</p>
                  <div className="text-center  w-50  mx-auto">
                    <a onClick={() => { this.setState({ showSuccesModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
            {/* Error Modal  */}
            <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={this.hideLoginModal}>
              <Modal.Header closeButton>
              </Modal.Header>
              <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
              <p></p>
              <Modal.Body>
                <div className="text-center mb-3">
                  <svg className="iconSize-md" >
                    <use href={Sprite + "#sad-face"} ></use>
                  </svg >
                </div>
                <form className="LoginModal  ">
                  <p className="text-center">{ExceptionTranslate(this.state.errorMessage)}</p>
                  <div className="text-center  w-50  mx-auto">
                    <a onClick={() => { this.setState({ showErrorModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </div >
      </>
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(KayıtlıAdreslerim);
