/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import i18next from 'i18next';



export default class OrderSummary extends Component {
  render() {
    const { item, sozlesme, complete, CargoPriceStr, TotalPriceStr, loading, TotalProductPriceStr, } = this.props;
    const lang = localStorage.getItem("lang")
    return (
      <div className="OrderSummary ">
        <h5 className="OrderSummary__title">{i18next.t("OrderSummary")}</h5>
        <div className="d-flex justify-content-between">
          <p className="p-size-3">{i18next.t("TotalPrice")}:</p>
          <p className="p-size-3">{item.TotalProductPriceStr} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="p-size-3">{i18next.t("Cargo")}:</p>
          <p className="p-size-3">{item.CargoPriceStr} {localStorage.getItem("lang") === "tr" ? "TL" : "$"} </p>
        </div>
        <div className="d-flex justify-content-between GeneralTotalPrice">
          <p className=" p-size-3 p-size--b">{i18next.t("GrandTotal")}:</p>
          <p className=" p-size-3 p-size--b">{item.TotalPriceStr} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</p>
        </div>
        {sozlesme &&
          <div className=" mt-3 ">
            <div className="form-check">
              <input type="checkbox" class="form-check-input" id="sozlesme" onClick={this.props.checkedSozlesme} />
              <label class="form-check-label" htmlFor="sozlesme">
                <span className="p-size-2 p-size--color divGilroy"><a href={`/${localStorage.getItem("lang")}/sozlesmeler`} target="_blank" className="sozlesmelink" > {i18next.t("ContractText")}</a>{lang === "en" ? <br></br> : <></>}{i18next.t("ContractText2")}</span>
              </label>
            </div>

          </div>}

        <a onClick={this.props.onClickOdemeyeGec} className={loading ? "submit-button loading" : "submit-button"}> {complete ? i18next.t("CompletePay") : i18next.t("GoToPay")} </a>

        <a href="searchproducts" className="OrderSummary__btn OrderSummary__btn--btn2 h-size-2" >{i18next.t("KeepShopping")}</a>

      </div>

    )
  }
}
