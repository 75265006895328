import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import SozlesmeCard from '../Components/SozlesmeCard';
import SozlesmeCardMobil from '../Components/SozlesmeCardMobil';
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';


export default class Sozlesmeler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ContractList: [],
      SelectedContractId: -1
    };
  }

  componentDidMount() {
    this.getContracts();
  }

  getContracts = () => {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-contracts',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        this.setState({
          ContractList: e.data.data.contractList,
        }, function () {
          if (this.props.match.params.id !== undefined && this.props.match.params.id != null && this.state.ContractList.find(x => x.Id == this.props.match.params.id) != undefined) {
            this.setState({ SelectedContractId: this.props.match.params.id });
          }
          else {
            if (this.state.ContractList.length > 0) {
              this.setState({ SelectedContractId: this.state.ContractList[0].Id ?? 0 });
            }
          }
        });
      },
      errorFunction: (e) => {

      }
    })
  }

  render() {
    return (
      <div className="sozlesme body--margin">
        <div className="container-fluid">
          <div className="container">
            <BreadCrumb path={i18next.t("Contracts")}></BreadCrumb>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-3  d-none d-lg-block ">
              <div className="ProfilDetailCard SozlesmeCard">
                <div className="ProfilPopever__body">
                  {
                    this.state.ContractList.map((item) => (
                      <p>
                        <div className={`${this.state.SelectedContractId == item.Id ? "text-red-color" : ""} cursor-pointer`} onClick={() => { this.setState({ SelectedContractId: item.Id }); }}> {item.Title}</div>
                      </p>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-3  d-lg-none UserDetail__section1">
              <div className="ProfilDetailCard SozlesmeCard">
                <div className="ProfilPopever__body">
                  {
                    this.state.ContractList.map((item) => (
                      <p>
                        <div className={`${this.state.SelectedContractId == item.Id ? "text-red-color" : ""} cursor-pointer`} onClick={() => { this.setState({ SelectedContractId: item.Id }); }}> {item.Title}</div>
                      </p>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              {
                this.state.ContractList.map((item) => (
                  <>
                    {
                      this.state.SelectedContractId == item.Id &&
                      <>
                        <h4 className="h-size-4 mt-3">{item.Title}</h4>
                        <p className="p-size-1 mt-4" dangerouslySetInnerHTML={{ __html: item.Description }}></p>
                      </>
                    }
                  </>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
