/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import BreadCrumb from '../Components/BreadCrumb';
import ProductCard from '../Components/ProductCard';
import Select, { components } from "react-select";
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputRange from 'react-input-range';
import i18next from 'i18next';
import Sprite from '../Assets/Icons/sprite.svg';
import ApiBase from '../lib/ApiBase';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import ExceptionTranslate from '../lib/ExceptionTranslate'
import Pager from 'react-pager';
import { inject, observer } from 'mobx-react';
const ShopPageSearch = observer(class ShopPageSearch extends Component {
  constructor(props) {
    super(props);

    this.handlePageChanged = this.handlePageChanged.bind(this);

    this.state = {
      favoriteList: [],
      isFavorited: -1,

      checkedCategoryList: [], //chekcbox a seçilenleri göstermek için
      selectedCategoryOptions: [],//listeye eklenenler select

      checkedDesignerList: [],
      selectedDesignerOptions: [],
      filterList: [
        {
          Id: 0,
          FilterName: i18next.t("Newest")
        },
        {
          Id: 3,
          FilterName: i18next.t("MostPopular")
        },

        {
          Id: 2,
          FilterName: i18next.t("HighestPrice")
        },
        {
          Id: 1,
          FilterName: i18next.t("LowestPrice")
        },
      ],
      selectedFilterItem: 0,
      priceValue: {
        min: 0,
        max: 10000,
      },
      fiyatRange: true,
      width: 0,
      mobileFilreler: false,
      mobileKategoriler: false,
      mobileFiyatRange: false,
      mobileTasarimcilar: false,
      ProductList: [],
      CategoryList: [],
      PostedFilterCategory: [],
      PostedFilterDesigner: [],
      DesignerList: [],
      SelectedChildCategoryId: 0,
      SelectedCategoryId: 0,
      SelectedCategoryName: "",
      SelectedCategoryDescription: "",
      TotalCount: 0,
      showErrorModal: false,
      favoriteIds: [],
      errorMessage: "",
      ChildCategoryList: [],
      ChilCategoryChildList: [],
      mobileCategorySelected: false,
      SearchList: [],
      TotalCount: 0,
      IsContinue: false,

      total: 0,
      current: 0,
      visiblePage: 3,

      ingredients: [],
      ingedientsList: [],
      checkedList: [],
      isChecked: false,

      isOnlyDiscounted: this.props.match.params.onlydiscounted,
      isOnlyFreeCargo: false,
      isOnlyStock: false,
    };
    this.multiselectRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth });
    this.getSearch();
    this.getCategories();
    this.getDesigners();
    this.getFavoriteIds();

  }
  getSearch = () => {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-search-results',
      data: {
        searchText: this.props.match.params.searchtext ? this.props.match.params.searchtext : "",
        IsMustHave: this.props.match.params.ismusthave,
        IsFeatured: this.props.match.params.isfeatured,
        CategoryId: this.state.SelectedChildCategoryId > 0 ? this.state.SelectedChildCategoryId : this.state.SelectedCategoryId,
        LanguageId: LangId,
        OrderBy: this.state.selectedFilterItem,
        MinPrice: this.state.priceValue.min,
        MaxPrice: this.state.priceValue.max === 10000 ? null : this.state.priceValue.max,
        designerListString: this.state.PostedFilterDesigner,
        Take: 24,
        Skip: this.state.current * 24,
        IsOnlyDiscounted: this.state.isOnlyDiscounted,
        IsOnlyStock: this.state.isOnlyStock,
        IsOnlyFreeCargo: this.state.isOnlyFreeCargo,
        CountryId: LangId
      },
      successFunction: (e) => {
        this.setState({
          SearchList: e.data.data.List,
          TotalCount: e.data.data.List.length > 0 && e.data.data.List[0].TotalCount,
          mobileFilreler: false,
          IsContinue: e.data.data.Continue,
          loading: false,
          total: e.data.data.List.length > 0 ? Math.ceil(e.data.data.List[0].TotalCount / 24) : 0,
        })
      },
      errorFunction: (e) => {
      }
    })
  }
  handlePageChanged(newPage) {
    this.setState({ current: newPage }, function () {
      this.getSearch();
    });
  }
  loadMore = () => {
    if (this.state.IsContinue === true) {
      var Lang = localStorage.getItem("lang")
      var LangId = Lang === "tr" ? 1 : 2;
      ApiBase.Post({
        url: '/get-search-results',
        data: {
          searchText: this.props.match.params.searchtext,
          LanguageId: LangId,
          OrderBy: this.state.selectedFilterItem,
          MinPrice: this.state.priceValue.min,
          MaxPrice: this.state.priceValue.max === 10000 ? null : this.state.priceValue.max,
          designerListString: this.state.PostedFilterDesigner,
          Take: 24,
          Skip: this.state.SearchList.length
        },
        successFunction: (e) => {
          this.setState({
            SearchList: [...this.state.SearchList, ...e.data.data.List],
            TotalCount: e.data.data.List.length > 0 && e.data.data.List[0].TotalCount,
            IsContinue: e.data.data.Continue
          })
        },
        errorFunction: (e) => {
        }
      })
    }
  }
  ////////
  getCategories = () => {
    this.setState({ loadingBlogDetail: true });
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-categories',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        // console.log(e.data.data)
        this.setState({
          CategoryList: e.data.data.categories
        });
      },
      errorFunction: (e) => {

        // console.log(e.data.message)
      }
    })
  };
  //////
  getDesigners = () => {
    this.setState({ loadingBlogDetail: true });
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-filter-designers',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        //  console.log("designers", e.data.data)
        this.setState({
          DesignerList: e.data.data.designers
        });
      },
      errorFunction: (e) => {

        // console.log(e.data.message)
      }
    })
  };

  /////
  toggleFavorite = (item) => {
    const i = this.state.favoriteList.indexOf(item.Id)

    if (i >= 0) {
      this.state.favoriteList.splice(i, 1)
      // console.log("i 0 dan büyük",this.state.favoriteList)
    } else {
      this.state.favoriteList.push(item.Id)
      //  console.log("i 0 dan küçük", this.state.favoriteList)
    }
    this.setState({ favoriteList: this.state.favoriteList });
    // // console.log(this.state.favoriteList)
    // console.log("iiii", i)
  };
  // resetValues() {
  //   // By calling the belowe method will reset the selected values programatically
  //   this.multiselectRef.current.resetSelectedValues();
  // }
  // onSelect(item) {

  //   this.setState({ selectedCategoryValues: item }, () => {
  //     console.log(this.state.selectedCategoryValues)
  //   })

  // }
  // getValues() {
  //   this.multiselectRef.current.getSelectedItems();

  // }

  // handleChange = (event) => {
  //   this.setState({
  //     selectedCategoryValues: event.target.value
  //   }, () => {
  //     console.log(this.state.selectedCategoryValues)
  //   })

  // }
  handleChange = (selectedCategoryOptions, item) => {
    this.setState({ selectedCategoryOptions }, () => {
      //   console.log(this.state.selectedCategoryOptions)
    });
  }

  mobileFiltreUygula = () => {
    this.setState({ mobileFilreler: false })
    this.getSearch();
    this.getChildCategories();
  }

  handleChangeCategories = (item) => {
    this.setState({ SelectedChildCategoryId: 0, SelectedCategoryId: item.value, SelectedCategoryName: item.label, SelectedCategoryDescription: item.description }, () => {
      // console.log(this.state.SelectedCategoryName, this.state.SelectedCategoryDescription)

      this.getChildCategories();
    })
  }

  handleChangeCategoriesMobile = (item) => {
    this.setState({ SelectedChildCategoryId: 0, SelectedCategoryId: item.Id, SelectedCategoryName: item.Name, SelectedCategoryDescription: item.Description, mobileKategoriler: false, mobileCategorySelected: true })
  }

  onClickCheckCategory = (value, label) => {

    const i = this.state.checkedCategoryList.filter(x => x.id === value);

    const FilterCategory = [];
    if (i.length > 0) {
      var temp = this.state.checkedCategoryList.filter(x => x.id !== value);
      this.setState({ checkedCategoryList: temp }, () => {
        // console.log(this.state.checkedCategoryList);
        this.state.checkedCategoryList.map(item => {
          FilterCategory.push(item.id);
          this.setState({ PostedFilterCategory: FilterCategory })
        })
      });
      // this.state.checkedCategoryList.splice(i, 1)
    } else {
      this.state.checkedCategoryList.push({ id: value, name: label });
      this.setState({ checkedCategoryList: this.state.checkedCategoryList }, () => {

        this.state.checkedCategoryList.map(item => {
          FilterCategory.push(item.id);
          this.setState({ PostedFilterCategory: FilterCategory })
        })
        //console.log("aa", this.state.checkedCategoryList);
      });
    }


  }

  handleChangeDesigner = (selectedDesignerOptions) => {
    this.setState({ selectedDesignerOptions }, () => {
      // console.log(this.state.selectedDesignerOptions)
    });
  }

  onClickCheckDesigner = (value, label) => {
    // console.log("bastm")
    //  console.log(this.state.checkedDesignerList)
    const i = this.state.checkedDesignerList.filter(x => x.id == value)
    const FilterDesigner = [];
    if (i.length > 0) {
      var temp = this.state.checkedDesignerList.filter(x => x.id != value);
      this.setState({ checkedDesignerList: temp }, () => {
        this.state.checkedDesignerList.map(item => {
          FilterDesigner.push(item.id);
          this.setState({ PostedFilterDesigner: FilterDesigner })
        })
        // console.log(this.state.checkedDesignerList)
      })
    } else {
      this.state.checkedDesignerList.push({ id: value, name: label });
      this.setState({ checkedDesignerList: this.state.checkedDesignerList }, () => {
        this.state.checkedDesignerList.map(item => {
          FilterDesigner.push(item.id);
          this.setState({ PostedFilterDesigner: FilterDesigner })
        })
        //   console.log(this.state.checkedDesignerList)
      })
    }



  }

  handleChangeFilter = (item) => {
    this.setState({ selectedFilterItem: item.value }, () => {
      //  console.log(this.state.selectedFilterItem)
      this.getSearch();
    });

  }

  _onClickMobileFiltreler = () => {
    this.setState({ mobileFilreler: true })
  }
  _mobileKateogoriler = () => {
    this.setState({ mobileKategoriler: true })
  }
  _mobileTasarimcilar = () => {
    this.setState({ mobileTasarimcilar: true })
  }
  _mobileFiyatRange = () => {
    this.setState({ mobileFiyatRange: true })
  }

  onOnlyDiscountedProduct = () => {
    this.setState({ isOnlyDiscounted: !this.state.isOnlyDiscounted });
  }
  onOnlyStockProduct = () => {
    this.setState({ isOnlyStock: !this.state.isOnlyStock });
  }
  onOnlyFreeCargoProduct = () => {
    this.setState({ isOnlyFreeCargo: !this.state.isOnlyFreeCargo });
  }
  onClickChildItem = (item) => {
    this.setState({ SelectedChildCategoryId: item.Id, selectedParentCategoryId: item.ParentId }, () => {
      this.getSearch();
      item.Children.length > 0 && this.getChildCategories();
    })
  }
  getChildCategories = () => {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-child-categories',
      data: {
        LanguageId: LangId,
        CategoryId: this.state.SelectedChildCategoryId > 0 ? this.state.SelectedChildCategoryId : this.state.SelectedCategoryId

        // CategoryId:this.state.PostedFilterCategory
        //  DesignerId:this.state.PostedFilterDesigner
      },
      successFunction: (e) => {

        // console.log("categories",e.data.data.product.Categories[0].NameTr)
        // console.log("catData", e.data.data.catData)
        this.setState({
          ChildCategoryList: e.data.data.catData,
          ChilCategoryChildList: e.data.data.catData.Children
          //    ProductCategoryName:e.data.data.product.Cattegories[0].NameTr
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
      }
    })
  }
  ///favoriekle
  AddFavorite = (ProductId) => {
    this.setState({ addFavoriteLoading: true })
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }
    ApiBase.Post({
      url: '/add-favourite',
      data: {
        ProductId: ProductId,
        CurrentUser: cUser
      },
      successFunction: (e) => {
        // console.log("favoriekle", e.data)
        this.getFavoriteIds();
        this.setState({ addFavoriteLoading: false })
        // this.toggleFavorite(item)
      },
      errorFunction: (e) => {
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message })
        // console.log(e.data.message)

      }
    })
  }
  removeFavorites = (ProductId) => {
    this.setState({ addFavoriteLoading: true });
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }


    ApiBase.Post({
      url: '/delete-favourite',
      data: {
        ProductId: ProductId,
        CurrenUser: cUser
      },
      successFunction: (e) => {
        // console.log("remove", e.data)

        this.getFavoriteIds();
        this.setState({ addFavoriteLoading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }
  getFavoriteIds = () => {
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var favIdList = [];
    ApiBase.Post({
      url: '/get-favorite-ids',
      data: {

        UserId: _UserId,

      },
      successFunction: (e) => {
        // console.log("favoriteıds", e.data.data.List)
        e.data.data.List.map(item =>
          favIdList.push(item.ProductId));
        this.setState({ favoriteIds: favIdList })
        //this.setState({ CartList: e.data.data.List, CartTotalPriceStr: e.data.data.TotalPriceStr })
      },
      errorFunction: (e) => {

        // console.log("hata", e.data.message)
      }
    })
  }
  clearCategoryFilter = () => {
    this.setState({ loading: true });
    const PostedFilterCategory = [];
    const _categoryListString = [];

    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-category-products',
      data: {
        LanguageId: LangId,
        CategoryId: this.state.SelectedCategoryId,
        OrderBy: this.state.selectedFilterItem,
        MinPrice: this.state.priceValue.min,
        MaxPrice: this.state.priceValue.max === 10000 ? null : this.state.priceValue.max,
        designerListString: this.state.PostedFilterDesigner,
        Take: 24,
        Skip: this.state.current * 24,
        IsOnlyDiscounted: this.state.isOnlyDiscounted,
        IsOnlyStock: this.state.isOnlyStock,
        IsOnlyFreeCargo: this.state.isOnlyFreeCargo
      },
      successFunction: (e) => {
        this.setState({
          SearchList: e.data.data.List,
          TotalCount: e.data.data.List.length > 0 && e.data.data.List[0].TotalCount,
          SelectedChildCategoryId: 0,
          mobileFilreler: false,
          IsContinue: e.data.data.Continue,
          loading: false,
          total: e.data.data.List.length > 0 ? Math.ceil(e.data.data.List[0].TotalCount / 24) : 0,
        });
        this.getChildCategories()
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
      }
    })
  }
  handleFilterButtonClick = () => {
    this.getSearch();
    this.getChildCategories();
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,

        color: 'black',
        padding: 5,
        fontSize: 16,
        marginLeft: 12,
        marginRight: 12,
        backgroundColor: 'transparent',
        fontFamily: ''
      }),
      control: (provided) => ({
        ...provided,
        marginTop: "5%",
        borderWidth: 0,
        borderBottomWidth: "1px",
        borderColor: '#EDE8DE',
        backgroundColor: "transparent",
        borderRadius: 0,
        outline: 'none',
        margin: 0,
        boxShadow: 0,
        ":hover": { borderColor: '#808080' }

      }),


      dropdownIndicator: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        color: '#B29F78',
        borderLeft: 0

      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        fontSize: 16,
        color: 'black'
      }),
      singleValue: (provided) => ({
        ...provided,
        // backgroundColor: 'blue',
        color: 'black',
        fontSize: 16,
      }),
      valueContainer: (provided) => ({
        ...provided,
        flexWrap: 'no-wrap',

      }),
      menuList: (provided) => ({
        ...provided,
        overflowX: 'hidden',
        overflowY: 'auto',

      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 12,


      })
    }

    const formatOptionLabel = ({ value, label }) => (
      <div className="CategorySubItem"
        onClick={() => this.onClickCheckCategory(value, label)}
        key={value} style={{ backgroundColor: this.state.checkedDesignerList.filter(x => x.id == value) >= 0 ? "white" : "#FBFBF8" }}>
        <div className="customCheckbox" style={{ backgroundColor: this.state.checkedCategoryList.filter(x => x.id == value) >= 0 ? "white" : "#AA7A56" }}>
        </div>

        <div style={{ opacity: this.state.checkedCategoryList.filter(x => x.id == value) >= 0 ? 0.5 : 1 }} className="CategorySubItemName">{label}</div>
      </div>
    );

    const ProductList = this.state.SearchList.map((item) => {
      return (
        <div key={item.Id} className="col-lg-3 col-6">
          <ProductCard _className={this.state.favoriteIds.indexOf(item.Id) >= 0 ? "product-card__like-button active" : "product-card__like-button"}
            toggleFavorite={() => { this.state.favoriteIds.indexOf(item.Id) >= 0 ? this.removeFavorites(item.Id) : this.AddFavorite(item.Id) }} history={this.props.history} item={item}></ProductCard>
        </div>
      );
    })
    const categoryName = this.props.match.params.categoryname;
    const catOptions = [];
    this.state.CategoryList.map(item => {
      return catOptions.push({ value: item.Id, label: item.Name, description: item.Description });
    });
    const designerOptions = [];
    this.state.DesignerList.map(item => {
      return designerOptions.push({ value: item.Id, label: item.Name })
    });
    const filterOptions = [];
    this.state.filterList.map(item => {
      return filterOptions.push({ value: item.Id, label: item.FilterName });
    });

    const catDescriptionList = catOptions.find(op => {
      return op.value == this.state.SelectedCategoryId
    });
    const ChildrenCat = this.state.CategoryList.filter(x => x.Id == this.state.SelectedCategoryId)
    const ChildrenCategoryList = ChildrenCat.map(item => {
      return (
        item.Children.map(cItem => {
          return (
            <a onClick={() => { this.onClickChildItem(cItem) }} className={cItem.Id == this.state.SelectedChildCategoryId ? "active" : ""} >{cItem.Name}</a>
          )
        })
      )
    })
    const NewChildCategory = this.state.ChilCategoryChildList != undefined && this.state.ChilCategoryChildList.length > 0 && this.state.ChilCategoryChildList.map(chilItem => {
      return (
        <a onClick={() => { this.onClickChildItem(chilItem) }} className={chilItem.Id == this.state.SelectedChildCategoryId ? "active" : ""} >{chilItem.Name}</a>
      )
    })

    return (
      <>
        {this.state.addFavoriteLoading == true &&
          <div className="favoriteoverlay">
          </div>
        }
        <div className="shopPage">
          <div className="container">
            <BreadCrumb title={i18next.t("Shop")} path={this.props.match.params.searchtext} ></BreadCrumb>
            <div className="shopPage__header mt-4">
              <p>{this.props.match.params.searchtext && this.props.match.params.searchText != " " ? this.props.match.params.searchtext + "  " + "araması için bulunan sonuçlar" : ""}</p>
              <div className="row ">
                <div className="col-10 p-0 mx-auto">
                  <p className="text-justify">{catOptions.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="divTopLine">
            <div className="container">
              <div className="row" >
                <div className={`col-lg-3 col-md-12 divFilterOpenButton ${window.innerWidth < 992 ? "d-block" : "d-none"}`} onClick={() => { this.setState({ mobileFilreler: !this.state.mobileFilreler }) }}>
                  {i18next.t('Filter')}
                </div>
                <div className={`col-lg-3 col-md-12 divColFilter ${this.state.mobileFilreler == false && window.innerWidth < 992 ? "d-none" : ""}`}>
                  <div className="row productFilters">
                    <div className="col-lg-12 offset-lg-1 m-2 prdocutFilters__category">
                      <label className=" categoryFilter__labelFont2">{i18next.t('FilterProducts')}</label>
                      <label className=" categoryFilter__labelFont">{i18next.t('ByCategory')}</label>
                      <div className="divMySelectRowContainer" id="ShopPageDisabledArea">
                        {this.state.CategoryList.map((item, i) => {
                          return (
                            <>
                              <div className="divMySelectRow">
                                <input class="form-check-input" type="radio" name="cateofyid" onChange={() => { this.setState({ SelectedCategoryId: item.Id }) }} value="" id="flexCheckDefault" checked={this.state.SelectedCategoryId == item.Id} />
                                <label class="form-check-label" for="flexCheckDefault">
                                  {item.Name}
                                </label>
                              </div>
                            </>)
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="row my-3 productFilters">
                    <div className="col-lg-12 mx-auto offset-lg-2 productFilters__priceRange1 w-100">
                      <label className="categoryFilter__labelFont">{i18next.t("PRICE")}</label>
                      {this.state.fiyatRange === true ?
                        <div className=" myPriceRangeContainer">
                          <InputRange
                            formatLabel={value => `${value} ${localStorage.getItem("lang") === "tr" ? "TL" : "$"}`}
                            maxValue={10000}
                            minValue={0}
                            value={this.state.priceValue}
                            onChange={value => this.setState({ priceValue: value })} />
                          <div className="text-center  mt-4">
                          </div>
                        </div>
                        :
                        <></>}
                    </div>
                  </div>
                  <div className="row my-3 productFilters">
                    <div className="col-lg-12 offset-lg-1 m-2 ">
                      <div className="divMySelectRowContainer">
                        <div className="divMySelectRow">
                          <input class="form-check-input" type="checkbox" checked={this.state.isOnlyDiscounted} id="flexCheckDefault" onChange={() => this.onOnlyDiscountedProduct()} />
                          <label class="form-check-label" for="flexCheckDefault">{i18next.t("OnlyDiscount")}</label>
                        </div>
                        <div className="divMySelectRow">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={() => this.onOnlyStockProduct()} />
                          <label class="form-check-label" for="flexCheckDefault">{i18next.t("OnlyStock")}</label>
                        </div>
                        <div className="divMySelectRow">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={() => this.onOnlyFreeCargoProduct()} />
                          <label class="form-check-label" for="flexCheckDefault">{i18next.t("FreeCargo")}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-3 productFilters">
                    <div className="submit-button" onClick={() => { this.handleFilterButtonClick(); }}>{i18next.t("APPLY")}</div>
                    <a href={`/${localStorage.getItem("lang")}/searchproducts/false/false`} className="submit-button4">{i18next.t("CLEAR")}</a>
                  </div>
                </div>
                <div className="col-lg-9 divFilterLine">
                  {
                    (this.state.SelectedCategoryId > 0 && this.state.ChilCategoryChildList != undefined && this.state.ChilCategoryChildList.length > 0) &&
                    <div className="DesignerList__filterBtns">
                      <a className="active">{this.state.ChildCategoryList.Name}</a>
                      {NewChildCategory}
                      {this.state.SelectedChildCategoryId > 0 && <a onClick={this.clearCategoryFilter} className="active">X</a>}
                    </div>
                  }
                  <div className="w-100">
                    <div className="w-100 d-flex align-items-center">
                      <div className="divMyProgressSpan d-inline-block">
                        {
                          localStorage.getItem("lang") === "tr" ?
                            <>
                              Toplam {this.state.TotalCount ? this.state.TotalCount : 0} ürün bulundu. {this.state.SearchList.length} ürün gösteriliyor.
                            </>
                            :
                            <>
                              Total {this.state.TotalCount ? this.state.TotalCount : 0} products are found. {this.state.SearchList.length} products are in display.
                            </>
                        }
                      </div>
                      <Select
                        placeholder={i18next.t("SortBy")}
                        styles={customStyles}
                        isSearchable={false}
                        className="w-50 d-inline-block ml-auto divFilterSelect"
                        onChange={this.handleChangeFilter}
                        options={filterOptions} >
                      </Select>
                    </div>
                  </div>
                  <div className=" product-cards my-3">
                    <div className="row">
                      {ProductList}
                    </div>
                  </div>
                  <Pager
                    total={this.state.total}
                    current={this.state.current}
                    visiblePages={this.state.visiblePage}
                    titles={{ first: '<<<', last: '>>>' }}
                    className="pagination pull-right"
                    onPageChanged={this.handlePageChanged}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Error Modal  */}
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={this.hideLoginModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center">{ExceptionTranslate(this.state.errorMessage)}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => { this.setState({ showErrorModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(ShopPageSearch);
