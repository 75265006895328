/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import FerualTextSvg from '../Assets/Image/Ferual-text.svg';
import Select from 'react-select';
import i18next from 'i18next';
import Cookies from 'universal-cookie';

export default class LanguageSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryList: [],
      languageOptions: [
        { value: 1, label: 'Türkçe' },
        { value: 2, label: 'English' }
      ],
      loadingForDropdown: true,
      selectedLanguage: '',
      selectedCountry: '',
      url: window.location.href,
      body: document.getElementsByTagName("BODY")[0]
    }
  }

  componentDidMount() {
    const cookies = new Cookies();
    var isAnyCookie = cookies.get('_lp');

    if (isAnyCookie === "true") {
      const _currentLang = localStorage.getItem("lang");

      if (_currentLang === null) {
        window.location.href = `/tr`;
      } else {
        window.location.href = `/${_currentLang}`;
      }
    }
    else {
      this.getCountryList();

      document.body.classList.add('mt-0');

      return () => {
        document.body.classList.add('mt-0');
      };
    }
  }

  getCountryList = async () => {
    this.setState({ loadingForDropdown: true });

    const response = await fetch("https://raw.githubusercontent.com/pryazilim/lib/master/data/location/countries.json", { method: "GET" });
    let json = await response.json();
    let rawData = json.sort((a, b) => { return a.name.localeCompare(b.name, 'tr', { sensitivity: 'base' }) });

    let _countryList = [];
    _countryList.push({ value: 223, label: "Türkiye" });

    rawData.forEach(element => {
      if (element.id !== 223) {
        _countryList.push({ value: element.id, label: element.name });
      }
    });

    this.setState({ countryList: _countryList }, () => {
      const _currentLang = localStorage.getItem("lang");

      if (_currentLang === "tr") {
        const _selectedLang = this.state.languageOptions.filter(x => x.value === 1)[0];

        this.setState({ selectedLanguage: _selectedLang }, () => {
          this.setState({ loadingForDropdown: false });
        });
      } else {
        const _selectedLang = this.state.languageOptions.filter(x => x.value === 2)[0];

        this.setState({ selectedLanguage: _selectedLang }, () => {
          this.setState({ loadingForDropdown: false });
        });
      }
    });
  }

  handleCountry = country => {
    this.setState({ selectedCountry: country }, () => {
      if (this.state.selectedCountry?.value === 223) {
        const _selectedLang = this.state.languageOptions.filter(x => x.value === 1)[0];
        this.setState({ selectedLanguage: _selectedLang });
      } else {
        const _selectedLang = this.state.languageOptions.filter(x => x.value === 2)[0];
        this.setState({ selectedLanguage: _selectedLang });
      }
    });
  }

  redirectToPage = () => {
    var date = new Date();
    var expireDate = new Date(date.setTime(date.getTime() + 1 * 86400000));

    const cookies = new Cookies();

    if (this.state.selectedLanguage?.value === 1) {
      cookies.set('_lp', true, { path: '/', expires: expireDate });
      window.location.href = `/tr`;
    } else {
      cookies.set('_lp', true, { path: '/', expires: expireDate });
      window.location.href = `/en`;
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontSize: 12,
        color: 'black',
        backgroundColor: "transparent",
        '&:hover': {
          backgroundColor: "#DEDEDE"
        },
        '&:focus': {
          backgroundColor: "#DEDEDE"
        },
      }),
      control: (provided, state) => ({
        ...provided,
        marginTop: "0",
        borderWidth: "1px",
        width: "100%",
        borderColor: '#DEDEDE',
        backgroundColor: "white",
        borderRadius: 4,
        height: "100%",
        outline: "transparent",
        fontSize: window.innerWidth < 576 ? "12px" : "12px",
        boxShadow: state.isFocused ? '0 0 0 0.2rem transparent' : '',
        '&:focus': {
          borderColor: "#DEDEDE",
          '&:hover': {
            borderColor: "#DEDEDE"
          },
        },
      }),
      container: (provided) => ({
        ...provided,
        height: "42px",
        '&:focus': {
          borderColor: "#F3F0E9",
        },
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: '#000000',
        borderLeft: 0
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0
      }),
      menu: (provided) => ({
        ...provided,
        overflow: "hidden"
      }),
      placeholder: (provided) => ({
        ...provided,
        "@media only screen and (max-width: 576px)": {
          ...provided["@media only screen and (max-width: 576px)"],
          fontSize: 13,
        },
      })
    }

    const cookiesForHtml = new Cookies();

    const isAnyCookieForHtml = cookiesForHtml.get('_lp');

    return (
      <>
        {isAnyCookieForHtml === "true" ?
          <></>
          :
          <div className="login-bg-image" >
            <div className="login-bg-image-overlay"> </div>
            <div className='container'>
              <div className="input-container">
                <img className="login-ferual-text" src={`${FerualTextSvg}`} alt="Ferual" />
                <div className="">
                  <div className="row align-items-stretch d-flex justify-content-center align-items-center ">
                    <div className="col-12 col-lg-3  mt-lg-0 mt-3">
                      <Select className="text-center"
                        options={this.state.countryList.map((item) => {
                          return item;
                        })}
                        onChange={this.handleCountry}
                        value={this.state.selectedCountry}
                        styles={customStyles}
                        isSearchable={true}
                        isLoading={this.state.loadingForDropdown}
                        isDisabled={this.state.loadingForDropdown}
                        placeholder={"Seçiniz"}
                        noOptionsMessage={() => "Seçenek Yok"}
                      >
                      </Select>
                    </div>
                    <div className="col-12 col-lg-3  mt-lg-0 mt-3">
                      <Select
                        className="text-center"
                        options={this.state.languageOptions.map((item) => {
                          return item;
                        })}
                        value={this.state.selectedLanguage}
                        placeholder={"Seçiniz"}
                        styles={customStyles}
                        isSearchable={false}
                        isLoading={this.state.loadingForDropdown}
                        isDisabled={true}
                        noOptionsMessage={() => "Seçenek Yok"}
                      >
                      </Select>
                    </div>
                    <div className="col-12 col-lg-3 mt-lg-0 mt-3">
                      <button onClick={this.redirectToPage} className="submit-button-register mt-0">{i18next.t('StartShop')}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}
