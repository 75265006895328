import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from './Components/NavBarHeader';
import Footer from './Components/Footer';
import LanguageSelect from './Views/LanguageSelect';
import Home from './Views/Home';
import ShopPage from './Views/ShopPage';
import ProductDetail from './Views/ProductDetail';
import BlogAndMagazine from './Views/BlogAndMagazine';
import BlogDetail from './Views/BlogDetail';
import Profilim from './Views/Profilim';
import Siparislerim from './Views/Siparislerim';
import SiparislerimDetay from './Views/SiparislerimDetay';
import UrunIade from './Views/UrunIade';
import Favorilerim from './Views/Favorilerim';
import Adreslerim from './Views/KayıtlıAdreslerim';
import KayitliKartlarim from './Views/KayitliKartlarim';
import Sepetim from './Views/Sepetim';
import AdresBilgisi from './Views/AdresBilgisi';
import OdemeYontemi from './Views/OdemeYontemi';
import UyeOl from './Views/UyeOl';
import Designers from './Views/Designers';
import Fashion from './Views/Fashion';
import ArtsAndWorkshops from './Views/ArtsAndWorkshops';
import About from './Views/About';
import ApplyDesigner from './Views/ApplyDesigner';
import Career from './Views/Career';
import DesignerList from './Views/DesignerList';
import ActivationLink from './Views/ActivationPage'
import ForgotPasswordChange from './Views/ForgotPasswordChange';
import OdemeSayfasiBasarili from './Views/OdemeSayfasiBasarili';
import OdemeSayfasiBasarisiz from './Views/OdemeSayfasiBasarisiz';
import SiparisOdeme from './Views/SiparisOdeme'
import UyeliksizAlisveris from './Views/UyeliksizAlisveris';
import ArtsAndWorkshopsDetail from './Views/ArtsAndWorkshopsDetail';
import SearchPage from './Views/ShopPageSearch';
import IsBirlikleri from './Views/IsBirlikleri';
import IsBirlikleriDetay from './Views/IsBirlikleriDetay';
import musteriHizmetleri from './Views/MusteriHizmetleri';
import ShippingReturns from './Views/ShippingReturns';
import Membership from './Views/Membership';
import PrivacyPolicy from './Views/PrivacyPolicy';
import TermsConditions from './Views/TermsConditions';
import Sozlesmeler from './Views/Sozlesmeler';
import Faq from './Views/Faq';

export default class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }
  componentDidMount() {

  }

  render() {
    return (
      <>
        <BrowserRouter>
          <Navbar />
          <Switch>
            {/* <Route exact path="/" component={LanguageSelect}></Route>
            <> */}
            <Route exact path="/:lang?" component={Home}></Route>
            <Route exact path="/:lang?/shop/:categoryName/:categoryId" component={ShopPage}></Route>
            <Route exact path="/:lang?/productdetail/:productName/:productId" component={ProductDetail}></Route>
            <Route exact path="/:lang?/blogandmagazine" component={BlogAndMagazine}></Route>
            <Route exact path="/:lang?/blogdetail/:blogName/:blogId" component={BlogDetail}></Route>
            <Route exact path="/:lang?/profilim" component={Profilim}></Route>
            <Route exact path="/:lang?/siparislerim" component={Siparislerim}></Route>
            <Route exact path="/:lang?/siparislerimdetay/:siparisId" component={SiparislerimDetay}></Route>
            <Route exact path="/:lang?/uruniade" component={UrunIade}></Route>
            <Route exact path="/:lang?/favorilerim" component={Favorilerim}></Route>
            <Route exact path="/:lang?/adreslerim" component={Adreslerim}></Route>
            {/* <Route exact path="/kayitlikartlarim" component={KayitliKartlarim}></Route> */}
            <Route exact path="/:lang?/sepetim" component={Sepetim}></Route>
            <Route exact path="/:lang?/adresbilgisi" component={AdresBilgisi}></Route>
            <Route exact path="/:lang?/odemeyontemi" component={OdemeYontemi}></Route>
            <Route exact path="/:lang?/uyeol" component={UyeOl}></Route>
            <Route exact path="/:lang?/designers/:designerName/:designerId" component={Designers}></Route>
            <Route exact path="/:lang?/fashion" component={Fashion}></Route>
            <Route exact path="/:lang?/artsandworkshops" component={ArtsAndWorkshops}></Route>
            <Route exact path="/:lang?/about" component={About}></Route>
            <Route exact path="/:lang?/applydesigner" component={ApplyDesigner}></Route>
            <Route exact path="/:lang?/career" component={Career}></Route>
            <Route exact path="/:lang?/designerlist" component={DesignerList}></Route>
            <Route exact path="/:lang?/aktivasyon/:guid" component={ActivationLink}></Route>
            <Route exact path="/:lang?/sifremiunuttum/:guid" component={ForgotPasswordChange}></Route>
            <Route exact path="/:lang?/odeme-basarili" component={OdemeSayfasiBasarili}></Route>
            <Route exact path="/:lang?/odeme-basarisiz/:guid" component={OdemeSayfasiBasarisiz}></Route>
            <Route exact path="/:lang?/siparisodeme" component={SiparisOdeme}></Route>
            <Route exact path="/:lang?/uyeliksizalisveris" component={UyeliksizAlisveris}></Route>
            <Route exact path="/:lang?/artsandworkshopdetail/:artsName/:artsId" component={ArtsAndWorkshopsDetail}></Route>
            <Route exact path="/:lang?/searchproducts/:ismusthave?/:isfeatured?/:onlydiscounted?/:searchtext?/" component={SearchPage}></Route>
            <Route exact path="/:lang?/isbirlikleri" component={IsBirlikleri}></Route>
            <Route exact path="/:lang?/isbirlikleridetay/:isbirlikleriName/:isbirlikleriId" component={IsBirlikleriDetay}></Route>
            <Route exact path="/:lang?/musteriHizmetleri" component={musteriHizmetleri}></Route>
            <Route exact path="/:lang?/shipping-returns" component={ShippingReturns}></Route>
            <Route exact path="/:lang?/membership" component={Membership}></Route>
            <Route exact path="/:lang?/privacy-policy" component={PrivacyPolicy}></Route>
            <Route exact path="/:lang?/termsconditions" component={TermsConditions}></Route>
            <Route exact path="/:lang?/sozlesmeler/:id?" component={Sozlesmeler}></Route>
            <Route exact path="/:lang?/faq" component={Faq}></Route>
            <Footer />
            {/* </> */}
          </Switch>
        </BrowserRouter>
      </>
    )
  }
}
