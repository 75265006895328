import React, { Component } from 'react'
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';
import Modal from 'react-bootstrap/Modal';
import { inject, observer } from 'mobx-react';
import Sprite from '../Assets/Icons/sprite.svg';
import ExceptionTranslate from '../lib/ExceptionTranslate'

const SiparisOdeme = observer(class SiparisOdeme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      errorMessage: "",

      loading: false
    };
  }
  componentDidMount() {
    var _isLogin = localStorage.getItem("isLogin");
    if (_isLogin === "true") {
      this.props.history.push(`/${localStorage.getItem("lang")}/adresbilgisi`)
    }
  }
  hideLoginModal = () => {
    this.setState({
      showLoginModal: false,
      showSuccesModal: false,
      showAlertModal: false,
      forgotPasswordModal: false,
      showNewPasswordModal: false,
      showSuccessActivationModal: false,
      showErrorModal: false,
    })
  }
  _loginFunction = () => {
    var AnonymousUserId = localStorage.getItem("AnonymousUserId")
    this.setState({ loading: true },
      () => {
        this.props.Store.CurrentUserStore.Login(this.state.userName, this.state.password, AnonymousUserId, this._errorFunction)
      }, () => {
        this.setState({ loading: false })
      })

  }
  _errorFunction = () => {
    var errorMessage = localStorage.getItem("errorMessage")
    this.setState({ loading: false, errorMessage: errorMessage, showErrorModal: true })
  }
  onPressForgotPassword = () => {
    this.setState({ forgotPasswordModal: true, showLoginModal: false, showAlertModal: false })

  }
  forgotPasswordSendMail = () => {
    this.setState({ actLoading: true, forgotPasswordModal: true })

    ApiBase.Post({
      url: '/send-forgot-password-mail',
      data: {
        Email: this.state.ForgotEmail
      },
      successFunction: (e) => {
        this.setState({ showAlertModal: true, forgotPasswordModal: true });
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data)
        this.setState({ showErrorModal: true });

      }
    })
  }

  render() {
    const { history } = this.props;
    return (
      <div className="SiparisOdeme d-flex ">
        <div className="container my-auto">
          <div className="row">
            <div className="col-lg-6 pb-5">
              <h5 className="SiparisOdeme__title h-size-1 text-uppercase py-2 text-center">{i18next.t('SignIn')} </h5>
              <p className="p-size-1 py-4 pl-2 text-center divGilroy">  {i18next.t('SiparisOdemeText1')}</p>
              <div className="LoginModal siparisOdeme__inputHr">
                <div className="divFormSubTitle">E-posta</div>
                <input type="email" className="form-control divFromInput" placeholder={i18next.t('EmailAdress')} value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value })} />
                <div className="divFormSubTitle">Şifre</div>
                <input type="password" className="form-control divFromInput" placeholder={i18next.t('Password')} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                <div className="col-lg-8 mx-auto">
                  <div className="text-center mx-auto">
                    <a onClick={() => { this._loginFunction() }} className={this.state.loading == true ? "submit-button loading" : "submit-button"}>{i18next.t('SignIn')}</a>
                  </div>
                </div>
              </div>
              <div className="LoginModal__links">
                <div className=" text-center">
                  <a className="p-size-2 divGilroy" onClick={this.onPressForgotPassword} >{i18next.t('ForgotPassword')}</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex">
              <div className="col-lg-12  my-auto">
                <div className=" col-lg-8 SiparisOdeme__section2 mx-auto ">
                  <p className="text-center h-size-3 p-size--b">{"Kayıtlı hesabınız yok mu?"}</p>
                  <div className="col-lg-12">
                    <a href={`/${localStorage.getItem("lang")}/uyeol`} className="SiparisOdeme__SignupBtn h-size-2 ">{i18next.t('SIGNUP')}</a>
                  </div>
                  <p className="my-3 text-center divGilroy">{i18next.t('OR')}</p>
                  <div className="col-lg-12 ">
                    <a href={`/${localStorage.getItem("lang")}/uyeliksizalisveris`} className="submit-button2">{i18next.t('BuyWithoutAMember')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Error Modal  */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
          <p></p>
          <Modal.Body>
            <div className="text-center mb-3">
              <svg className="iconSize-md" >
                <use href={Sprite + "#sad-face"} ></use>
              </svg >
            </div>
            <form className="LoginModal  ">
              <p className="text-center">{ExceptionTranslate(this.state.errorMessage)}</p>
              <div className="text-center  w-50  mx-auto">
                <a onClick={() => { this.setState({ showErrorModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/* Şifremi Unuttum Modal */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.forgotPasswordModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('ForgotPassword')}</h4>
          <p></p>
          <Modal.Body>
            <div className="LoginModal">
              <p className="text-center p-size-1">{i18next.t('ForgotPasswordText')}</p>
              <div class="form-group  mx-auto">
                <input type="email" placeholder={i18next.t('EmailAdress')} value={this.state.ForgotEmail} onChange={(e) => this.setState({ ForgotEmail: e.target.value })} required />
              </div>
              <div className="text-center  w-50  mx-auto">
                <a onClick={this.forgotPasswordSendMail} className="submit-button">{i18next.t('Send')}</a>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Alert Modal */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showAlertModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="LoginModal">
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#smile"} ></use>
                </svg >
              </div>
              <p className="text-center h-size-3 p-size--color2 ">{i18next.t("AlertModalText")} </p>
              <div className="d-flex px-2">
                <div className="mx-auto ">
                  <p className=" p-size-3 ps-ize--b mb-2"> Aktivasyon linki e-postası elinize ulaşmadıysa:</p>
                  <p className=" p-size-3 font--opacity  mb-2"> {i18next.t("AlertModalText1")} </p>
                  <p className=" p-size-3  font--opacity mb-2"> {i18next.t("AlertModalText2")}</p>
                  <p className=" p-size-3  font--opacity mb-2">{i18next.t("AlertModalText3")}</p>
                </div>
              </div>
              <div className="text-center  w-50  mx-auto">
                <button onClick={() => this.setState({ showAlertModal: false })} className="submit-button">{i18next.t("TurnBack")}</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div >
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(SiparisOdeme);
