import React, { Component } from 'react';
import BreadCrumb from '../Components/BreadCrumb';
import KeepInTouch from '../Components/KeepInTouch';
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';
import About1 from '../Assets/Image/ferualAboutNew1.png';
import About2 from '../Assets/Image/ferualAboutNew2.png';
import playButton from '../Assets/Image/playCircle.svg';
import ReactPlayer from 'react-player';

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingBlogs: false,
      AboutDetail: [],

    };
  }

  componentDidMount() {
    this.getAbout();
  }

  getAbout = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-about-detail',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        this.setState({
          AboutDetail: e.data.data.aboutData
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
      }
    })
  }
  render() {
    return (
      <div className="About">
        <div className="container">
          <BreadCrumb path={i18next.t('ABOUT')} />
          <h2 className="h-size-4 text-center mt-4">{this.state.AboutDetail.Title}</h2>

          <div className="row">
            <div className="col-lg-12 mx-auto ">
              <div className="position-relative divMarginAbout">
                <ReactPlayer
                  url={this.state.AboutDetail.Video}
                  controls
                  playbackRate={1}
                  light={this.state.AboutDetail.PhotoPath}

                  className="divVideoPlayer"
                />
              </div>
              <p className="p-size-2 divGilroy text-center " dangerouslySetInnerHTML={{ __html: this.state.AboutDetail.Description }}></p>
            </div>
            <div className="col-lg-12 p-0">
              <div className="row divAboutRow">
                <div className="col-lg-5 col-md-12 mt-4 mr-auto">
                  <img src={About1} className="img-fluid"></img>
                </div>
                <div className="col-lg-5 col-md-12 mt-4 ml-auto">
                  <img src={About2} className="img-fluid"></img>
                </div>
                <div className="divTheFSVG"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
