/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import i18next from 'i18next';

export default class SozlesmeCardMobil extends Component {

  render() {
    const { active1, active2, active3, active4, active5, active6, active7 } = this.props;
    const title = active1 ?
      <p className="d-inline card--color">
        <a href="#" className="p-size-3">{i18next.t("FtShippingReturns")}  </a>
      </p>
      :
      active2 ?
        <p className="d-inline card--color">
          <a href="#" className="p-size-3">{i18next.t("FtMembership")}</a>
        </p>
        :
        active3 ?
          <p className=" d-inline card--color">
            <a href="#" className="p-size-3"> {i18next.t("FtPrivacyPolicy")}</a>
          </p>
          :
          active4 ?
            <p className="d-inline card--color">
              <a href="#" className="p-size-3">{i18next.t("FtTermsConditions")}</a>
            </p>
            :
            ""

    return (
      <div>
        <DropdownButton id="dropdown-item-button" title={title} className="UserDetailCardMobil">
          <Dropdown.Item active={active1} as="button">
            <p className="">
              <a href={`/${localStorage.getItem("lang")}/shipping-returns`} className="p-size-3">{i18next.t("FtShippingReturns")}</a>
            </p>
          </Dropdown.Item>
          <Dropdown.Item active={active2} as="button">
            <p>
              <a href={`/${localStorage.getItem("lang")}/membership`} className="p-size-3">{i18next.t("FtMembership")}</a>
            </p>
          </Dropdown.Item>
          <Dropdown.Item active={active3} as="button">
            <p>
              <a href={`/${localStorage.getItem("lang")}/privacy-policy`} className="p-size-3"> {i18next.t("FtPrivacyPolicy")}</a>
            </p>
          </Dropdown.Item>
          <Dropdown.Item active={active4} as="button">
            <p>
              <a href={`/${localStorage.getItem("lang")}/termsconditions`} className="p-size-3">{i18next.t("FtTermsConditions")}</a>
            </p>
          </Dropdown.Item>
        </DropdownButton>
      </div>
    )
  }
}
