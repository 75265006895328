import { extendObservable, action, observable, configure, runInAction } from 'mobx';
import ApiBase from '../lib/ApiBase';

function CurrentUserStore() {
  var _AccessToken = localStorage.getItem("AccessToken")
  var _CurrentUser = localStorage.getItem("CurrentUser")
  var _IsLogin = localStorage.getItem("isLogin")
  extendObservable(this, {
    AccessToken: _AccessToken,
    CurrentUser: _CurrentUser,
    IsLogin: _IsLogin,
  
    Login: action(function (Email, Password, AnonymousUserId,errorFunction) {
      ApiBase.Post({
        url: '/login',
        data: {
          Email: Email,
          Password: Password,
          AnonymousUserId:AnonymousUserId
        },
        successFunction: (e) => {
          // console.log(e.data.data)
          this.AccessToken = e.data.data.AccessToken;
          this.CurrentUser = e.data.data;
          this.IsLogin = true;
          localStorage.setItem("CurrentUser", JSON.stringify(this.CurrentUser))
          localStorage.setItem("AccessToken", this.AccessToken)
          localStorage.setItem("isLogin", this.IsLogin)
          localStorage.removeItem("AnonymousUserId");
          window.location.reload()
        },
        errorFunction: (e) => {
          this.errorMessage = e.data.message;
          localStorage.setItem("errorMessage", this.errorMessage);
          errorFunction()
        },
      });
    }),
    ReloadUser: action(function (UserId) {
      ApiBase.Post({
        url: '/reload-user',
        data: {
          UserId: UserId,
          
        },
        successFunction: (e) => {
          // console.log("reloadUser",e.data.data)
          this.AccessToken = e.data.data.AccessToken;
          this.CurrentUser = e.data.data;
          localStorage.setItem("CurrentUser", JSON.stringify(this.CurrentUser))
          localStorage.setItem("AccessToken", this.AccessToken)
          // localStorage.setItem("isLogin", this.IsLogin)
          // localStorage.removeItem("AnonymousUserId");
          // window.location.reload()
        },
        errorFunction:(e)=>{
          // console.log("reloadUser",e.data.message)
        }
      });
    })
  })
  
 
}

// class CurrentUserStore {
//   constructor() {
//     extendObservable(this, {
//       AccessToken : "",
//       CurrentUser : [],
//       Name:"burak",
//       IsLogin : false,
//       UpdateName: action(function(){
//         this.Name="özdemirs"
//       })
//   //     Login: action(function (Email, Password, errorFunction) {
// ApiBase.Post({
//   url: '/login',
//   data: {
//     Email: Email,
//     Password: Password
//   },
//   successFunction:  (e) => {
//     console.log(this.Name)
//     this.AccessToken = e.data.data.AccessToken;
//     this.CurrentUser = e.data.data;
//     this.IsLogin = true;
//     localStorage.setItem("CurrentUser", this.CurrentUser)
//     localStorage.setItem("AccessToken", this.AccessToken)
//     localStorage.setItem("isLogin", this.IsLogin)

//   },
//   errorFunction: errorFunction,
// });
//   //     }),
//   //     UpdateName: action(function() {
//   //       this.Name = "Özdemir"
//   // })
//     })
//   }

// }
export default new CurrentUserStore();