/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg'
import i18next from 'i18next';

export default class OrderDetail extends Component {
  render() {
    const { item, orderDetail, isInDetail } = this.props;
    return (

      <div className="  py-2 justfy-items-center justify-content-center align-items-center UserDetail__sectionSub2">
        <div className="spanLabel ml-4">SİPARİŞ NO:</div>
        <div className="spanLabel2"> {item.OrderNo}</div>
        <div className="spanLabel ml-4">SİPARİŞ TARİHİ:</div>
        <div className="spanLabel2">{item.DateStr}</div>
        <div className="spanLabel ml-4">SİPARİŞ ÖZETİ:</div>
        <div className="spanLabel2">{item.ProductCount} {i18next.t("Product")}</div>
        <div className="spanLabel ml-4">{i18next.t("TotalPrice")}</div>
        <div className="spanLabel2">{orderDetail ? item.TotalProductPriceStr : item.PriceStr} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</div>
        <div className="spanLabel3 mx-auto">
          {
            item.Status !== 11 ?
              <p className={item.Status === 6 || item.Status === 9 || item.Status === 10 || item.Status === 11 ? "OrderDetail__OrderStatus2" : item.Status === 4 ? "OrderDetail__OrderStatus3" : "OrderDetail__OrderStatus1"} >{item.StatueStr}</p>
              :
              <p className={item.Status === 6 || item.Status === 9 || item.Status === 10 || item.Status === 11 ? "OrderDetail__OrderStatus2" : item.Status === 4 ? "OrderDetail__OrderStatus3" : "OrderDetail__OrderStatus1"} >{item.PreviousStatueStr} ({item.StatueStr})</p>
          }
        </div>
        {
          isInDetail ?
            <></>
            :
            <div className=" my-auto ml-auto OrderDetail__ArrowRight">
              <a href={`/${localStorage.getItem("lang")}/siparislerimdetay/${item.OrderId}`}>
                <svg className="iconSize-sm " >
                  <use href={Sprite + "#arrow-right"} ></use>
                </svg >
              </a>
            </div>
        }
      </div >
    )
  }
}
