import React, { Component } from 'react';
import i18next from 'i18next';

export default class InspirationBanner extends Component {
  render() {
    const { item, className, blogHref, isBlog, fashionHref } = this.props;
    return (
      <div>
        <div className="InspirationBanner__img  ">
          <img src={item.CoverPhoto} className="img-fluid"></img>
          <div className={className}>
            <h2>{item.Title}</h2>
            <p className="InspirationBanner__text--clamp module InspirationBanner__text-font" dangerouslySetInnerHTML={{ __html: item.DescriptionShort }}></p>
            <a href={isBlog ? blogHref : fashionHref} className="p-size-2 p-size--b p-size--color2">{isBlog ? i18next.t("ReadMore") : item.ButtonTitle} {'>'}</a>
          </div>
        </div>
      </div>
    )
  }
}