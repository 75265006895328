import React, { Component } from 'react'
import i18next from 'i18next';
import Logo from '../Assets/Image/logo.png';

export default class OdemeSayfasiBasarili extends Component {
  render() {
    return (
      <div className="ActivationPage">
        <div className="container ">
          <div className="ActivationPage__Body d-flex flex-column">
            <div className=" text-center my-auto">
              <img src={Logo} className="img-fluid"></img>
              {this.stateactLoading !== true &&
                <h4 className="text-center h-size-4 mt-4 "> Ödeme İşleminiz Tamamlanmıştır. </h4>}

              <div className="row">
                <div className=" col-lg-2 mx-auto py-3 ">
                  <a className="addAddressBtn h-size-2 text-uppercase" href={`/${localStorage.getItem("lang")}/`}>{i18next.t("Home")}</a>
                </div>

              </div>
            </div>


          </div>
        </div>

      </div>
    )
  }
}
