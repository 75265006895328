export default function ExceptionTranslate(Exception) {
    var Lang = localStorage.getItem("lang");
    switch (Exception) {
        case "Bu İşlemi Yapabilmek İçin Giriş Yapmanız Gerekmektedir":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "In order to proceed with that request please login.";
            }
        case "Lütfen email giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter email.";
            }
        case "Lütfen şifre giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter password.";
            }
        case "Kullanıcı bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "User could not be found.";
            }
        case "Blog Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Blog could not be found.";
            }
        case "İçerik Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Content could not be found.";
            }
        case "Slider Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Slider could not be found.";
            }
        case "Banner Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Banner could not be found.";
            }
        case "Sözlşeme Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Contract could not be found.";
            }
        case "Ürün Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Prodcut could not be found.";
            }
        case "Kategori Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Category could not be found.";
            }
        case "Dil Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Language could not be found.";
            }
        case "Ülke bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Country could not be found.";
            }
        case "Şehir bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "City could not be found.";
            }
        case "Aktivasyon Kodu Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Activation code could not be found.";
            }
        case "Şifre Değiştirme İsteği Bulunamadı.Lütfen Yeni Bir İstek Oluşturunuz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Password change request could not be found.";
            }
        case "Bilgilerinizi güncellemek için lütfen giriş yapınız.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "In order to update your information please login.";
            }
        case "Şifrenizi güncellemek için lütfen giriş yapınız.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "In order to update your pasword please login.";
            }
        case "Lütfen Mevcut Şifrenizi Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your current password.";
            }
        case "Lütfen Yeni Şifrenizi Giriniz.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your new password.";
            }
        case "Şifreniz 8 ila 20 Karakter Arası Olmalıdır":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Your password length must be between 8 and 20.";
            }
        case "Yeni Şifreniz ile Yeni Şifrenizin Doğrulaması Uyuşmamaktadır":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Your passwords do not match.";
            }
        case "Lütfen Favoriye Eklemek İstediğiniz Ürünü Seçiniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select the product that you want to favorite.";
            }
        case "Lütfen Favoriden Kaldırmak İstediğiniz Ürünü Seçiniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select the product that you want to unfavorite.";
            }
        case "Favori ürünlerinizi görüntülemek için lütfen giriş yapınız.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "In order to view your favorites please login.";
            }
        case "Adres Ekleyebilmek İçin Lütfen Giriş Yapınız.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "In order to add an address please login.";
            }
        case "Lütfen Adres Başlığı Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter address title.";
            }
        case "Lütfen İsim Soyisim Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter name and surname.";
            }
        case "Lütfen Şehir Seçiniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select city.";
            }
        case "Lütfen İlçe Seçiniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select distirict.";
            }
        case "Lütfen Adres Seçiniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select address.";
            }
        case "Lokasyan Bilgisi Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Location informatin coul not be found.";
            }
        case "Adres Güncelleyebilmek İçin Lütfen Giriş Yapınız.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "In order to update an address please login.";
            }
        case "Lütfen Sepete Eklemek İstediğiniz Ürünü Seçiniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select the product that you want to add to chart.";
            }
        case "Lütfen Varyasyon Seçiniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select variation of the product.";
            }
        case "Teslimat Adresi Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Delivery address could not be found.";
            }
        case "Fatura Adresi Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Bill address could not be found.";
            }
        case "Lütfen İsim Soyisim Giriniz(Teslimat Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your name and surname(Delivery address).";
            }
        case "Lütfen Geçerli Bir İsim Soyisim Giriniz(Teslimat Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter valid name and surname(Delivery address).";
            }
        case "Lütfen Email Adresi Giriniz(Teslimat Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your email(Delivery address).";
            }
        case "Lütfen Geçerli Bir Email Adresi Giriniz(Teslimat Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter valid email(Delivery address).";
            }
        case "Lütfen Telefon Numarası Giriniz(Teslimat Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your phone(Delivery address).";
            }
        case "Lütfen Şehir Seçiniz(Teslimat Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select city(Delivery address).";
            }
        case "Lütfen İlçe Seçiniz(Teslimat Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select district(Delivery address).";
            }
        case "Lütfen Teslimat Adresi Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter delivery address.";
            }
        case "Lütfen İsim Soyisim Giriniz(Fatura Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your name and surname(Bill address).";
            }
        case "Lütfen Geçerli Bir İsim Soyisim Giriniz(Fatura Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter valid name and surname(Bill address).";
            }
        case "Lütfen Email Adresi Giriniz(Fatura Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your email(Bill address).";
            }
        case "Lütfen Geçerli Bir Email Adresi Giriniz(Fatura Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter valid email(Fatura address).";
            }
        case "Lütfen Telefon Numarası Giriniz(Bill Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your phone(Bill address).";
            }
        case "Lütfen Şehir Seçiniz(Fatura Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select city(Bill address).";
            }
        case "Lütfen İlçe Seçiniz(Fatura Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select district(Bill address).";
            }
        case "Lütfen Fatura Adresi Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter bill address.";
            }
        case "Lütfen İsim Soyisim Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your name and surname.";
            }
        case "Lütfen Geçerli Bir İsim Soyisim Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter valid name and surname.";
            }
        case "Lütfen Geçerli Bir E-Posta Adresi Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter valid email.";
            }
        case "Şifreniz 8 ila 20 karakter arası olmalıdır.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Password length must be between 8 and 20.";
            }
        case "Şifre ile Şifre Doğrulama aynı olmalıdır.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Passwords must match.";
            }
        case "Lütfen Email Adresi Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your email.";
            }
        case "Lütfen Geçerli Bir Email Adresi Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter valid email.";
            }
        case "Lütfen Telefon Numarası Giriniz)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your phone.";
            }
        case "Lütfen Şehir Seçiniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select city.";
            }
        case "Lütfen İlçe Seçiniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select district.";
            }
        case "Lütfen Fatura Adresi Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter bill address.";
            }
        case "Lütfen Firma Adı Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter company name.";
            }
        case "Lütfen Vergi Numarası Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter tax number.";
            }
        case "Lütfen Vergi Dairesi Giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter tax department.";
            }
        case "Lütfen TC Kimlik Numarası Giriniz(Fatura Adresi)":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your citizenship number.";
            }
        case "Sepet Bilgisine Ulaşılamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Cart could not be found.";
            }
        case "Yeterli Stok Bulunmamaktadır":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "There is no stock.";
            }
        case "Lütfen kart numarası giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter card number.";
            }
        case "Lütfen geçerli bir kart numarası giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter valid card number.";
            }
        case "Lütfen kart üzerinde yazan isim soyismi giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter the name and surname that written on the card.";
            }
        case "Lütfen kartın son kullanım tarihini giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter expiration date of the card.";
            }
        case "Lütfen geçerli bir son kullanma tarihi giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter valid expiration date.";
            }
        case "Lütfen CVC bilgisi giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter CVC.";
            }
        case "Lütfen Geçerli bir CVC kodu giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter valis CVC.";
            }
        case "Lütfen taksit sayısını seçiniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please select installation count.";
            }
        case "Fiyat Bilgisine Ulaşılamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Price information could not be found.";
            }
        case "PayTR Bilgisine Ulaşılamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "PayTR information could not be found.";
            }
        case "Sipariş Bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Order could not be found.";
            }
        case "Siparişlerinizi görüntülemek için lütfen giriş yapınız.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "In order to view your orders please login.";
            }
        case "Soru bulunamadı":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Question could not be found.";
            }
        case "Talep oluşturmak için lütfen giriş yapınız.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "In order to create a request please login.";
            }
        case "Kullanım Şartlarını ve Kişisel Verilen Korunması Kanunu Kabul Ediniz.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Pleace read and accept privacy policy.";
            }
        case "Lütfen isim soyisim giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter name and surname.";
            }
        case "Lütfen Kişisel Verilerin Korunması Kanunu'nu okuyup kabul ediniz.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please read and accept privacy policy.";
            }
        case "Lütfen email adresi giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter email address";
            }
        case "Lütfen mesajınızı giriniz":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your message";
            }
        case "Send successfuly":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter your message";
            }
        case "Sepete Eklendi!":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Added to the cart!";
            }
        case "Lütfen geçerli bir TC kimlik numarası giriniz.":
            if (Lang === "tr") {
                return Exception;
            }
            else {
                return "Please enter valid citizenship number.";
            }
        default:
            return Exception;
    }
}
