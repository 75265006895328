import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'mobx-react';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Assets/Fonts/stylesheet.css';
import 'react-input-range/lib/css/index.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import stores from './Store/index';
import 'react-toastify/dist/ReactToastify.minimal.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider Store={stores}>
      <App />
    </Provider>
  </React.StrictMode >,

  document.getElementById('root')
);

serviceWorker.unregister();
