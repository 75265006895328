
import React from "react";
import Router from './Router';
import './Assets/Css/style.css';
import './i18n';
import axios from 'axios';
import i18next from 'i18next';

function App() {

  const _pathNameForLang = window?.location?.pathname?.split('/')[1];

  const _currentLang = localStorage.getItem("lang");

  const _userLanguage = window.navigator.userLanguage || window.navigator.language;

  if (_pathNameForLang === "tr") {
    localStorage.setItem("lang", "tr");
  } else if (_pathNameForLang === "en") {
    localStorage.setItem("lang", "en");
  }
  else {
    if (_currentLang === null) {
      if (_userLanguage === "tr-TR") {
        localStorage.setItem("lang", "tr");
      }
      else {
        localStorage.setItem("lang", "en");
      }
    }
  }

  const _settedlang = localStorage.getItem('lang') || 'tr';
  axios.defaults.baseURL = 'http://localhost:3000/';
  axios.defaults.headers.common['Accept-Language'] = _settedlang;
  i18next.changeLanguage(_settedlang);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
