/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import ProductCard from '../Components/ProductCard';
import Slider from "react-slick";
import i18next from 'i18next';
import { inject, observer } from 'mobx-react';
import ApiBase from '../lib/ApiBase';
import Modal from 'react-bootstrap/Modal';
import Sprite from '../Assets/Icons/sprite.svg';
import Cookies from 'universal-cookie';
import sliderholder from '../Assets/Image/slider-holder.png';
import bannerholder from '../Assets/Image/banner-holder.png';
import ExceptionTranslate from '../lib/ExceptionTranslate'

const Home = observer(class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      className: "product-card__like-button",
      name: 'burak',
      favoriteList: [],
      isFavorited: -1,
      BlogList: [],
      RecommendedProducts: [],
      SliderList: [],
      favoriteIds: [],
      showErrorModal: false,
      errorMessage: "",
      BannerList: [],
      showWelcomeModal: false,
      popupData: [],
      loadingSlider: true,
      loadingBanner: true
    };
  }
  _
  componentDidMount() {
    const _url = this.props.match.params.lang;

    if (_url?.length > 2) {
      const _currentLang = localStorage.getItem("lang");
      if (_currentLang === null) {
        window.location.href = `/tr/${_url}`;
      } else {
        window.location.href = `/${_currentLang}/${_url}`;
      }
    }
    else {
      // this.getBlogs();
      this.getRecommendedProducts();
      this.getSlider();
      this.getFavoriteIds();
      this.getBanners();
      // this.getPopup();
    }
  }

  getBlogs = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-blogs',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        this.setState({
          BlogList: e.data.data.blogList,
          FeatureBlog: e.data.data.featuredBlog,
          loadingBlogs: false
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
      }
    })
  }

  getPopup = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-popup',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        var date = new Date();
        var expireDate = new Date(date.setTime(date.getTime() + 1 * 86400000));
        const cookies = new Cookies();
        var isAnyCookie = cookies.get('popup');
        if (isAnyCookie === "true") {
          this.setState({ showWelcomeModal: false });
        }
        else {
          this.setState({ showWelcomeModal: true })
          cookies.set('popup', true, { path: '/', expires: expireDate });
        }
        this.setState({
          popupData: e.data.data.popup
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false, showWelcomeModal: false });
      }
    })
  }

  getSlider = () => {
    this.setState({ loadingSlider: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-slider',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        this.setState({
          SliderList: e.data.data.sliderList,
          loadingSlider: false
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingSlider: false });
      }
    })
  }

  toggleFavorite = (item) => {
    const i = this.state.favoriteList.indexOf(item.Id);

    if (i >= 0) {
      this.state.favoriteList.splice(i, 1);
    } else {
      this.state.favoriteList.push(item.Id);
    }
    this.setState({ favoriteList: this.state.favoriteList });
  };

  AddFavorite = (item, ProductId) => {
    this.setState({ addFavoriteLoading: true })
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }
    ApiBase.Post({
      url: '/add-favourite',
      data: {
        ProductId: ProductId,
        CurrentUser: cUser
      },
      successFunction: (e) => {
        this.getFavoriteIds();
        this.setState({ addFavoriteLoading: false });
        // this.toggleFavorite(item)
      },
      errorFunction: (e) => {
        this.setState({ showErrorModal: true, errorMessage: e.data.message, addFavoriteLoading: false });
        if (e.data.message === "Ürün, favori ürünleriniz arasında bulunmaktadır.") {
          // this.toggleFavorite(item)
        }
      }
    })
  }

  removeFavorites = (ProductId) => {
    this.setState({ addFavoriteLoading: true });
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];

    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }

    ApiBase.Post({
      url: '/delete-favourite',
      data: {
        ProductId: ProductId,
        CurrenUser: cUser
      },
      successFunction: (e) => {
        this.setState({ addFavoriteLoading: false })
        this.getFavoriteIds();
      },
      errorFunction: (e) => {
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message });
      }
    })
  }

  getBanners = () => {
    this.setState({ loadingBanner: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-banners',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        this.setState({
          BannerList: e.data.data.bannerList,
          loadingBanner: false
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBanner: false });
      }
    })
  }

  getRecommendedProducts = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-featured-products',
      data: {
        LanguageId: LangId,
        CountryId: LangId
      },
      successFunction: (e) => {
        this.setState({
          RecommendedProducts: e.data.data.productList,
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false });
      }
    })
  }

  getFavoriteIds = () => {
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var favIdList = [];
    ApiBase.Post({
      url: '/get-favorite-ids',
      data: {
        UserId: _UserId,
      },
      successFunction: (e) => {
        e.data.data.List.map(item =>
          favIdList.push(item.ProductId));
        this.setState({ favoriteIds: favIdList });
      },
      errorFunction: (e) => {
      }
    })
  }

  render() {
    const holders = [];

    for (let index = 0; index < 3; index++) {
      holders.push(<div className="col-lg-4"><div className="category-card d-flex flex-column" style={{ background: `url(${bannerholder})` }}>
      </div></div>)
    }

    var settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 576,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        },
      ],
    };

    var settingsRecom = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.RecommendedProducts.length < 4 ? this.state.RecommendedProducts.length : 4,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: this.state.RecommendedProducts.length < 2 ? this.state.RecommendedProducts.length : 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplaySpeed: 3000,

          }
        },
        {
          breakpoint: 576,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            autoplaySpeed: 3000
          }
        },
      ],
    };

    const headerSlider = this.state.SliderList.map(item => {
      return (
        <a key={item.Id} href={item.ContinueUrl} target={item.IsBlank && "_blank"} >
          <header className="headerSlider " >
            <div className="headerSliderShade"></div>
            <div className="divSliderImage" style={{ backgroundImage: `url(${item.PhotoPath})` }}></div>
            <div className="headerSlider__texts container text-center">
              <div className="headerSlider__header">
                <h1 className="headerSlider__title slider-m-title" style={{ color: item.FontColor }}>{item.Title}</h1>
              </div>
            </div>
          </header>
        </a>
      )
    });

    const myBannerList = this.state.BannerList.map((item, index) => {
      if (index % 2 == 0) {
        return (
          <>
            <div className="container row mx-auto divMyBannerRow">
              <div className="col-lg-6 col-md-12 mx-auto">
                <div className="h-100 d-flex divMobileMargin">
                  <div className="my-auto">
                    <div className="divMyBannerTitle">{item.Title}</div>
                    <p className="divMyBannerDescription" dangerouslySetInnerHTML={{ __html: item.Description }}>
                    </p>
                    <a onClick={() => { this.props.history.push(item.ContinueUrl) }} className="divMyBannerButton">{i18next.t('StartShop')}</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 ml-auto position-relative">
                <div className="divTheFSVG"></div>
                <img className="img-fluid" src={item.PhotoPath} />
              </div>
            </div>
          </>
        )
      }
      else {
        return (
          <>
            <div className="container row mx-auto flex-column-reverse flex-md-row divMyBannerRow">
              <div className="col-lg-5 col-md-12 ml-auto position-relative">
                <div className="divTheLSVG"></div>
                <img className="img-fluid" src={item.PhotoPath} />
              </div>
              <div className="col-lg-6 col-md-12 mx-auto">
                <div className="h-100 d-flex divMobileMargin">
                  <div className="my-auto">
                    <div className="divMyBannerTitle">{item.Title}</div>
                    <p className="divMyBannerDescription" dangerouslySetInnerHTML={{ __html: item.Description }}>
                    </p>
                    <a onClick={() => { this.props.history.push(item.ContinueUrl) }} className="divMyBannerButton">{i18next.t('StartShop')}</a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    })

    const ListItem = this.state.RecommendedProducts.map((pitem) => {
      return (
        <div className="px-2 px-0">
          <li className="list-unstyled" key={pitem.ProductId} >
            <ProductCard item={pitem} _className={this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? "product-card__like-button active" : "product-card__like-button"} toggleFavorite={() => { this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? this.removeFavorites(pitem.Id) : this.AddFavorite(pitem, pitem.Id) }} ></ProductCard>
          </li>
        </div>
      );
    });
    return (
      <>
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showWelcomeModal} onHide={() => { this.setState({ showWelcomeModal: false }) }}>
          <Modal.Header closeButton>
          </Modal.Header>
          <div className="mt-3">
            <a href={this.state.popupData.ContinueUrl}>
              <img src={this.state.popupData.PhotoPath} className="img-fluid"></img>
            </a>
          </div>
          <p></p>
          <Modal.Body>
            <form className="LoginModal  ">
              <p className="text-center">{this.state.errorMessage}</p>
              <div className="text-center  w-50  mx-auto">
                <a href={this.state.popupData.ContinueUrl} className="submit-button">{this.state.popupData.ButtonTitle}</a>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <>
          {this.state.addFavoriteLoading == true &&
            <div className="favoriteoverlay"></div>
          }
          <div id="123" className="Home">
            <div className="container IndexSlider">
              {this.state.loadingSlider == true &&
                <div className="headerSlider">
                  <img src={sliderholder}></img>
                </div>}
              {this.state.loadingSlider != true &&
                <>
                  <Slider {...settings2} ref={slider => (this.slider1 = slider)}>
                    {headerSlider}
                    {
                      this.slider1 && this.slider1.slickGoTo(0)
                    }
                  </Slider>
                </>
              }
            </div>
            <h5 className="container text-center h-size-4 mt-4">
              {i18next.t('NewPieces')}
            </h5>
            <div className="container">
              <div className="product-card">
                <Slider {...settingsRecom}>
                  {ListItem}
                </Slider>
              </div>
            </div>
            <div className="container">
              <div className=" row mb-5 ">
                <div className="col-lg-6 mx-auto">
                  <div className="col-lg-7 mx-auto">
                    <div className="slider__button">
                      <a onClick={() => { this.props.history.push(`/${localStorage.getItem("lang")}/searchproducts/${false}/${true}`) }}>{i18next.t("AllProducts")}</a> </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divHomeNewBanner">
              <div className="divHomeNewBannerTitle">
                <div className="divHomeNewBannerTitleInnerContainer">
                  <span className="divHomeNewBannerSVG1">
                    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.9955 3.504C13.4275 2.64 14.0275 1.944 14.7955 1.416C15.6115 0.887997 16.5235 0.623996 17.5315 0.623996C18.8755 0.623996 20.0035 1.104 20.9155 2.064C21.8755 2.976 22.3555 4.104 22.3555 5.448C22.3555 6.744 21.8755 7.872 20.9155 8.832C19.9555 9.792 18.8275 10.272 17.5315 10.272C17.1955 10.272 16.9555 10.248 16.8115 10.2C16.9075 11.064 17.1475 11.976 17.5315 12.936C17.9155 13.848 18.2995 14.592 18.6835 15.168H17.2435C16.0435 13.824 14.9635 12.384 14.0035 10.848C13.0915 9.312 12.5875 7.848 12.4915 6.456C12.4435 5.976 12.4675 5.472 12.5635 4.944C12.6595 4.368 12.8035 3.888 12.9955 3.504ZM0.539469 3.504C0.971469 2.64 1.57147 1.944 2.33947 1.416C3.15547 0.887997 4.06747 0.623996 5.07547 0.623996C6.41947 0.623996 7.54747 1.104 8.45947 2.064C9.41947 2.976 9.89947 4.104 9.89947 5.448C9.89947 6.744 9.41947 7.872 8.45947 8.832C7.49947 9.792 6.37147 10.272 5.07547 10.272C4.73947 10.272 4.49947 10.248 4.35547 10.2C4.45147 11.064 4.69147 11.976 5.07547 12.936C5.45947 13.848 5.84347 14.592 6.22747 15.168H4.78747C3.58747 13.824 2.50747 12.384 1.54747 10.848C0.635469 9.312 0.131469 7.848 0.0354691 6.456C-0.012531 5.976 0.0114689 5.472 0.107469 4.944C0.203469 4.368 0.347469 3.888 0.539469 3.504Z" fill="#823134" />
                    </svg>
                  </span>
                  <span >
                    {i18next.t('HomeBanner1')}
                  </span>
                  <span className="divHomeNewBannerSVG2">
                    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.0045 3.504C9.57253 2.64 8.97253 1.944 8.20453 1.416C7.38853 0.887997 6.47653 0.623996 5.46853 0.623996C4.12453 0.623996 2.99653 1.104 2.08453 2.064C1.12453 2.976 0.644529 4.104 0.644529 5.448C0.644529 6.744 1.12453 7.872 2.08453 8.832C3.04453 9.792 4.17253 10.272 5.46853 10.272C5.80453 10.272 6.04453 10.248 6.18853 10.2C6.09253 11.064 5.85253 11.976 5.46853 12.936C5.08453 13.848 4.70053 14.592 4.31653 15.168H5.75653C6.95653 13.824 8.03653 12.384 8.99653 10.848C9.90853 9.312 10.4125 7.848 10.5085 6.456C10.5565 5.976 10.5325 5.472 10.4365 4.944C10.3405 4.368 10.1965 3.888 10.0045 3.504ZM22.4605 3.504C22.0285 2.64 21.4285 1.944 20.6605 1.416C19.8445 0.887997 18.9325 0.623996 17.9245 0.623996C16.5805 0.623996 15.4525 1.104 14.5405 2.064C13.5805 2.976 13.1005 4.104 13.1005 5.448C13.1005 6.744 13.5805 7.872 14.5405 8.832C15.5005 9.792 16.6285 10.272 17.9245 10.272C18.2605 10.272 18.5005 10.248 18.6445 10.2C18.5485 11.064 18.3085 11.976 17.9245 12.936C17.5405 13.848 17.1565 14.592 16.7725 15.168H18.2125C19.4125 13.824 20.4925 12.384 21.4525 10.848C22.3645 9.312 22.8685 7.848 22.9645 6.456C23.0125 5.976 22.9885 5.472 22.8925 4.944C22.7965 4.368 22.6525 3.888 22.4605 3.504Z" fill="#823134" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            {myBannerList}
            <div className="divHomeNewBanner  mt-5">
              <div className="divHomeNewBannerTitle">
                <div className="divHomeNewBannerTitleInnerContainer">
                  <span className="divHomeNewBannerSVG1">
                    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.9955 3.504C13.4275 2.64 14.0275 1.944 14.7955 1.416C15.6115 0.887997 16.5235 0.623996 17.5315 0.623996C18.8755 0.623996 20.0035 1.104 20.9155 2.064C21.8755 2.976 22.3555 4.104 22.3555 5.448C22.3555 6.744 21.8755 7.872 20.9155 8.832C19.9555 9.792 18.8275 10.272 17.5315 10.272C17.1955 10.272 16.9555 10.248 16.8115 10.2C16.9075 11.064 17.1475 11.976 17.5315 12.936C17.9155 13.848 18.2995 14.592 18.6835 15.168H17.2435C16.0435 13.824 14.9635 12.384 14.0035 10.848C13.0915 9.312 12.5875 7.848 12.4915 6.456C12.4435 5.976 12.4675 5.472 12.5635 4.944C12.6595 4.368 12.8035 3.888 12.9955 3.504ZM0.539469 3.504C0.971469 2.64 1.57147 1.944 2.33947 1.416C3.15547 0.887997 4.06747 0.623996 5.07547 0.623996C6.41947 0.623996 7.54747 1.104 8.45947 2.064C9.41947 2.976 9.89947 4.104 9.89947 5.448C9.89947 6.744 9.41947 7.872 8.45947 8.832C7.49947 9.792 6.37147 10.272 5.07547 10.272C4.73947 10.272 4.49947 10.248 4.35547 10.2C4.45147 11.064 4.69147 11.976 5.07547 12.936C5.45947 13.848 5.84347 14.592 6.22747 15.168H4.78747C3.58747 13.824 2.50747 12.384 1.54747 10.848C0.635469 9.312 0.131469 7.848 0.0354691 6.456C-0.012531 5.976 0.0114689 5.472 0.107469 4.944C0.203469 4.368 0.347469 3.888 0.539469 3.504Z" fill="#823134" />
                    </svg>
                  </span>
                  <span >
                    {i18next.t('HomeBanner2')}
                  </span>
                  <span className="divHomeNewBannerSVG2">
                    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.0045 3.504C9.57253 2.64 8.97253 1.944 8.20453 1.416C7.38853 0.887997 6.47653 0.623996 5.46853 0.623996C4.12453 0.623996 2.99653 1.104 2.08453 2.064C1.12453 2.976 0.644529 4.104 0.644529 5.448C0.644529 6.744 1.12453 7.872 2.08453 8.832C3.04453 9.792 4.17253 10.272 5.46853 10.272C5.80453 10.272 6.04453 10.248 6.18853 10.2C6.09253 11.064 5.85253 11.976 5.46853 12.936C5.08453 13.848 4.70053 14.592 4.31653 15.168H5.75653C6.95653 13.824 8.03653 12.384 8.99653 10.848C9.90853 9.312 10.4125 7.848 10.5085 6.456C10.5565 5.976 10.5325 5.472 10.4365 4.944C10.3405 4.368 10.1965 3.888 10.0045 3.504ZM22.4605 3.504C22.0285 2.64 21.4285 1.944 20.6605 1.416C19.8445 0.887997 18.9325 0.623996 17.9245 0.623996C16.5805 0.623996 15.4525 1.104 14.5405 2.064C13.5805 2.976 13.1005 4.104 13.1005 5.448C13.1005 6.744 13.5805 7.872 14.5405 8.832C15.5005 9.792 16.6285 10.272 17.9245 10.272C18.2605 10.272 18.5005 10.248 18.6445 10.2C18.5485 11.064 18.3085 11.976 17.9245 12.936C17.5405 13.848 17.1565 14.592 16.7725 15.168H18.2125C19.4125 13.824 20.4925 12.384 21.4525 10.848C22.3645 9.312 22.8685 7.848 22.9645 6.456C23.0125 5.976 22.9885 5.472 22.8925 4.944C22.7965 4.368 22.6525 3.888 22.4605 3.504Z" fill="#823134" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => this.setState({ showErrorModal: false })}>
              <Modal.Header closeButton>
              </Modal.Header>
              <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
              <p></p>
              <Modal.Body>
                <div className="text-center mb-3">
                  <svg className="iconSize-md" >
                    <use href={Sprite + "#sad-face"} ></use>
                  </svg >
                </div>
                <form className="LoginModal  ">
                  <p className="text-center  p-size-1">{ExceptionTranslate(this.state.errorMessage)}</p>
                  <div className="text-center  w-50  mx-auto">
                    <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('OK')}</a>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </>
      </>
    )
  }
})

export default inject((stores) => ({
  Store: stores.Store,
}))(Home);