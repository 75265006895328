import React, { Component } from 'react';
import ProfilDetailCard from '../Components/ProfilDetailCard';
import BreadCrumb from '../Components/BreadCrumb';
import UserDetailCardMobil from '../Components/UserDetailCardMobil';
import OrderDetail from '../Components/OrderDetail';
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Modal from 'react-bootstrap/Modal';
const Siparislerim = observer(class Siparislerim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: [
        {
          OrderId: 1,
          OrderCode: '#273723457',
          OrderStatus: 1,
          OrderDate: '21.09.2020',
          TotalPrice: '106,98 TL',
          Count: 3
        },
        {
          OrderId: 2,
          OrderCode: '#273723457',
          OrderStatus: 2,
          OrderDate: '21.09.2020',
          TotalPrice: '106,98 TL',
          Count: 2
        },
        {
          OrderId: 3,
          OrderCode: '#273723457',
          OrderStatus: 3,
          OrderDate: '21.09.2020',
          TotalPrice: '106,98 TL',
          Count: 2
        },
        {
          OrderId: 4,
          OrderCode: '#273723457',
          OrderStatus: 1,
          OrderDate: '21.09.2020',
          TotalPrice: '106,98 TL',
          Count: 3
        },
        {
          OrderId: 5,
          OrderCode: '#273723457',
          OrderStatus: 3,
          OrderDate: '21.09.2020',
          TotalPrice: '106,98 TL',
          Count: 5
        }
      ],
      Skip: 0,
      Take: 10,
      OrderList: [],
    };
  }
  componentDidMount() {
    this.getUserOrders();
  }
  getUserOrders = () => {
    this.setState({ actLoading: true })
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);

    ApiBase.Post({
      url: '/get-user-orders',
      data: {
        UserId: cUser.UserId
      },
      successFunction: (e) => {
        this.setState({ orderLoading: false, OrderList: e.data.data.List });
      },
      errorFunction: (e) => {
        this.setState({ actLoading: false, errorMessage: e.data.message });
      }
    })
  }
  render() {
    const listOrder = this.state.OrderList.map(item => {
      return <OrderDetail item={item} />
    })
    return (
      <div className="UserDetail">
        <div className="container">
          <BreadCrumb hesabim path={i18next.t("MyOrders")}></BreadCrumb>
          <div className="row  UserDetail__Siparislerim">
            <div className="col-lg-3 d-none d-lg-block  UserDetail__section1">
              <ProfilDetailCard className2={"bg--secondary"}></ProfilDetailCard>
            </div>
            <div className="col-lg-3  d-lg-none UserDetail__section1">
              <UserDetailCardMobil active2></UserDetailCardMobil>
            </div>
            <div className="col-lg-9  UserDetail__section2">
              {/* <div className="row d-none d-lg-flex">
                                <div className="col-lg-3 ">
                                   <p className="OrderDetail__head">{i18next.t("OrderCode")}</p> 
                                 </div>
                                <div className="col-lg-3 pr-0">
                                   <p className="OrderDetail__head">{i18next.t("OrderStatus")}</p> 
                                 </div>
                                <div className="col-lg-1 p-0">
                                <p style={{height:32}} className= "OrderDetail__head "></p>
                                </div>
                                <div className="col-lg-2 ">
                                   <p className="OrderDetail__head">{i18next.t("OrderDate")}</p>
                                 </div>
                                <div className="col-lg-2 ">
                                    <p className="OrderDetail__head">{i18next.t("TotalPrice")}</p>
                                </div>
                                <div className="col-lg-1 ">
                                </div>
                            </div>                     */}
              <div className="divProfileDetailTitle divGilroy font-weight-bold mb-2"> {i18next.t('MyOrders')}</div>
              {listOrder}
            </div>



          </div>
        </div>
      </div>
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(Siparislerim);
