import React, { Component } from 'react'
import i18next from 'i18next';

export default class UyeliksizAlisveris extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: "",
    };
  }
  onClickContinue = () => {
    if (this.state.Email === "") {
      alert("Email alanı boş geçilemez")
    } else {
      this.props.history.push(`/${localStorage.getItem("lang")}/adresbilgisi`);
    }
  }
  render() {
    return (
      <div className="UyeliksizAlisveris SiparisOdeme d-flex">
        <div className="container  my-auto">
          <div className="row">
            <div className="col-lg-6 text-center mx-auto">
              <h5 className="SiparisOdeme__title h-size-1 text-uppercase py-2"> {i18next.t('PurchaseWithoutMembership')}</h5>
              <p className="p-size-1 py-4 pl-2">  {i18next.t('HizliOdemeText2')} </p>
              <div className="LoginModal siparisOdeme__inputHr">
                <div className="divFormSubTitle">E-posta</div>
                <input type="email" className="form-control divFromInput" placeholder={i18next.t('EmailAdress')} value={this.state.Email} onChange={(e) => { this.setState({ Email: e.target.value }) }} />
                <div className="col-lg-6 mx-auto">
                  <div className="text-center mx-auto">
                    <a onClick={this.onClickContinue} className="h-size-3 p-size--b submit-button" >{i18next.t('Continue')}</a>
                  </div>
                </div>
                <p className="h-size-3">
                  <div className="row my-3">
                    <div className="col-12 text-center ">
                      {i18next.t('HizliOdemeText1')}
                    </div>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
