import React, { Component } from 'react';
// import ProductCardImage from '../Assets/Image/productcard1.svg';

import Sprite from '../Assets/Icons/sprite.svg';
import i18next from 'i18next';



export default class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }
  render() {
    const { item, designer, designerName, disabled } = this.props;
    const { _className } = this.props;
    var totalStock = 0;
    totalStock = item.IsMainVariation === true ? item.Stock : item.Variations.reduce((totalStock, x) => totalStock + x.Stock, 0);
    return (
      <>
        <div className="product-card  my-3">

          <a className="text-dark" href={`/${localStorage.getItem("lang")}/productdetail/${item.Url}`}>
            <div className="product-card__image">
              <img src={item.MainPhoto} className="img-fluid" />
              {item.DiscountPercentage > 0 ?
                <>
                  <span className="product-card__discountAmount">
                    -%{item.DiscountPercentage}
                  </span>
                </>
                :
                <>
                </>}
            </div>
            <div className="product-card__body">
              <p className="product-card__title">{item.Title}</p>
              {designer ? <p className="product-card__brand">{designerName}</p> : <p className="product-card__brand">{item.Designer.Name}</p>}
              {totalStock > 0 ?
                item.DiscountedPrice > 0 ?
                  <>
                    <span className="product-card__oldPrice"> <del>{parseFloat(item.Price).toFixed(2)} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</del></span>
                    <span className="product-card__newPrice">{parseFloat(item.DiscountedPrice).toFixed(2)} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</span>
                  </>
                  :
                  <>
                    <span className="product-card__newPrice"> {parseFloat(item.Price).toFixed(2)} {localStorage.getItem("lang") === "tr" ? "TL" : "$"}</span>
                  </>
                :
                <>
                  <span className="spanNoStock">{i18next.t('NOSTOCK')}</span>
                </>
              }
            </div>
          </a>
          <span disabled={disabled} onClick={this.props.toggleFavorite} className={_className}>
            <svg className="iconSize-xs " >
              <use href={Sprite + "#like-button"} ></use>
            </svg >
          </span>
        </div>
      </>
    );
  }
}

