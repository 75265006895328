
import React, { Component } from 'react';
import BreadCrumb from '../Components/BreadCrumb';
import KeepInTouch from '../Components/KeepInTouch';
import i18next from 'i18next';
import Sprite from '../Assets/Icons/sprite.svg';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import ApiBase from '../lib/ApiBase';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import ExceptionTranslate from '../lib/ExceptionTranslate'

export default class MusteriHizmetleri extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LayoutInfo: [],
      Map: "",
      NameSurname: "",
      Email: "",
      Phone: "",
      Message: "",
      TermsOfUse: false,
      Subject: "",
    }
  }
  componentDidMount() {
    this.getLayoutInfo();
  }
  getLayoutInfo = () => {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-layout-info',
      data: {

      },
      successFunction: (e) => {
        this.setState({
          LayoutInfo: e.data.data,
          Map: e.data.data.Map
        });
      },
      errorFunction: (e) => {
      }
    })
  };

  sendContactForm = () => {
    if (this.state.TermsOfUse != true) {
      this.setState({ showErrorModal: true, errorMessage: "Lütfen Kişisel Verilerin Korunması Kanunu'nu okuyup kabul ediniz." })
    } else {
      this.setState({ SendLoading: true });
      ApiBase.Post({
        url: '/add-contact-form',
        data: {
          NameSurname: this.state.NameSurname,
          Email: this.state.Email,
          Phone: "+90" + this.state.Phone,
          Message: this.state.Message,
          Subject: this.state.Subject
        },
        successFunction: (e) => {
          this.setState({ SendLoading: false, showSuccessModal: true, successMessage: e.data.message })

        },
        errorFunction: (e) => {
          this.setState({ SendLoading: false, showErrorModal: true, errorMessage: e.data.message })
        }
      })
    }
  }

  handleChangeSubject = (option) => {
    this.setState({ Subject: option.value })
  }


  render() {
    const customStyles = {

      option: (provided, state) => ({
        ...provided,
        fontSize: 12,
        color: 'black',
        boxShadow: 'none',
        backgroundColor: "transparent",

        '&:hover': {
          backgroundColor: "#DEDEDE",
          boxShadow: 'none',

        },
        '&:focus': {
          backgroundColor: "#DEDEDE",
          boxShadow: 'none',

        },
      }),

      control: (provided, state) => ({
        ...provided,
        paddingTop: 10,
        paddingBottom: 10,
        borderWidth: 0,
        borderBottomWidth: "1px",
        borderColor: '#EDE8DE!important',
        backgroundColor: "transparent",
        borderRadius: 0,
        outline: "transparent",
        boxShadow: '0 !important',
        '&:focus': {
          borderColor: "#CEC2A6!important",
          boxShadow: 'none',

          '&:hover': {
            borderColor: "#CEC2A6!important",
            boxShadow: 'none!important',


          },
        },

      }),

      ValueContainer: (provided, state) => ({
        ...provided,
        padding: '0 6px',
        '&:focus': {
          borderColor: "#EDE8DE",
          boxShadow: 'none',
        },
        '&:hover': {
          borderColor: "#CEC2A6",
          boxShadow: 'none',

        },

      }),

      container: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        '&:focus': {
          borderColor: "#CEC2A6",

        },
        '&:hover': {
          borderColor: "#CEC2A6",

        },

      }),

      dropdownIndicator: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        color: '#CEC2A6',
        borderLeft: 0

      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0
      }),
      placeholder: (provided) => ({
        ...provided,
        color: '#CEC2A6',
      })


    }
    const options = [
      { value: "İptal İade", label: 'İptal & İade' },
      { value: "Ürün Problemi", label: 'Ürün Problemi' },
      { value: "Yeni Marka", label: 'Yeni Marka' },
      { value: "Diğer", label: 'Diğer' },


    ]
    return (
      <div className="MusteriHizmetleri">
        <div className="container">
          <BreadCrumb className="mb-4" path={i18next.t('CONTACT')}></BreadCrumb>
          <div className="MusteriHizmetleri__body">
            <div className="row align-items-center py-4">
              <div className="col-lg-6  text-center">

                <p className="MusteriHizmetleri__title h6 divGilroy ">
                  {i18next.t("CallUs")}
                </p>
                <p className="MusteriHizmetleri__text divAthena h5 font-weight-bold">
                  {this.state.LayoutInfo.Phone}
                </p>
                <p className="MusteriHizmetleri__title h6 divGilroy mt-3">
                  {i18next.t("WriteUs")}
                </p>
                <p className="MusteriHizmetleri__text MusteriHizmetleri__text--font divAthena h5 font-weight-bold">
                  {this.state.LayoutInfo.Email}
                </p>
                <p className="MusteriHizmetleri__title h6 divGilroy mt-3">
                  {i18next.t("ExpectToVisit")}
                </p>
                <p className="MusteriHizmetleri__text divAthena h5 font-weight-bold">
                  {this.state.LayoutInfo.Address}
                </p>
                <p className="MusteriHizmetleri__title h6 divGilroy mt-3">
                  {i18next.t("FollowUs")}
                </p>
                <p className="MusteriHizmetleri__text">
                  <div className="row">
                    <div className="col-lg-6 mx-auto">
                      <div className="socialMediaTags">
                        <ul className="d-flex justify-content-lg-around flex-wrap">
                          <a href={this.state.LayoutInfo.Facebook} target="_blank" >
                            <svg className="iconSize-xxs" >
                              <use href={Sprite + "#facebook"} ></use>
                            </svg >
                          </a>
                          <a href={this.state.LayoutInfo.Instagram} target="_blank" >
                            <svg className="iconSize-xxs" >
                              <use href={Sprite + "#instagram"} ></use>
                            </svg >
                          </a>
                          <a href={this.state.LayoutInfo.Youtube} target="_blank">
                            <svg className="iconSize-xxs" >
                              <use href={Sprite + "#youtube"} ></use>
                            </svg >
                          </a>
                          <a href={this.state.LayoutInfo.LinkedIn} target="_blank" >
                            <svg className="iconSize-xxs" >
                              <use href={Sprite + "#linkedin"} ></use>
                            </svg >
                          </a>
                        </ul>
                      </div>
                    </div>
                  </div>
                </p>
              </div>
              <div className="col-lg-6 MusteriHizmetleri__border">
                <h2 className="h-size-4 MusteriHizmetleri__mainTitle divAthena">{i18next.t('ContactUs')}</h2>

                <Form className="iletisim px-lg-5 ">
                  <div className="divFormSubTitle">{i18next.t("NameSurname")}</div>
                  <input type="text" id="name" placeholder={i18next.t("NameSurname")} className="form-control divFromInput" required value={this.state.NameSurname} onChange={(e) => this.setState({ NameSurname: e.target.value })} />
                  <div className="divFormSubTitle">{i18next.t("EmailAdress")}</div>
                  <input type="email" id="emailadress" placeholder={i18next.t("EmailAdress")} className="form-control divFromInput" required value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} />
                  <div className="divFormSubTitle">{i18next.t("PhoneNumber")}</div>
                  <InputMask mask="(999) 999 99 99"
                    className="c-input form-control divFromInput"
                    maskChar={null}
                    placeholder={i18next.t("PhoneNumber")}
                    type="tel"
                    id="telno"
                    value={this.state.Phone}
                    onChange={(e) => { this.setState({ Phone: e.target.value }) }}
                    required>
                  </InputMask>
                  <div className="divFormSubTitle">{i18next.t("Subject")}</div>
                  <div className="form-control divFromInput">
                    <Select
                      placeholder={i18next.t("Subject")}
                      styles={customStyles}
                      onChange={this.handleChangeSubject}
                      options={options}
                    >
                    </Select>
                  </div>
                  <div className="divFormSubTitle">{i18next.t("YourMessage")}</div>
                  <div>
                    <textarea
                      maxLength={450}
                      placeholder={i18next.t("YourMessage")}
                      value={this.state.Message}
                      onChange={(e) => { this.setState({ Message: e.target.value }) }}
                      className="w-100 form-control divFromInput"
                      rows="3"
                      required >
                    </textarea>
                    <div className="text-right divContactRemainingText">{450 - this.state.Message.length + "/450"}</div>
                  </div>
                  <div className="form-check conditions">
                    <input type="checkbox" class="form-check-input mt-3" id="conditions" onChange={() => { this.setState({ TermsOfUse: !this.state.TermsOfUse }) }} />
                    <label class="form-check-label " htmlFor="conditions">
                      <span className="p-size-2 p-size--color divGilroy"><a href={`/${localStorage.getItem("lang")}/sozlesmeler/5`} className="text-dark"> {i18next.t("ProtectPersonalData")} </a>  {i18next.t("ContractText2")} </span>
                    </label>
                  </div>
                  <div className="row mt-4 mb-4 mx-0">
                    <div className="col-lg-12  p-0 mx-auto ">
                      <a className={this.state.SendLoading === true ? "submit-button" : "submit-button"} onClick={this.sendContactForm} >{i18next.t("Send")}</a>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <iframe
          src={this.state.LayoutInfo.Map}
          style={{
            width: '100%', height: 450, frameBorder: 0, allowfullscreen
              : "", ariaHidden: "false", tabindex: 0
          }}
        ></iframe>
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
          <p></p>
          <Modal.Body>
            <div className="text-center mb-3">
              <svg className="iconSize-md" >
                <use href={Sprite + "#sad-face"} ></use>
              </svg >
            </div>
            <form className="LoginModal  ">
              <p className="text-center  p-size-1">{ExceptionTranslate(this.state.errorMessage)}</p>
              <div className="text-center  w-50  mx-auto">
                <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('TurnBack')}</a>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccessModal} onHide={() => { this.setState({ showSuccessModal: false }) }}>
          <div className="LoginModal__border">
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center"> {i18next.t("ProcessSuccess")}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#smile"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center p-size-1"> {this.state.successMessage}</p>
                <div className="text-center  w-50  mx-auto">
                  <button onClick={() => this.props.history.push('/')} className="submit-button">{i18next.t('Home')}</button>
                </div>
              </form>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    )
  }
}


